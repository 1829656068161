import React from 'react';

const EnabledColumn = (rowData) =>
  rowData?.json?.status ||
  rowData?.status === '1' ||
  rowData?.show === '1' ||
  rowData?.display === '1' ||
  rowData?.active === true ||
  rowData?.active === 1 ||
  rowData === 1 ||
  rowData === true ||
  rowData === '1' ? (
    <span>Active</span>
  ) : (
    <span>Inactive</span>
  );
export default EnabledColumn;
