/* eslint-disable new-cap */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import jsPDF from 'jspdf';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { CardContent, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
// import pdfMake from 'pdfmake';
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY, SET_SAVE_SUCCESS } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import { onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';

const initialItem = {
  from_date: '',
  to_date: '',
  order_status: '',
  mobile: '',
  order_number: '',
};

const OrderList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [searchItems, setSearchItems] = useState(initialItem);
  const { errors, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [ordersStatus, setOrderStatus] = useState([]);
  const [searchMode, setSearchMode] = useState(false);
  const [postalColumns, setPostalColumns] = useState([]);
  const searchPage = searchParams.get('page');
  const currentPage = Number.isNaN(parseInt(searchParams.get('page'), 10)) ? 1 : parseInt(searchParams.get('page'), 10);
  const currentPageRef = useRef(currentPage);
  const searchTitle = searchParams.get('search');
  const searchParamRef = useRef(searchTitle === 'true');

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const onChangeGetOtherPageData = (page) => {
    const updatedSearchParams = new URLSearchParams();

    if (page) {
      updatedSearchParams.set('page', String(page));
    } else {
      updatedSearchParams.delete('page');
    }

    if (searchParamRef.current || searchMode) {
      updatedSearchParams.set('search', 'true');
    } else {
      updatedSearchParams.delete('search');
    }
    setSearchParams(updatedSearchParams);

    if (searchMode || searchParamRef.current) {
      const savedSearchItems = JSON.parse(localStorage.getItem('searchItems') || '{}');

      if (Object.keys(savedSearchItems).length > 0) {
        saveItem(`orders/search?page=${page}`, savedSearchItems, null)
          .then((response) => {
            globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
            if (response) {
              const allData = [];
              const number_of_pages = response.data?.data?.last_page;
              setTotalPageNumber(number_of_pages * 10);
              for (let i = 0; i < response.data?.data?.data.length; i++) {
                allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
              }
              setData(allData);
            }
          })
          .finally(() => setLoading(false));
      }
    } else {
      fetchItem({
        modelName: `/orders?page=${page}`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const allData = [];
          const number_of_pages = response.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data.length; i++) {
            allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
          }
          setData(allData);
        }
      });
    }
  };
  useEffect(() => {
    setPostalColumns([
      {
        title: 'Product Name',
        dataIndex: '',
        key: 'name',
        render: (_, cartItem) => {
          const cartItemVariantAttributes = cartItem?.product_variant?.product_variant_attributes ?? [];
          let finalName = cartItem?.product?.name ?? '';
          for (let j = 0; j < cartItemVariantAttributes.length; j++) {
            const cartAtt = cartItemVariantAttributes[j];
            const attName = cartAtt?.attribute?.name ?? '';
            const attValue =
              cartAtt?.attribute?.data_type_id === 7
                ? cartAtt?.attribute_option?.value.slice(7) ?? ''
                : cartAtt?.attribute_option?.value ?? '';
            if (!(cartAtt?.attribute_id === 1 && cartAtt?.attribute_option_id === 1)) {
              const attUnit = cartAtt?.attribute?.data_type_id === 7 ? '' : cartAtt?.attribute?.unit?.name ?? '';
              finalName += ` - ${attName}: ${attValue}${attUnit ? ` ${attUnit}` : ''}`;
            }
          }
          return finalName; // Return the final formatted name
        },
      },
      {
        title: 'Price',
        dataIndex: 'item_base_price',
        key: 'price',
        render: (item) => Number(item ?? 0).toLocaleString(),
      },
      // {
      //   title: 'قیمت بعد از تخفیف محصول (تومان)',
      //   dataIndex: 'item_off_price',
      //   key: 'offPrice',
      //   render: (item) => Number(item ?? 0).toLocaleString(),
      // },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]);
  }, []);

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, searchItems, setSearchItems);
  };

  const onLoadData = useCallback(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        if ((searchPage && searchPage !== '1') || searchParamRef.current) {
          onChangeGetOtherPageData(searchPage);
        } else {
          const number_of_pages = response.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data.length; i++) {
            allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
          }
        }
        setData(allData);
      }
    });
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/order-status`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.length; i++) {
          allData.push({ ...response.data[i], key: response.data[i].id });
        }
        setOrderStatus(allData);
      }
    });
  }, []);

  useEffect(() => {
    onLoadData();
  }, [onLoadData]);

  useEffect(() => {
    setColumns([
      {
        title: 'Order Number',
        dataIndex: 'order_number',
        key: 'order_number',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'user',
        key: 'user',
        render: (item) => item.mobile,
      },
      {
        title: 'Order Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (item) => moment(item).format('YYYY/M/D HH:mm:ss'),
      },
      {
        title: 'Total Price',
        dataIndex: 'order_total_price',
        key: 'order_total_price',
        render: (item) => Number(item).toLocaleString(),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (rowData) => rowData.status,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'admin.orders.update',
    deletePermission: 'admin.orders.destroy',
    editURL: '/order-management/order/',
    removePATH: 'orders',
    createPermission: '',
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/order-management/order');
  };

  const onSubmit = useCallback(() => {
    setLoading(true);
    setSearchMode(true);
    searchParamRef.current = true;
    const _searchItems = {};
    if (searchItems.from_date) {
      const dateTimeStringFrom = searchItems.from_date;
      _searchItems.from_date = dateTimeStringFrom.split(' ')[0];
    }

    if (searchItems.to_date) {
      const dateTimeStringTo = searchItems.to_date;
      _searchItems.to_date = dateTimeStringTo.split(' ')[0];
    }

    if (searchItems.mobile) {
      _searchItems.mobile = searchItems.mobile;
    }

    if (searchItems.order_number) {
      _searchItems.order_number = searchItems.order_number;
    }

    if (searchItems.order_status) {
      _searchItems.order_status = searchItems.order_status;
    }
    localStorage.setItem('searchItems', JSON.stringify(_searchItems));
    saveItem('orders/search', _searchItems, null)
      .then((response) => {
        globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
        if (response) {
          const searchParameters = new URLSearchParams();
          searchParameters.set('search', 'true');
          setSearchParams(searchParameters);
          const allData = [];
          const number_of_pages = response.data?.data?.last_page;
          setTotalPageNumber(number_of_pages * 10);
          for (let i = 0; i < response.data?.data?.data.length; i++) {
            allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
          }
          setData(allData);
        }
      })
      .finally(() => setLoading(false));
  }, [searchItems]);

  const onResetSearch = useCallback(() => {
    setSearchMode(false);
    setSearchParams(new URLSearchParams());
    searchParamRef.current = '';
    onLoadData();
    setSearchItems(initialItem);
    localStorage.removeItem('searchItems');
  }, [onLoadData]);

  const getNestedValue = (obj, path) =>
    path.split('.').reduce((acc, nestedKey) => (acc ? acc[nestedKey] : undefined), obj);

  const generatePdfContent = (selectedData, pColumns) => {
    const container = document.createElement('div'); // Create a container for all rows
    selectedData.forEach((item, index) => {
      // Create content for each row
      const rowContent = document.createElement('div');
      rowContent.innerHTML = `
        <div style="margin-bottom: 40px; padding-bottom: 40px;">
          <div style="text-align: left; margin-bottom: 20px;">
          <style>
            .table-content {
              font-size: 25px;
              font-weight: bold;
            }
            .table-header {
              font-size: 30px;
              direction: ltr;
              font-family: Arial, sans-serif;
              line-height: 1.5;
              word-break: normal; /* Allow natural word wrapping */
              white-space: normal; /* Prevent unwanted breaks */
              overflow-wrap: anywhere; /* Ensure words wrap properly */
              max-width: 50%;
              text-align: left;
            }
          </style>
          <h2>Recipient Name: ${item?.address?.receiver_first_name ?? '-'} ${
        item?.address?.receiver_last_name ?? ''
      }</h2>
          <h2>Recipient Phone Number: ${item?.address?.receiver_phone ?? '-'}</h2>
          <h2>Recipient Email: ${item?.address?.receiver_email ?? '-'}</h2>
          <h2 class="table-header">
            Recipient Address: ${item?.address?.address}, ${item?.address?.city}, ${item?.address?.province}, ${
        item?.address?.country
      }
          </h2>
          <h2 class="table-header">
            Postal Code: ${item?.address?.postal_code}
          </h2>
          <h2 class="table-header">
            Delivery Method: ${item?.delivery_method?.name ?? '-'}
          </h2>
          <h2 class="table-header">
            Delivery Price: ${item?.delivery_price ?? '-'}
          </h2>
        </div>
        <div style="border-top: 1px dashed #000; margin: 20px 0;"></div>
        <div style="margin-top: 20px;">
          <h2 style="text-align: left;">Products</h2>
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr class="table-content">
                ${pColumns
                  .map((col) => `<th style="border: 1px solid #ddd; padding: 8px; width: 120px;">${col.title}</th>`)
                  .join('')}
              </tr>
            </thead>
            <tbody class="table-content">
              ${item.cart?.items
                .map(
                  (cartItem) => `
                    <tr class="table-content">
                      ${pColumns
                        .map((col) => {
                          const value = col.dataIndex ? getNestedValue(cartItem, col.dataIndex) : undefined;
                          const formattedValue = col.render ? col.render(value, cartItem) : value;
                          return `<td style="border: 1px solid #ddd; padding: 8px;" class="table-content">${
                            formattedValue || ''
                          }</td>`;
                        })
                        .join('')}
                    </tr>
                  `,
                )
                .join('')}
            </tbody>
          </table>
        </div>
        <div style="margin-top: 20px;">
          <h2 style="text-align: right;">Total Price: ${Number(item?.order_total_price ?? 0).toLocaleString()}</h2>
        </div>
        <div style="margin-top: 20px;">
          <h2 style="text-align: right;">Total Paid: ${(
            Number(item?.order_total_price ?? 0) + Number(item?.delivery_price ?? 0)
          ).toLocaleString()}</h2>
        </div>
      </div>
      `;
      // Add a line between rows
      if (index < selectedData.length - 1) {
        const separator = document.createElement('hr');
        separator.style.margin = '40px 0';
        container.appendChild(rowContent);
        container.appendChild(separator);
      } else {
        container.appendChild(rowContent);
      }
    });

    return container;
  };

  const onPrintData = useCallback(
    async (selectedData) => {
      // Generate the HTML content for the PDF
      const pdfContent = generatePdfContent(selectedData, postalColumns);

      // Append to the DOM (hidden for rendering)
      document.body.appendChild(pdfContent);
      pdfContent.style.visibility = 'visible';
      pdfContent.style.position = 'static'; // Prevents layout shifting

      // Render the PDF using html2canvas and jsPDF
      try {
        const canvas = await html2canvas(pdfContent, {
          scale: 3, // High-quality rendering
          fontEmbed: true, // Ensure correct font embedding
          useCORS: true, // Allow cross-origin font loading
        });
        const imgWidth = 190; // PDF width in mm
        const pageHeight = 300; // A4 page height in mm
        const topPadding = 20; // Padding at the top
        const bottomPadding = 20; // Padding at the bottom
        const usablePageHeight = pageHeight - topPadding - bottomPadding;

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'mm', 'a4');
        // pdf.setFont('Tahoma');
        const pageCanvas = document.createElement('canvas');
        const ctx = pageCanvas.getContext('2d');

        let position = 0; // Track how much of the image is processed
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.floor((usablePageHeight / imgWidth) * canvas.width); // Crop height in pixels

        while (position < canvas.height) {
          // Clear the canvas for cropping
          ctx.clearRect(0, 0, pageCanvas.width, pageCanvas.height);

          // Crop the part of the canvas for the current page
          ctx.drawImage(
            canvas,
            0,
            position, // Source x, y
            canvas.width,
            pageCanvas.height, // Source width and height
            0,
            0, // Destination x, y
            pageCanvas.width,
            pageCanvas.height, // Destination width and height
          );

          // Convert the cropped portion to an image
          const imgData = pageCanvas.toDataURL('image/png');

          // Add the cropped image to the PDF
          pdf.addImage(imgData, 'PNG', 10, topPadding, imgWidth, usablePageHeight, undefined, 'FAST');

          position += pageCanvas.height; // Move to the next section

          if (position < canvas.height) {
            pdf.addPage(); // Add new page if there's more content
          }
        }
        pdf.save('orders-group-post.pdf');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error generating PDF:', error);
      } finally {
        // Clean up
        document.body.removeChild(pdfContent);
      }
    },
    [postalColumns],
  );

  const selectionRowCallback = useCallback(
    async (selectedRows) => {
      setLoading(true);
      const selectedData = selectedRows.map((id) => data.find((row) => row.id === id));
      if (!selectedData.length) {
        return;
      }
      await onPrintData(selectedData);
      setLoading(false);
    },
    [data, onPrintData],
  );

  // const selectionRowCallback = useCallback(
  //   (selectedRows) => {
  //     const selectedData = selectedRows.map((id) => data.find((row) => row.id === id));
  //     if (!selectedData.length) return;

  //     const fonts = {
  //       IRANSans: {
  //         normal: '/fonts/IRANSansWeb(FaNum)_Light.ttf',
  //         bold: '/fonts/IRANSansWeb(FaNum)_Bold.ttf',
  //       },
  //     };

  //     // Map your data to the PDF document definition
  //     const documentDefinition = {
  //       content: selectedData.map((item) => ({
  //         stack: [
  //           {
  //             text: `نام گیرنده: ${item?.user?.name}`,
  //             style: 'header',
  //           },
  //           {
  //             text: `شماره تماس گیرنده: ${item?.user?.mobile}`,
  //             style: 'subheader',
  //           },
  //           {
  //             text: `آدرس گیرنده: ${item?.address?.city?.province?.name}, ${item?.address?.city?.name}, ${item?.address?.address_detail}`,
  //             style: 'rtlText',
  //           },
  //           {
  //             text: `کد پستی: ${item?.address?.postal_code}`,
  //             style: 'subheader',
  //           },
  //           {
  //             text: `روش ارسال: ${item?.delivery_method?.name ?? '-'}`,
  //             style: 'subheader',
  //           },
  //           { text: '\n' },
  //           {
  //             text: 'محصولات',
  //             style: 'sectionHeader',
  //           },
  //           {
  //             table: {
  //               headerRows: 1,
  //               widths: postalColumns.map(() => 'auto'),
  //               body: [
  //                 // Table Headers
  //                 postalColumns.map((col) => ({
  //                   text: col.title,
  //                   style: 'tableHeader',
  //                   alignment: 'center',
  //                 })),
  //                 // Table Rows
  //                 ...item.cart?.items.map((cartItem) =>
  //                   postalColumns.map((col) => ({
  //                     // eslint-disable-next-line no-nested-ternary
  //                     text: col.render
  //                       ? col.render(col.dataIndex ? getNestedValue(cartItem, col.dataIndex) : undefined, cartItem)
  //                       : col.dataIndex
  //                       ? getNestedValue(cartItem, col.dataIndex)
  //                       : '',
  //                     style: 'tableCell',
  //                     alignment: 'center',
  //                   })),
  //                 ),
  //               ],
  //             },
  //             layout: 'lightHorizontalLines', // Add gridlines for better readability
  //           },
  //           { text: '\n' },
  //           {
  //             text: `مجموع خرید: ${Number(item?.order_total_price ?? 0).toLocaleString()}`,
  //             style: 'summary',
  //           },
  //           { text: '\n\n' },
  //         ],
  //       })),
  //       styles: {
  //         header: {
  //           fontSize: 18,
  //           bold: true,
  //           margin: [0, 5, 0, 5],
  //         },
  //         subheader: {
  //           fontSize: 14,
  //           margin: [0, 5, 0, 5],
  //         },
  //         rtlText: {
  //           alignment: 'right',
  //           fontSize: 12,
  //           margin: [0, 5, 0, 5],
  //         },
  //         sectionHeader: {
  //           fontSize: 16,
  //           bold: true,
  //           margin: [0, 10, 0, 10],
  //           alignment: 'right',
  //         },
  //         tableHeader: {
  //           bold: true,
  //           fontSize: 12,
  //           fillColor: '#f2f2f2',
  //         },
  //         tableCell: {
  //           fontSize: 10,
  //         },
  //         summary: {
  //           fontSize: 14,
  //           bold: true,
  //           alignment: 'right',
  //         },
  //         boldText: {
  //           font: 'IRANSans',
  //           bold: true,
  //         },
  //         lightText: {
  //           font: 'IRANSans',
  //           fontSize: 14,
  //         },
  //       },
  //       defaultStyle: {
  //         font: 'IRANSans', // Use IRANSans as the default font
  //       },
  //       fonts,
  //     };

  //     // Generate and download the PDF
  //     pdfMake.createPdf(documentDefinition).download('orders-group-post.pdf');
  //   },
  //   [data],
  // );

  const onEditProduct = (type, recordId) => {
    if (type === 'edit') {
      navigate(
        `/order-management/order/${recordId}?page=${currentPageRef.current}${
          searchParamRef.current ? `&search=true` : ''
        }`,
      );
    }
  };

  // const printAllPaidOrders = useCallback(async () => {
  //   setLoading(true);
  //   const currentSearchItems = localStorage.getItem('searchItems');
  //   const allData = [];

  //   try {
  //     const firstResponse = await saveItem('orders/search', JSON.parse(currentSearchItems), null);
  //     globalDispatch({ type: SET_SAVE_SUCCESS, value: false });

  //     if (firstResponse) {
  //       const number_of_pages = firstResponse.data?.data?.last_page;

  //       // Process the first page data
  //       for (let i = 0; i < firstResponse.data?.data?.data.length; i++) {
  //         if (firstResponse.data?.data?.data[i].order_status === 3) {
  //           allData.push({
  //             ...firstResponse.data?.data?.data[i],
  //             key: firstResponse.data?.data?.data[i].id,
  //           });
  //         }
  //       }

  //       // Collect promises for additional pages
  //       const promises = [];
  //       for (let j = 2; j <= number_of_pages; j++) {
  //         promises.push(
  //           saveItem(`orders/search?page=${j}`, JSON.parse(currentSearchItems), null).then((res) => {
  //             globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
  //             if (res) {
  //               for (let k = 0; k < res.data?.data?.data.length; k++) {
  //                 if (res.data?.data?.data[k].order_status === 3) {
  //                   allData.push({
  //                     ...res.data?.data?.data[k],
  //                     key: res.data?.data?.data[k].id,
  //                   });
  //                 }
  //               }
  //             }
  //           }),
  //         );
  //       }

  //       // Wait for all additional pages to be processed
  //       await Promise.all(promises);
  //     }

  //     // Trigger onPrintData if there are paid orders
  //     if (allData.length > 0) {
  //       const chunks = [];
  //       for (let i = 0; i < allData.length; i += 20) {
  //         chunks.push(allData.slice(i, i + 20));
  //       }

  //       await Promise.all(chunks.map((chunk) => onPrintData(chunk)));
  //     } else {
  //       BSwalShowError(['سفارش با وضعیت پرداخت شده یافت نشد.']);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     BSwalShowError(['خطایی در دریافت سفارش‌ها رخ داد.']);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [onPrintData, postalColumns]);

  return (
    <>
      <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={3}>
            <BDateFieldTable
              name='from_date'
              label='From Date'
              value={searchItems.from_date}
              onChange={onChange}
              required
              key='from_date'
              xs={12}
              sm={3}
            />
            <BDateFieldTable
              name='to_date'
              label='To Date'
              value={searchItems.to_date}
              onChange={onChange}
              required
              key='to_date'
              xs={12}
              sm={3}
            />
            <BTextField
              xs={12}
              sm={3}
              label='Mobile Number'
              name='mobile'
              value={searchItems.mobile}
              onChange={onChange}
            />
            <BTextField
              xs={12}
              sm={3}
              label='Order Number'
              name='order_number'
              value={searchItems.order_number}
              onChange={onChange}
            />
            <BSelectField
              sm={3}
              xs={12}
              label='Order Status'
              name='order_status'
              value={searchItems?.order_status}
              onChange={onChange}
              error={errors?.data_type_id}
              items={convertArrayToKeyedObject(ordersStatus, 'id', 'status')}
            />
            <Grid item xs={12} sm={3}>
              <BSubmitButton id='submitSearch' label='Search' loading={loading} style={{ marginTop: '40px' }} />{' '}
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Button id='resetSearch' loading={loading} style={{ marginTop: '40px' }} onClick={onResetSearch}>
                Show All
              </Button>
            </Grid>
          </Grid>
          {/* <Button
            id='printAll'
            loading={loading}
            style={{ marginTop: '40px', marginRight: '-15px' }}
            onClick={printAllPaidOrders}
            disabled={!searchMode && !searchParamRef.current}
          >
            چاپ فاکتور پستی همه سفارشات پرداخت شده
          </Button> */}
        </CardContent>
      </form>
      <ListsContainer>
        <Table
          tableKey={key}
          key={key}
          title='Orders List'
          columns={columns}
          createSlug='Add an Order'
          actions={actions}
          createAction={handleCreate}
          tableRef={tableRef}
          data={data}
          pageSize={pageSize}
          filtering={false}
          sortableColumns
          totalPages={totalPageNumber}
          getOtherPageData={onChangeGetOtherPageData}
          hasRowSelection={{ button: '  Print Invoice Pdf', callback: selectionRowCallback }}
          actionCallback={(type, record) => {
            onEditProduct(type, record);
          }}
          currentPage={parseInt(searchParams.get('page'), 10) || 1}
        />
      </ListsContainer>
    </>
  );
};

export default OrderList;
