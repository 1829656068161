import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';

const ProductsReportList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='جستجو'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            جستجو
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            ریست
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    // fetchItem({
    //   modelName: `/service-categories`,
    //   showLoader: true,
    // }).then((response) => {
    //   if (response) {
    //     const number_of_pages = response.data?.last_page;
    //     setTotalPageNumber(number_of_pages * 10);
    //     setData(response.data?.data);
    //   }
    // });
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: 'نام',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      // {
      //   title: 'کتگوری اصلی',
      //   dataIndex: 'parent_id',
      //   key: 'parent_id',
      //   render: (id) => data.find((value) => Number(value.id) === Number(id))?.name,
      // },
      {
        title: 'وضعیت',
        dataIndex: 'display',
        key: 'display',
        type: 'boolean',
        filters: [
          {
            text: 'فعال',
            value: '1',
          },
          {
            text: 'غیر فعال',
            value: '0',
          },
        ],
        onFilter: (value, record) => record.display === value,
        sorter: (a, b) => Number(a.display) - Number(b.display),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: '',
    deletePermission: '',
    editURL: '/',
    removePATH: '',
    createPermission: '',
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/salon-management/service-category');
  };

  const onChangeGetOtherPageData = (page) => {
    // fetchItem({
    //   modelName: `/service-categories?page=${page}`,
    //   showLoader: true,
    // }).then((response) => {
    //   if (response) {
    //     setData(response.data?.data);
    //   }
    // });
  };

  return (
    <ListsContainer>
      <Table
        key={key}
        title='گزارش بر اساس محصول'
        columns={columns}
        createSlug='افزودن به کتگوری سرویس ها'
        actions={actions}
        createAction={handleCreate}
        tableRef={tableRef}
        data={data}
        pageSize={pageSize}
        filtering={false}
        sortableColumns
        totalPages={totalPageNumber}
        getOtherPageData={onChangeGetOtherPageData}
      />
    </ListsContainer>
  );
};

export default ProductsReportList;
