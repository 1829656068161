import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import Table from '../../../../components/tables/Table';

const initialItem = {
  order_number: '',
  created_at: '',
  user: {
    name: '',
    mobile: '',
    email: '',
  },
  address: {
    city: '',
    province: '',
    country: '',
    postal_code: '',
    address: '',
    receiver_first_name: '',
    receiver_last_name: '',
    receiver_phone: '',
    receiver_email: '',
  },
  order_status: '',
  address_id: '',
  cart_id: '',
  delivery_time_id: null,
  order_total_price: '',
  payment_id: '',
  payment: '',
  delivery_price: '',
  cart_items: [],
};

const OrderPage = (props) => {
  const { staticContext, ...rest } = props;
  const [searchParams] = useSearchParams();
  const { fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [ordersStatus, setOrderStatus] = useState([]);
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/orders',
    localStorageKey: 'orders',
    initialItem,
  });
  const [key, setKey] = useState(0);
  const [postalKey, setPostalKey] = useState(0);
  const [columns, setColumns] = useState([]);
  const [postalColumns, setPostalColumns] = useState([]);
  const tableRef = useRef(null);
  const [cartItems, setCartItems] = useState([]);
  const [priceTableKey, setPriceTableKey] = useState(0);
  const [priceTableColumns, setPriceTableColumns] = useState([]);
  const priceTableRef = useRef(null);
  const [priceTableItems, setPriceTableItems] = useState([]);
  const page = searchParams.get('page');
  const searchParam = searchParams.get('search');

  const saveAsPDF = useCallback(async () => {
    const originalContent = document.getElementById('pdf-content');
    if (!originalContent) {
      // eslint-disable-next-line no-console
      console.error('PDF content element not found!');
      return;
    }

    // Clone the content into a temporary visible container
    const clonedContent = originalContent.cloneNode(true);
    clonedContent.style.visibility = 'visible';
    clonedContent.style.position = 'static';
    clonedContent.style.transform = 'none';
    document.body.appendChild(clonedContent);

    try {
      const canvas = await html2canvas(clonedContent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();

      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');
      pdf.save(`order-details-${currentItem.order_number}.pdf`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the cloned content
      document.body.removeChild(clonedContent);
    }
  }, [currentItem.order_number]);

  const saveAsPDFPostal = useCallback(async () => {
    const originalContent = document.getElementById('pdf-content-postal');
    if (!originalContent) {
      // eslint-disable-next-line no-console
      console.error('PDF content element not found!');
      return;
    }

    // Clone the content into a temporary visible container
    const clonedContent = originalContent.cloneNode(true);
    clonedContent.style.visibility = 'visible';
    clonedContent.style.position = 'static';
    clonedContent.style.transform = 'none';
    document.body.appendChild(clonedContent);

    try {
      const canvas = await html2canvas(clonedContent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();

      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');
      pdf.save(`order-post-${currentItem.order_number}.pdf`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the cloned content
      document.body.removeChild(clonedContent);
    }
  }, [currentItem.order_number]);

  useEffect(() => {
    fetchItem({
      modelName: `/order-status`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.length; i++) {
          allData.push({ ...response.data[i], key: response.data[i].id });
        }
        setOrderStatus(allData);
      }
    });
  }, []);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    _currentItem.order_status = Number(currentItem.order_status);
    if (
      _currentItem.order_status === 1 ||
      _currentItem.order_status === 2 ||
      _currentItem.order_status === 3 ||
      _currentItem.order_status === 10
    ) {
      BSwalShowError(['Can not update to selected status']);
      setLoading(false);
      return;
    }
    if (!_currentItem.delivery_time_id) delete _currentItem.delivery_time_id;
    saveItem('/orders', _currentItem, id)
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  useEffect(() => {
    if (currentItem && currentItem.cart && currentItem.cart.items && currentItem.cart.items.length > 0) {
      const finalItems = [];
      for (let i = 0; i < currentItem.cart.items.length; i++) {
        const cartItem = currentItem.cart.items[i];
        const cartItemVariantAttributes = cartItem?.product_variant?.product_variant_attributes ?? [];
        let finalName = cartItem?.product?.name ?? '';
        for (let j = 0; j < cartItemVariantAttributes.length; j++) {
          const cartAtt = cartItemVariantAttributes[j];
          const attName = cartAtt?.attribute?.name ?? '';
          const attValue =
            cartAtt?.attribute?.data_type_id === 7
              ? cartAtt?.attribute_option?.value.slice(7) ?? ''
              : cartAtt?.attribute_option?.value ?? '';
          if (!(cartAtt?.attribute_id === 1 && cartAtt?.attribute_option_id === 1)) {
            const attUint = cartAtt?.attribute?.data_type_id === 7 ? '' : cartAtt?.attribute?.unit?.name ?? '';
            finalName += ` - ${attName}: ${attValue}${attUint ? `${attUint}` : ''}`;
          }
        }
        finalItems.push({
          name: finalName,
          brand: cartItem?.product?.brand?.name ?? '',
          category: cartItem?.product?.category?.name ?? '',
          price: Number(cartItem?.item_base_price).toLocaleString() || '',
          offPrice:
            Number(cartItem?.item_off_price).toLocaleString() && Number(cartItem?.item_off_price).toLocaleString() > 0
              ? Number(cartItem?.item_off_price).toLocaleString()
              : '-',
          quantity: cartItem?.quantity ?? '',
          key: `${cartItem?.cart_id ?? 0}-${cartItem?.id ?? 0}-${cartItem?.product?.id ?? 0}`,
        });
      }
      setCartItems(finalItems);
      const finalPriceItem = {
        orderTotalPrice: Number(currentItem?.order_total_price ?? 0).toLocaleString(),
        deliveryPayment: Number(currentItem?.delivery_price ?? 0).toLocaleString(),
        discountCode: '',
        discountCodeValue: '',
        orderPayment:
          (Number(currentItem?.order_total_price) + Number(currentItem?.delivery_price)).toLocaleString() ?? '',
        referenceId: currentItem?.payment?.reference_id,
        paymentTime: currentItem?.payment ? moment(currentItem?.payment?.updated_at).format('YYYY/M/D HH:mm:ss') : '-',
        // eslint-disable-next-line no-nested-ternary
        paymentStatus: currentItem?.payment
          ? // eslint-disable-next-line no-nested-ternary
            currentItem?.payment?.status === 'paid'
            ? 'Success'
            : currentItem?.payment?.status === 'pending'
            ? 'Pending'
            : 'Unsuccessful'
          : '-',
      };
      setPriceTableItems([finalPriceItem]);
    } else {
      setCartItems([]);
    }
  }, [currentItem]);

  useEffect(() => {
    setColumns([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Price After Discount',
        dataIndex: 'offPrice',
        key: 'offPrice',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]);
    setKey(key + 1);
  }, []);

  useEffect(() => {
    setPostalColumns([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Price After Discount',
        dataIndex: 'offPrice',
        key: 'offPrice',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]);
    setPostalKey(postalKey + 1);
  }, []);

  useEffect(() => {
    setPriceTableColumns([
      {
        title: 'Order Total Price',
        dataIndex: 'orderTotalPrice',
        key: 'orderTotalPrice',
      },
      // {
      //   title: 'Discount Code',
      //   dataIndex: 'discountCode',
      //   key: 'discountCode',
      // },
      // {
      //   title: 'Discount Code Price',
      //   dataIndex: 'discountCodeValue',
      //   key: 'discountCodeValue',
      // },
      {
        title: 'Delivery Price',
        dataIndex: 'deliveryPayment',
        key: 'deliveryPayment',
      },
      {
        title: 'Total Paid',
        dataIndex: 'orderPayment',
        key: 'orderPayment',
      },
      {
        title: 'Payment Status',
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
      },
      {
        title: 'Payment Reference Number',
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
      {
        title: 'Payment Date and Time',
        dataIndex: 'paymentTime',
        key: 'paymentTime',
      },
    ]);
    setPriceTableKey(key + 1);
  }, []);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            key='back'
            id='backToListIcon'
            component={Link}
            to={
              page
                ? `/order-management/orders?page=${encodeURIComponent(page)}${searchParam ? `&search=true` : ''}`
                : `/order-management/orders${searchParam ? `?search=true` : ''}`
            }
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <Button
            key='saveAsPDF'
            id='saveAsPDF'
            onClick={saveAsPDF}
            color='primary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            Print Invoice
          </Button>
          {/* <Button
            key='saveAsPDFPostal'
            id='saveAsPDFPostal'
            onClick={saveAsPDFPostal}
            color='primary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            Print Postal Invoice
          </Button> */}
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Order Details' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={3}
                  label='Order Number'
                  name='order_number'
                  value={currentItem?.order_number}
                  onChange={onChange}
                  readOnly
                  error={errors?.order_number}
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Customer Name'
                  name='user.name'
                  value={currentItem?.user?.name}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Recipient Name'
                  name='address.name'
                  value={`${currentItem?.address?.receiver_first_name ?? ''} ${
                    currentItem?.address?.receiver_last_name ?? ''
                  }`}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Customer Mobile Number'
                  name='user.mobile'
                  value={currentItem?.user?.mobile}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Recipient Mobile Number'
                  name='address.mobile'
                  value={currentItem?.address?.receiver_phone}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Customer Email'
                  name='user.email'
                  value={currentItem?.user?.email}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Recipient email'
                  name='address.email'
                  value={currentItem?.address?.receiver_email}
                  onChange={onChange}
                  readOnly
                />
                <BDateFieldTable
                  xs={12}
                  sm={3}
                  name='create_at'
                  label='Order Date'
                  error={errors?.created_at}
                  value={currentItem?.created_at}
                  onChange={onChange}
                  readOnly
                />
                <div style={{ height: '30px', width: '100%' }} />
                <div style={{ height: '30px', width: '100%' }}>Recipient Address Info</div>
                <BTextField
                  xs={12}
                  sm={12}
                  label='Address'
                  name='address.address'
                  value={currentItem?.address?.address}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='City'
                  name='address.city'
                  value={currentItem?.address?.city}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Province'
                  name='address.province'
                  value={currentItem?.address?.province}
                  onChange={onChange}
                  readOnly
                />
                <BTextField
                  xs={12}
                  sm={3}
                  label='Postal Code'
                  name='address.postal_code'
                  value={currentItem?.address?.postal_code}
                  onChange={onChange}
                  readOnly
                />
                <BSelectField
                  sm={3}
                  xs={12}
                  label='Order Status'
                  name='order_status'
                  value={currentItem?.order_status}
                  onChange={onChange}
                  items={convertArrayToKeyedObject(ordersStatus, 'id', 'status')}
                  readOnly={
                    Number(currentItem?.order_status) === 1 ||
                    Number(currentItem?.order_status) === 2 ||
                    Number(currentItem?.order_status) === 10
                  }
                />
                <Grid item xs={12} sm={6} style={{ marginTop: '40px' }}>
                  <BSubmitButton id='changeOrderStatus' label='Update Status' loading={loading} />
                </Grid>
              </Grid>
              <ListsContainer key='basket'>
                <Table
                  tableKey={key}
                  key={key}
                  title='Basket Details'
                  columns={columns}
                  createSlug=''
                  actions={[]}
                  createAction={undefined}
                  tableRef={tableRef}
                  data={cartItems}
                  pageSize={10}
                  filtering={false}
                  totalPages={undefined}
                  getOtherPageData={undefined}
                />
              </ListsContainer>
              <ListsContainer key='price'>
                <Table
                  tableKey={priceTableKey}
                  key={priceTableKey}
                  title='Payment Details'
                  columns={priceTableColumns}
                  createSlug=''
                  actions={[]}
                  createAction={undefined}
                  tableRef={priceTableRef}
                  data={priceTableItems}
                  filtering={false}
                  totalPages={undefined}
                  getOtherPageData={undefined}
                />
              </ListsContainer>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={
                    page
                      ? `/order-management/orders?page=${encodeURIComponent(page)}${searchParam ? `&search=true` : ''}`
                      : `/order-management/orders${searchParam ? `?search=true` : ''}`
                  }
                  color='secondary'
                  variant='outlined'
                >
                  Back to List
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
      <div id='pdf-content' style={{ visibility: 'hidden', position: 'absolute', top: '-10000px', left: '-10000px' }}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h1>Sanaz H Expreesions - Product Invoice</h1>
          <p>
            Recipient Name: {currentItem?.address?.receiver_first_name ?? '-'}{' '}
            {currentItem?.address?.receiver_last_name ?? ''}:
          </p>
          <p>Recipient Phone Number: {currentItem?.address?.receiver_phone ?? '-'}</p>
          <p>Recipient Email: {currentItem?.address?.receiver_email ?? '-'}</p>
          <p>
            Recipient Address: {currentItem?.address?.address}, {currentItem?.address?.city},{' '}
            {currentItem?.address?.province}, {currentItem?.address?.country}, Postal code:{' '}
            {currentItem?.address?.postal_code}
          </p>
          <h5>Delivery Method: {currentItem?.delivery_method?.name ?? '-'}</h5>
        </div>
        <div style={{ marginTop: '20px' }}>
          <ListsContainer key='basket-pdf'>
            <Table
              tableKey={key}
              key={key}
              title='Basket Details'
              columns={columns}
              createSlug=''
              actions={[]}
              createAction={undefined}
              tableRef={tableRef}
              data={cartItems}
              pageSize={10}
              filtering={false}
              totalPages={undefined}
              getOtherPageData={undefined}
            />
          </ListsContainer>
          <ListsContainer key='price-pdf'>
            <Table
              tableKey={priceTableKey}
              key={priceTableKey}
              title='Payment Details'
              columns={priceTableColumns}
              createSlug=''
              actions={[]}
              createAction={undefined}
              tableRef={priceTableRef}
              data={priceTableItems}
              filtering={false}
              totalPages={undefined}
              getOtherPageData={undefined}
            />
          </ListsContainer>
        </div>
      </div>
      <div
        id='pdf-content-postal'
        style={{ visibility: 'hidden', position: 'absolute', top: '-10000px', left: '-10000px' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h1>فاکتور سفارش فروشگاه اینترنتی آیلا</h1>
          <p>نام گیرنده: {currentItem?.user?.name}</p>
          <p>شماره تماس گیرنده: {currentItem?.user?.mobile}</p>
          <p>
            آدرس گیرنده: {currentItem?.address?.city?.province?.name}, {currentItem?.address?.city?.name},{' '}
            {currentItem?.address?.address_detail}, کد پستی: {currentItem?.address?.postal_code}
          </p>
          <h5>روش ارسال: {currentItem?.delivery_method?.name ?? '-'}</h5>
        </div>
        <div style={{ marginTop: '20px' }}>
          <ListsContainer key='basket-postal'>
            <Table
              tableKey={key}
              key={key}
              title='محصولات'
              columns={postalColumns}
              createSlug=''
              actions={[]}
              createAction={undefined}
              tableRef={tableRef}
              data={cartItems}
              pageSize={10}
              filtering={false}
              totalPages={undefined}
              getOtherPageData={undefined}
            />
          </ListsContainer>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
