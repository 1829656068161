import React, { useState } from 'react';
import { InputLabel, Hidden } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import { BSwalShowError } from '../../../common/BSwal';
import Locale from '../../../helpers/Locale';
import { forms } from '../../../data/Dictionary';
import BSubmitButton from '../../buttons/SubmitButton';

/**
 *
 * @param props
 * @returns {HTMLDivElement} This will return the login page inputs
 * @constructor
 */
export default function LoginForm({ loginCredentials, loading }) {
  const Language = Locale();
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  /**
   *
   * @param {Object} event
   */
  const emailHandler = (event) => {
    setemail(event.target.value);
  };

  /**
   *
   * @param {Object} event
   */
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  /**
   *
   * @param {Object} event
   * @param {String} email
   * @param {String} password
   */

  const handleSubmit = async (event) => {
    if (!email && !password) return BSwalShowError(['نام کابری /کلمه عبور را وارد  کنید ']).then();
    event.preventDefault();
    await loginCredentials({ email, password });
    try {
      // await login(email, password)
      // history.push('/dashboard')
    } catch (e) {
      Promise.reject(e);
    }
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') return handleSubmit(event);
  };

  return (
    <>
      {/* <div
        style={{
          background: 'url(/logo/BitBeauty.png)',
          left: '10%',
          position: 'absolute',
          width: '95px',
          height: '20px',
          backgroundRepeat: 'no-repeat',
        }}
      /> */}
      <div
        style={{
          background: 'url(/logo/bitweb-logo.png)',
          width: '32px',
          height: '35px',
          position: 'absolute',
          right: '14%',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{
          height: '100%',
          marginTop: '50px',
        }}
      >
        <Grid item xs={12} sm={10} md={8} style={{ marginBottom: '50px', marginTop: '10px', border: 'none' }}>
          <Hidden lgDown>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid
                item
                sm={8}
                md={8}
                lg={8}
                style={{
                  height: '6px',
                  background: 'rgba(59, 89, 152, 1)',
                }}
              />
              <Grid
                item
                sm={4}
                md={4}
                lg={4}
                style={{
                  height: '6px',
                  background: 'rgba(255, 189, 26, 1)',
                }}
              />
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{
                  height: '6px',
                  background: 'rgba(59, 89, 152, 1)',
                  marginTop: '50px',
                }}
              />
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                style={{
                  height: '6px',
                  background: 'rgba(255, 189, 26, 1)',
                  marginTop: '50px',
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Grid container justifyContent='center' alignItems='center'>
            <Hidden only='xs'>
              <Grid
                item
                sm={6}
                md={7}
                lg={8}
                style={{
                  paddingRight: '20px',
                  minHeight: '30rem',
                }}
              >
                <div
                  style={{
                    minHeight: '30rem',
                    backgroundImage:
                      'linear-gradient(0deg, rgb(255 255 255), rgb(252 252 252 / 55%)),url(/logo/Beautyshop.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '20px',
                  }}
                />
              </Grid>
            </Hidden>
            <Grid
              xs={12}
              sm={6}
              md={5}
              lg={4}
              style={{
                border: '1px solid rgba(59, 89, 152, 0.3)',
                padding: '97px 0',
                background: '#F8F8F8',
                borderRadius: '20px',
              }}
            >
              <CardContent>
                <form
                  style={{ display: 'flex', flexDirection: 'column' }}
                  noValidate
                  onSubmit={(event) => {
                    handleSubmit(event).then();
                  }}
                >
                  <InputLabel style={{ color: 'rgba(59, 89, 152, 1)', fontSize: '18px', marginBottom: '10px' }}>
                    {forms[Language].username}
                  </InputLabel>
                  <TextField id='email' data-testid='email' variant='outlined' onChange={emailHandler} />
                  <InputLabel
                    style={{
                      color: 'rgba(59, 89, 152, 1)',
                      fontSize: '18px',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                  >
                    {forms[Language].password}
                  </InputLabel>
                  <TextField
                    id='password'
                    data-testid='password'
                    variant='outlined'
                    onKeyPress={handleKeyPress}
                    onChange={passwordHandler}
                    // type={"password"}
                    type={passwordShown ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <VisibilityIcon onClick={togglePasswordVisiblity} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </CardContent>
              <CardActions
                style={{
                  paddingRight: 15,
                  paddingLeft: 15,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={12}>
                    <BSubmitButton
                      id='submitButton'
                      data-testid='submitButton'
                      loading={loading}
                      label={forms[Language].login}
                      style={{ width: '100%', background: 'rgba(59, 89, 152, 1)', color: '#fff', fontSize: '18px' }}
                      onClick={(event) => handleSubmit(event, email, password)}
                      variant='outlined'
                      // color={"white"}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                      <FormControlLabel
                        style={{ marginRight: -10, marginTop: 10 }}
                        control={<Checkbox name='checkedC' />}
                        label={forms[Language].rememeberMe}
                      />
                    </Grid> */}
                </Grid>
              </CardActions>
              {/* <div
                  style={{
                    fontFamily: 'IRANSans',
                    padding: '0 16px 10px 16px',
                  }}
                >
                  <div>
                    <Link to='/forgot-password'>{forms[Language].forgotPass}</Link>
                  </div>
                </div> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
