import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptEmailCharacters,
  convertArrayToKeyedObject,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import BSelectField from '../../../../components/inputs/forms/BSelectField';

const initialItem = {
  name: '',
  email: '',
  mobile: '',
  birthday: '',
  engaging_date: '',
  address: '',
  user: {
    name: '',
    email: '',
    mobile: '',
    birthday: '',
    engaging_date: '',
    gender: '1',
  },
  services: [],
  display: true,
};

const CustomerPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [firstLoad, setFirstLoad] = useState(false);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/customers',
    localStorageKey: 'customers',
    initialItem,
  });

  useEffect(() => {
    if (currentItem?.id && currentItem?.user?.id && firstLoad) {
      const _currentItem = currentItem;
      _currentItem.name = currentItem.user?.name;
      _currentItem.email = currentItem.user?.email;
      _currentItem.mobile = currentItem.user?.mobile;
      _currentItem.birthday = currentItem.user?.birthday;
      _currentItem.engaging_date = currentItem.user?.engaging_date;
      _currentItem.gender = currentItem.user?.gender;
      setCurrentItem(_currentItem);
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
      setFirstLoad(true);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    delete _currentItem.user;
    saveItem('customers', _currentItem, id)
      .then(() => navigate('/customer-management/customers'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/customer-management/customers',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Customer Info' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  label='Name'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BTextField
                  label='Email'
                  name='email'
                  value={currentItem?.email}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.email}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEmailCharacters}
                />
                <BTextField
                  label='Mobile'
                  name='mobile'
                  value={currentItem?.mobile}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.mobile}
                  required
                />
                {/* <BTextField
                  type='password'
                  name='password'
                  label='رمز عبور'
                  value={currentItem?.password}
                  onChange={onChange}
                  required={!currentItem.id}
                  error={errors?.password}
                /> */}
                <BDateFieldTable
                  name='birthday'
                  label='BirthDate'
                  error={errors?.birthday}
                  value={currentItem?.birthday}
                  onChange={onChange}
                  required
                />
                <BSelectField
                  sm={4}
                  xs={12}
                  label='Gender'
                  name='gender'
                  value={currentItem?.gender}
                  onChange={onChange}
                  error={errors?.gender}
                  items={convertArrayToKeyedObject(
                    [
                      { id: '1', name: 'Male' },
                      { id: '2', name: 'Female' },
                    ],
                    'id',
                    'name',
                  )}
                  readOnly={viewReadOnly}
                />
                {/* <BDateFieldTable
                  name='engaging_date'
                  label='تاریخ ازدواج'
                  error={errors?.engaging_date}
                  value={currentItem?.engaging_date}
                  onChange={onChange}
                /> */}
                <BTextField
                  sm={12}
                  xs={12}
                  label='Address'
                  name='address'
                  value={currentItem?.address}
                  error={errors?.address}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='Active'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/customer-management/customers',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to List
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default CustomerPage;
