import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-multi-date-picker';
import gregorian from 'react-date-object/calendars/gregorian';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import moment from 'moment';
import styles from '../../../assets/scss/modules/inputs.module.scss';
import { ReactFormHookServerError } from '../../../common/utilities';

const BDateFieldTable = ({
  xs = 12,
  sm = 4,
  label,
  name,
  readOnly = false,
  onChange,
  value,
  max = '2200-01-01',
  min = '1001-01-01',
  error,
  serverError,
  placeholder,
}) => {
  const [dateValue, setDateValue] = useState(null);

  const changeDate = (newDate) => {
    const test = new Date(newDate);
    const formattedDate = moment(test).format('YYYY-MM-DD HH:mm:ss');
    onChange({
      currentTarget: {
        name,
        value: formattedDate,
      },
    });
  };

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);
      setDateValue(newDate);
    } else {
      setDateValue(null);
    }
  }, [value]);

  return (
    <Grid item xs={xs} sm={sm} aria-disabled={readOnly}>
      {label && (
        <InputLabel htmlFor={`input--${name}`} style={{ marginBottom: '10px' }}>
          {label}
        </InputLabel>
      )}
      <DatePicker
        disabled={readOnly}
        containerClassName='custom-container'
        value={dateValue}
        placeholder={placeholder}
        onChange={changeDate}
        max={max}
        min={min}
        calendar={gregorian}
        locale={gregorian_en}
        style={{ width: '100%', padding: '16px 10px' }}
      />
      {error && <p className={styles.validationError}>{error.message}</p>}
      {serverError && ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
    </Grid>
  );
};

BDateFieldTable.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  max: PropTypes.any,
  min: PropTypes.any,
  error: PropTypes.object,
  serverError: PropTypes.array,
  placeholder: PropTypes.string,
};

export default BDateFieldTable;
