import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DashboardPanelContext } from '../../../../../components/context/DashboardContext';
import Table from '../../../../../components/tables/Table';
import useTableActions from '../../../../../hooks/useTableActions';
import ListsContainer from '../../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../../components/EnabledColumn/EnabledColumn';
import { SET_VIEW_READ_ONLY } from '../../../../../components/context/reducer/actionTypes';

const CourseFaqList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [courseName, setCourseName] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    fetchItem({
      modelName: `/course/get-faq/${id}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          const _arr = JSON.parse(response.data?.data[i].content);
          const _data = {
            id: response.data?.data[i].id,
            question: _arr ? _arr[0] : '',
            answer: _arr ? _arr[1] : '',
            status: response.data?.data[i].status,
          };
          res.push(_data);
        }
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        setData(res);
      }
    });
    fetchItem({
      modelName: `/courses/${id}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setCourseName(response.data?.description);
      }
    });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='جستجو'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            جستجو
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            ریست
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    setColumns([
      {
        title: 'پرسش',
        dataIndex: 'question',
        key: 'question',
      },
      {
        title: 'پاسخ',
        dataIndex: 'answer',
        key: 'answer',
      },
      {
        title: 'وضعیت',
        dataIndex: 'status',
        key: 'status',
        type: 'boolean',
        filters: [
          {
            text: 'فعال',
            value: '1',
          },
          {
            text: 'غیر فعال',
            value: '0',
          },
        ],
        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => Number(a.status) - Number(b.status),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'courses.faqs.update',
    deletePermission: 'courses.faqs.delete',
    editURL: `/course-management/faq-page/${id}/`,
    removePATH: 'delete-faq',
    createPermission: 'courses.faqs.add',
    viewPermission: '',
  });

  const onChangeGetOtherPageData = (page) => {
    fetchItem({
      modelName: `/course/get-faq/${id}/?page=${page}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data?.data?.length; i++) {
          const _arr = JSON.parse(response.data?.data[i].content);
          const _data = {
            id: response.data?.data[i].id,
            question: _arr ? _arr[0] : '',
            answer: _arr ? _arr[1] : '',
            status: response.data?.data[i].status,
          };
          res.push(_data);
        }
        setData(res);
      }
    });
  };

  const handleCreate = useCallback(() => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate(`/course-management/faq-page/${id}`);
  }, [id]);

  return (
    <ListsContainer>
      <Button
        id='backToList'
        onClick={() => navigate('/course-management/courses')}
        color='secondary'
        variant='outlined'
      >
        بازگشت به لیست دوره ها
      </Button>
      <Table
        key={key}
        title={`لیست پرسش های متداول ${courseName}`}
        columns={columns}
        createSlug='افزودن'
        actions={actions}
        createAction={handleCreate}
        onRowClick
        tableRef={tableRef}
        data={data}
        pageSize={pageSize}
        filtering={false}
        sortableColumns
        totalPages={totalPageNumber}
        getOtherPageData={onChangeGetOtherPageData}
      />
    </ListsContainer>
  );
};

export default CourseFaqList;
