import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';
import {
  Dashboard as DashboardView,
  NotFound as NotFoundView,
  SliderList as SliderListView,
  SliderPage as SliderPageView,
  SlideShowList as SlideShowListView,
  SlideShowPage as SlideShowPageView,
  MenuList as MenuListView,
  MenuPage as MenuPageView,
  MenuItemsList as MenuItemsListView,
  MenuItemsPage as MenuItemsPageView,
  SettingSectionList as SettingSectionListView,
  SettingSectionPage as SettingSectionPageView,
  SettingList as SettingListView,
  SettingPage as SettingPageView,
  PagesList as PagesListView,
  PagesPage as PagesPageView,
  ServiceCategoryList as ServiceCategoryListView,
  ServiceCategoryPage as ServiceCategoryPageView,
  ServiceList as ServiceListView,
  ServicePage as ServicePageView,
  CustomerList as CustomerListView,
  CustomerPage as CustomerPageView,
  VideoList as VideoListView,
  VideoPage as VideoPageView,
  CourseList as CourseListView,
  CoursePage as CoursePageView,
  RoleList as RoleListView,
  RolePage as RolePageView,
  DeliveryList as DeliveryListView,
  DeliveryPage as DeliveryPageView,
  UserList as UserListView,
  UserPage as UserPageView,
  ProfilePage as ProfilePageView,
  ContactFormList as ContactFormListView,
  ContactFormPage as ContactFormPageView,
  DiscountList as DiscountListView,
  DiscountPage as DiscountPageView,
  CourseCommentsList as CourseCommentsListView,
  CourseFaqList as CourseFaqListView,
  CourseFaqPage as CourseFaqPageView,
  ProductCategoryList as ProductCategoryListView,
  ProductCategoryPage as ProductCategoryPageView,
  MainProductCategoryList as MainProductCategoryListView,
  MainProductCategoryPage as MainProductCategoryPageView,
  AttributeCategoryList as AttributeCategoryListView,
  AttributeCategoryPage as AttributeCategoryPageView,
  BrandList as BrandListView,
  BrandPage as BrandPageView,
  UnitList as UnitListView,
  UnitPage as UnitPageView,
  AttributeList as AttributeListView,
  AttributePage as AttributePageView,
  ProductList as ProductListView,
  ProductPage as ProductPageView,
  ProductAttributePage as ProductAttributePageView,
  ProductAttributeQuantityPage as ProductAttributeQuantityPageView,
  CustomersReportList as CustomersReportListView,
  DiscountsReportList as DiscountsReportListView,
  OrdersReportList as OrdersReportListView,
  ProductsReportList as ProductsReportListView,
  ProvincesList as ProvincesListView,
  CitiesList as CitiesListView,
  ProductCommentsList as ProductCommentsListView,
  OrdersList as OrdersListView,
  OrdersPage as OrdersPageView,
  OrderStatusList as OrderStatusListView,
  OrderStatusPage as OrderStatusPageView,
  CurrencyPage as CurrencyPageView,
  BlogList as BlogListView,
  BlogPage as BlogPageView,
} from './views';
import Login from './views/Login/Index';
import Logout from './views/Logout/Logout';
import { DashboardPanelContext } from './components/context/DashboardContext';
import { SET_ERROR } from './components/context/reducer/actionTypes';
import DontHavePermission from './views/403';

const AppRoutes = () => {
  const [path, setPath] = useState('/dashboard');
  const cookies = new Cookies();
  const { globalDispatch, globalState } = useContext(DashboardPanelContext);
  const location = useLocation();

  useEffect(() => {
    if (globalState.error !== undefined) globalDispatch({ type: SET_ERROR, value: undefined });
    if (cookies.get('userToken') && location.pathname !== '/login') {
      setPath(location.pathname);
    }

    if (!cookies.get('userToken') && location.pathname === '/') {
      setPath('/login');
    }
    if (location.pathname === '/logout' || location.pathname === '/') {
      setPath('/dashboard');
    }
  }, [location]);
  return (
    <Routes>
      {/* <Route path='/' element={<RequireAuthToken redirectTo={path} />} /> */}
      <Route path='/login' name='Login Page' element={<Login />} />
      <Route element={<Logout />} path='/logout' />
      <Route element={<DontHavePermission />} path='/403' />
      <Route
        element={<RouteWithLayout component={DashboardView} layout={MainLayout} path='/dashboard' />}
        path='/dashboard'
      />
      <Route
        element={<RouteWithLayout component={SliderListView} layout={MainLayout} path='/cms/slider-list' />}
        path='/cms/slider-list'
      />
      <Route
        element={<RouteWithLayout component={SliderPageView} layout={MainLayout} path='/cms/slider/:id?' />}
        path='/cms/slider/:id?'
      />
      <Route
        element={<RouteWithLayout component={SlideShowListView} layout={MainLayout} path='/cms/slideshow-list' />}
        path='/cms/slideshow-list'
      />
      <Route
        element={<RouteWithLayout component={SlideShowPageView} layout={MainLayout} path='/cms/slideshow/:id?' />}
        path='/cms/slideshow/:id?'
      />
      <Route
        element={<RouteWithLayout component={MenuListView} layout={MainLayout} path='/cms/menu-list' />}
        path='/cms/menu-list'
      />
      <Route
        element={<RouteWithLayout component={MenuPageView} layout={MainLayout} path='/cms/menu/:id?' />}
        path='/cms/menu/:id?'
      />
      <Route
        element={<RouteWithLayout component={MenuItemsListView} layout={MainLayout} path='/cms/menu-items' />}
        path='/cms/menu-items'
      />
      <Route
        element={<RouteWithLayout component={MenuItemsPageView} layout={MainLayout} path='/cms/menu-item/:id?' />}
        path='/cms/menu-item/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={SettingSectionListView} layout={MainLayout} path='/cms/setting-sections' />
        }
        path='/cms/setting-sections'
      />
      <Route
        element={
          <RouteWithLayout component={SettingSectionPageView} layout={MainLayout} path='/cms/setting-section/:id?' />
        }
        path='/cms/setting-section/:id?'
      />
      <Route
        element={<RouteWithLayout component={SettingListView} layout={MainLayout} path='/cms/settings' />}
        path='/cms/settings'
      />
      <Route
        element={<RouteWithLayout component={SettingPageView} layout={MainLayout} path='/cms/setting/:id?' />}
        path='/cms/setting/:id?'
      />
      <Route
        element={<RouteWithLayout component={PagesListView} layout={MainLayout} path='/cms/pages' />}
        path='/cms/pages'
      />
      <Route
        element={<RouteWithLayout component={PagesPageView} layout={MainLayout} path='/cms/page/:id?' />}
        path='/cms/page/:id?'
      />
      <Route
        element={<RouteWithLayout component={BlogListView} layout={MainLayout} path='/cms/blogs' />}
        path='/cms/blogs'
      />
      <Route
        element={<RouteWithLayout component={BlogPageView} layout={MainLayout} path='/cms/blog/:id?' />}
        path='/cms/blog/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={ServiceCategoryListView}
            layout={MainLayout}
            path='/salon-management/service-categories'
          />
        }
        path='/salon-management/service-categories'
      />
      <Route
        element={
          <RouteWithLayout
            component={ServiceCategoryPageView}
            layout={MainLayout}
            path='/salon-management/service-category/:id?'
          />
        }
        path='/salon-management/service-category/:id?'
      />
      <Route
        element={<RouteWithLayout component={ServiceListView} layout={MainLayout} path='/salon-management/services' />}
        path='/salon-management/services'
      />
      <Route
        element={
          <RouteWithLayout component={ServicePageView} layout={MainLayout} path='/salon-management/service/:id?' />
        }
        path='/salon-management/service/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={CustomerListView} layout={MainLayout} path='/customer-management/customers' />
        }
        path='/customer-management/customers'
      />
      <Route
        element={
          <RouteWithLayout component={CustomerPageView} layout={MainLayout} path='/customer-management/customer/:id?' />
        }
        path='/customer-management/customer/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={DiscountListView} layout={MainLayout} path='/customer-management/discounts' />
        }
        path='/customer-management/discounts'
      />
      <Route
        element={
          <RouteWithLayout component={DiscountPageView} layout={MainLayout} path='/customer-management/discount/:id?' />
        }
        path='/customer-management/discount/:id?'
      />
      <Route
        element={<RouteWithLayout component={VideoListView} layout={MainLayout} path='/course-management/videos' />}
        path='/course-management/videos'
      />
      <Route
        element={<RouteWithLayout component={VideoPageView} layout={MainLayout} path='/course-management/video/:id?' />}
        path='/course-management/video/:id?'
      />
      <Route
        element={<RouteWithLayout component={CourseListView} layout={MainLayout} path='/course-management/courses' />}
        path='/course-management/courses'
      />
      <Route
        element={
          <RouteWithLayout component={CoursePageView} layout={MainLayout} path='/course-management/course/:id?' />
        }
        path='/course-management/course/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={CourseCommentsListView}
            layout={MainLayout}
            path='/course-management/comments/:id?'
          />
        }
        path='/course-management/comments/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={CourseFaqListView} layout={MainLayout} path='/course-management/faq/:id?' />
        }
        path='/course-management/faq/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={CourseFaqPageView}
            layout={MainLayout}
            path='/course-management/faq-page/:courseId?/:id?'
          />
        }
        path='/course-management/faq-page/:courseId?/:id?'
      />
      <Route
        element={<RouteWithLayout component={RoleListView} layout={MainLayout} path='/access-management/roles' />}
        path='/access-management/roles'
      />
      <Route
        element={<RouteWithLayout component={RolePageView} layout={MainLayout} path='/access-management/role/:id?' />}
        path='/access-management/role/:id?'
      />
      <Route
        element={<RouteWithLayout component={UserListView} layout={MainLayout} path='/access-management/users' />}
        path='/access-management/users'
      />
      <Route
        element={<RouteWithLayout component={UserPageView} layout={MainLayout} path='/access-management/user/:id?' />}
        path='/access-management/user/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={DeliveryListView}
            layout={MainLayout}
            path='/delivery-management/delivery-methods'
          />
        }
        path='/delivery-management/delivery-methods'
      />
      <Route
        element={
          <RouteWithLayout
            component={DeliveryPageView}
            layout={MainLayout}
            path='/delivery-management/delivery-method/:id?'
          />
        }
        path='/delivery-management/delivery-method/:id?'
      />

      <Route
        element={<RouteWithLayout component={ProfilePageView} layout={MainLayout} path='/profile' />}
        path='/profile'
      />
      <Route
        element={
          <RouteWithLayout component={ContactFormListView} layout={MainLayout} path='/salon-management/contact-forms' />
        }
        path='/salon-management/contact-forms'
      />
      <Route
        element={
          <RouteWithLayout
            component={ContactFormPageView}
            layout={MainLayout}
            path='/salon-management/contact-form/:id?'
          />
        }
        path='/salon-management/contact-form/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={ProductCategoryListView}
            layout={MainLayout}
            path='/product-management/product-categories'
          />
        }
        path='/product-management/product-categories'
      />
      <Route
        element={
          <RouteWithLayout
            component={ProductCategoryPageView}
            layout={MainLayout}
            path='/product-management/product-category/:id?'
          />
        }
        path='/product-management/product-category/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={MainProductCategoryListView}
            layout={MainLayout}
            path='/product-management/main-product-categories'
          />
        }
        path='/product-management/main-product-categories'
      />
      <Route
        element={
          <RouteWithLayout
            component={MainProductCategoryPageView}
            layout={MainLayout}
            path='/product-management/main-product-category/:id?'
          />
        }
        path='/product-management/main-product-category/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={AttributeCategoryListView}
            layout={MainLayout}
            path='/product-management/attribute-categories'
          />
        }
        path='/product-management/attribute-categories'
      />
      <Route
        element={
          <RouteWithLayout
            component={AttributeCategoryPageView}
            layout={MainLayout}
            path='/product-management/attribute-category/:id?'
          />
        }
        path='/product-management/attribute-category/:id?'
      />
      <Route
        element={<RouteWithLayout component={BrandListView} layout={MainLayout} path='/product-management/brands' />}
        path='/product-management/brands'
      />
      <Route
        element={
          <RouteWithLayout component={BrandPageView} layout={MainLayout} path='/product-management/brand/:id?' />
        }
        path='/product-management/brand/:id?'
      />
      <Route
        element={<RouteWithLayout component={UnitListView} layout={MainLayout} path='/product-management/units' />}
        path='/product-management/units'
      />
      <Route
        element={<RouteWithLayout component={UnitPageView} layout={MainLayout} path='/product-management/unit/:id?' />}
        path='/product-management/unit/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={AttributeListView} layout={MainLayout} path='/product-management/attributes' />
        }
        path='/product-management/attributes'
      />
      <Route
        element={
          <RouteWithLayout
            component={AttributePageView}
            layout={MainLayout}
            path='/product-management/attribute/:id?'
          />
        }
        path='/product-management/attribute/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={ProductListView} layout={MainLayout} path='/product-management/products' />
        }
        path='/product-management/products'
      />
      <Route
        element={
          <RouteWithLayout component={ProductPageView} layout={MainLayout} path='/product-management/product/:id?' />
        }
        path='/product-management/product/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={ProductAttributePageView}
            layout={MainLayout}
            path='/product-management/product-attribute/:productId?/:id?'
          />
        }
        path='/product-management/product-attribute/:productId?/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={ProductAttributeQuantityPageView}
            layout={MainLayout}
            path='/product-management/product-attribute-quantity/:productId?/:id?'
          />
        }
        path='/product-management/product-attribute-quantity/:productId?/:id?'
      />
      <Route
        element={
          <RouteWithLayout
            component={ProductCommentsListView}
            layout={MainLayout}
            path='/product-management/comments/:id?'
          />
        }
        path='/product-management/comments/:id?'
      />
      <Route
        element={<RouteWithLayout component={CustomersReportListView} layout={MainLayout} path='/reports/customers' />}
        path='/reports/customers'
      />
      <Route
        element={<RouteWithLayout component={DiscountsReportListView} layout={MainLayout} path='/reports/discounts' />}
        path='/reports/discounts'
      />
      <Route
        element={<RouteWithLayout component={OrdersReportListView} layout={MainLayout} path='/reports/orders' />}
        path='/reports/orders'
      />
      <Route
        element={<RouteWithLayout component={ProductsReportListView} layout={MainLayout} path='/reports/products' />}
        path='/reports/products'
      />
      <Route
        element={
          <RouteWithLayout component={ProvincesListView} layout={MainLayout} path='/order-management/provinces' />
        }
        path='/order-management/provinces'
      />
      <Route
        element={<RouteWithLayout component={CitiesListView} layout={MainLayout} path='/order-management/cities' />}
        path='/order-management/cities'
      />
      <Route
        element={<RouteWithLayout component={OrdersListView} layout={MainLayout} path='/order-management/orders' />}
        path='/order-management/orders'
      />
      <Route
        element={<RouteWithLayout component={OrdersPageView} layout={MainLayout} path='/order-management/order/:id?' />}
        path='/order-management/order/:id?'
      />
      <Route
        element={
          <RouteWithLayout component={OrderStatusListView} layout={MainLayout} path='/order-management/orders-status' />
        }
        path='/order-management/orders-status'
      />
      <Route
        element={
          <RouteWithLayout
            component={CurrencyPageView}
            layout={MainLayout}
            path='/settings-management/get-default-currency'
          />
        }
        path='/settings-management/get-default-currency'
      />
      <Route
        element={
          <RouteWithLayout
            component={OrderStatusPageView}
            layout={MainLayout}
            path='/order-management/order-status/:id?'
          />
        }
        path='/order-management/order-status/:id?'
      />
      <Route element={<RouteWithLayout component={DashboardView} layout={MainLayout} path={path} />} path='/' />
      <Route path='*' element={<RouteWithLayout component={NotFoundView} layout={MainLayout} path='/not-found' />} />
    </Routes>
  );
};

export default React.memo(AppRoutes);
