import React, { useEffect, useContext, useState, useCallback } from 'react';
import { get } from 'lodash';
import '../../assets/scss/generalStyles/_fonts.scss';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Table, Pagination, Tooltip, Button } from 'antd';
import { DashboardPanelContext } from '../context/DashboardContext';
import { BSwalConfirmationDelete, BSwalDeleted, BSwalShowError } from '../../common/BSwal';
import { SET_VIEW_READ_ONLY } from '../context/reducer/actionTypes';
import { font } from '../configs/Variables';
import SlidingButton from '../buttons/Sliding';
import './table.css';
import useTable from './useTable';

const CustomTable = ({
  tableRef,
  columns = [],
  data,
  name,
  filtering = true,
  pageSize = 10,
  actions,
  title,
  createSlug,
  location,
  createAction,
  sortableColumns,
  onRowClick = false,
  tableKey = 0,
  removePath = null,
  removeFunction = undefined,
  totalPages = undefined,
  getOtherPageData = undefined,
  handleOpenModal = undefined,
  extraActions = [],
  actionCallback = undefined,
  currentPage = undefined,
  hasRowSelection = undefined,
}) => {
  const navigate = useNavigate();
  const { parentColumns, setParentColumns, count, setCount } = useTable({ columns });
  const { globalDispatch, removeItem } = useContext(DashboardPanelContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onPageChange = (current) => {
    getOtherPageData(current);
  };

  const customText = {
    filterConfirm: 'Confirm', // Custom text for filter confirmation button
    filterReset: 'Reset', // Custom text for filter reset button
  };
  const tableLocale = { filterConfirm: customText.filterConfirm, filterReset: customText.filterReset };
  useEffect(() => {
    if (actions.length > 0) {
      setParentColumns(() => {
        const newState = [...columns];
        newState.push({
          title: 'Actions',
          key: 'action',
          render: (_, record) => (
            <Space size='middle'>
              {actions[0] && (
                <EditOutlined
                  onClick={async () => {
                    if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
                    if (actionCallback) {
                      actionCallback('edit', record.id);
                    } else if (actions[0] === 'modal' && handleOpenModal) {
                      handleOpenModal(record.id);
                    } else {
                      navigate(`${actions[0]}${record.id}`);
                    }
                  }}
                />
              )}
              {actions[3] && onRowClick && (
                <EyeOutlined
                  onClick={async () => {
                    if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
                    navigate(`${actions[3]}${record.id}`);
                  }}
                />
              )}
              {extraActions.map((extraAction) => {
                if (extraAction.url) {
                  const IconComponent = extraAction.icon;
                  return (
                    <Tooltip title={extraAction.title}>
                      <IconComponent
                        onClick={async () => {
                          if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
                          if (extraAction.callback) {
                            extraAction.callback(`${record.id}`);
                          } else {
                            navigate(`${extraAction.url}${record.id}`);
                          }
                        }}
                      />
                    </Tooltip>
                  );
                }

                return null;
              })}
              {actions[1] && (
                <DeleteOutlined
                  onClick={async () => {
                    BSwalConfirmationDelete().then((result) => {
                      if (result.value) {
                        if (removePath) {
                          removeFunction(record.id);
                        } else {
                          removeItem(actions[1], record.id)
                            .then((response) => {
                              if (response) {
                                if (response.status === 200 || response.status === 204) {
                                  tableRef?.current?.onQueryChange();
                                  BSwalDeleted();
                                }
                                window.location.reload();
                                if (response.status === 409) {
                                  BSwalShowError(get(response, 'data.messages', []));
                                }
                              }
                            })
                            .finally();
                        }
                      }
                    });
                  }}
                />
              )}
            </Space>
          ),
        });
        return [...newState];
      });
      setCount(count + 1);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onRowSelectionCallbak = useCallback(() => {
    if (hasRowSelection.callback) hasRowSelection.callback(selectedRowKeys);
  }, [hasRowSelection, selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <>
      {hasRowSelection && (
        <Button onClick={onRowSelectionCallbak} disabled={!hasSelected}>
          {hasRowSelection.button}
        </Button>
      )}
      <Table
        rowSelection={hasRowSelection ? rowSelection : undefined}
        locale={tableLocale}
        id={name}
        columns={parentColumns}
        dataSource={data}
        key={count + tableKey}
        tableRef={tableRef}
        title={() => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 style={{ margin: 0, fontFamily: font, fontSize: 15 }}>{title}</h1>
            {actions[2] && createSlug && createAction && (
              <SlidingButton
                style={{ marginRight: 25, marginLeft: 25 }}
                label={createSlug}
                location={location}
                onClick={createAction}
                id='newItem'
              />
            )}
          </div>
        )}
        sorter={sortableColumns}
        filterSearch={filtering}
        pagination={false}
      />
      <br />
      {totalPages && (
        <Pagination
          current={currentPage}
          onChange={onPageChange}
          defaultCurrent={1}
          total={totalPages}
          showSizeChanger={false}
        />
      )}
    </>
  );
};

export default CustomTable;
