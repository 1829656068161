import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, Space } from 'antd';
import { SearchOutlined, SlidersOutlined, MessageOutlined, ProjectOutlined } from '@ant-design/icons';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY, SET_SAVE_SUCCESS } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';

const ProductList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const searchInput = useRef(null);
  const [searchProduct, setSearchProduct] = useState(false);
  const searchPage = searchParams.get('page');
  const searchTitle = searchParams.get('title');
  const currentPage = Number.isNaN(parseInt(searchParams.get('page'), 10)) ? 1 : parseInt(searchParams.get('page'), 10);
  const currentPageRef = useRef(currentPage);
  const [searchQuery, setSearchQuery] = useState(searchTitle ?? '');
  const searchTitleRef = useRef(searchTitle);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const onChangeGetOtherPageData = (page) => {
    const updatedSearchParams = new URLSearchParams();

    if (page) {
      updatedSearchParams.set('page', String(page));
    } else {
      updatedSearchParams.delete('page');
    }

    if (searchTitleRef.current) {
      updatedSearchParams.set('title', searchTitleRef.current);
    } else {
      updatedSearchParams.delete('title');
    }

    setSearchParams(updatedSearchParams);

    if (searchTitleRef.current) {
      saveItem(`/product/search?page=${page}`, { title: searchTitleRef.current }).then(async (response) => {
        globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
        const number_of_pages = response.data?.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        if (response) {
          const allData = [];
          for (let i = 0; i < response.data?.data?.data?.length; i++) {
            allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
          }
          setData(allData);
        }
      });
    } else {
      fetchItem({
        modelName: `/product?page=${page}`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const allData = [];
          for (let i = 0; i < response.data?.data.length; i++) {
            allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
          }
          setData(allData);
        }
      });
    }
  };

  const getAllProducts = useCallback(() => {
    fetchItem({
      modelName: `/product`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        if ((searchPage && searchPage !== '1') || searchTitleRef.current) {
          onChangeGetOtherPageData(searchPage);
        } else {
          for (let i = 0; i < response.data?.data.length; i++) {
            allData.push({ ...response.data?.data[i], key: response.data?.data[i].id });
          }
          setData(allData);
        }
      }
    });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const query = selectedKeys[0];
    setSearchQuery(query); // Update the search query state
    setSearchParams({ title: query });
    searchTitleRef.current = query;
    saveItem('/product/search', { title: query }).then(async (response) => {
      globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
      if (response) {
        const allData = [];
        const number_of_pages = response.data?.data?.last_page;
        setTotalPageNumber(number_of_pages * 10);
        for (let i = 0; i < response.data?.data?.data?.length; i++) {
          allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
        }
        setData(allData);
      }
    });
  };
  const handleReset = (clearFilters, close) => {
    clearFilters();
    setSearchQuery('');
    setSearchParams(new URLSearchParams());
    searchTitleRef.current = '';
    getAllProducts();
    close();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder='Search'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, close)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              // setSearchText(selectedKeys[0]);
              // setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) => {
    //   console.log('record', record);
    //   console.log('dataIndex', dataIndex);
    //   console.log('val', value);
    //   saveItem('/product/search', { title: value }).then((res) => console.log('res', res));
    //   // return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    // },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  useEffect(() => {
    getAllProducts();
  }, [searchProduct, getAllProducts]);

  useEffect(() => {
    setColumns([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (item) => item.name,
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        render: (item) => item.name,
      },
      // {
      //   title: 'موجودی',
      //   dataIndex: 'inventory',
      //   key: 'inventory',
      //   render: (item) => item?.quantity || 0,
      // },
      {
        title: 'Publication',
        dataIndex: 'active',
        key: 'active',
        type: 'boolean',
        // filters: [
        //   {
        //     text: 'منتشر شده',
        //     value: '1' || 1 || true,
        //   },
        //   {
        //     text: 'منتشر نشده',
        //     value: 0,
        //   },
        // ],
        // onFilter: (value, record) => console.log('rec', record, value),
        sorter: (a, b) => Number(a.active) - Number(b.active),
        sortDirections: ['descend'],
        render: (rowData) => (rowData === 1 ? 'Published' : 'Unpublished'),
      },
      {
        title: 'Status',
        dataIndex: 'display',
        key: 'display',
        type: 'boolean',
        // filters: [
        //   {
        //     text: 'فعال',
        //     value: '1',
        //   },
        //   {
        //     text: 'غیر فعال',
        //     value: '0',
        //   },
        // ],
        // onFilter: (value, record) => record.display === value,
        sorter: (a, b) => Number(a.display) - Number(b.display),
        sortDirections: ['descend'],
        render: EnabledColumn,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'product.update',
    deletePermission: 'product.destroy',
    editURL: '/product-management/product/',
    removePATH: 'product',
    createPermission: 'product.store',
    extraActions: ['admin.product.get_comments.by.id', '/product-management/comments/'],
    extraActionsAttribute: ['show-product-attribute-options', '/product-management/product-attribute/'],
    // duplicatePermission: 'duplicate',
    extraActionsQuantity: ['show-product-attribute-options', '/product-management/product-attribute-quantity/'],
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/product-management/product');
  };

  // const onDuplicateProduct = (productId) => {
  //   fetchItem({
  //     modelName: `/product/${productId}`,
  //     showLoader: true,
  //   }).then((response) => {
  //     if (response.data) {
  //       const initialItem = {
  //         name: response.data.name,
  //         name_en: response.data.name_en,
  //         product_category_id: response.data.category?.id || null,
  //         brand_id: response.data.brand?.id || null,
  //         description: response.data.description,
  //         instruction: response.data.instruction,
  //         base_price: response.data.base_price,
  //         off_price: response.data.off_price,
  //         new: response.data.new,
  //         special: response.data.special,
  //         popular: response.data.popular,
  //         active: response.data.active,
  //         display: response.data.display,
  //       };
  //       saveItem(`product`, initialItem, null).then(() => setSearchProduct((prev) => !prev));
  //     }
  //   });
  // };

  const onSetAttribute = (productId) => {
    if (searchTitleRef.current) {
      navigate(
        `/product-management/product-attribute/${productId}?page=${currentPageRef.current}&title=${searchTitleRef.current}`,
      );
    } else {
      navigate(`/product-management/product-attribute/${productId}?page=${currentPageRef.current}`);
    }
  };
  const onSetVariant = (productId) => {
    if (searchTitleRef.current) {
      navigate(
        `/product-management/product-attribute-quantity/${productId}?page=${currentPageRef.current}&title=${searchTitleRef.current}`,
      );
    } else {
      navigate(`/product-management/product-attribute-quantity/${productId}?page=${currentPageRef.current}`);
    }
  };
  const onEditProduct = (type, recordId) => {
    if (type === 'edit') {
      if (searchTitleRef.current) {
        navigate(
          `/product-management/product/${recordId}?page=${currentPageRef.current}&title=${searchTitleRef.current}`,
        );
      } else {
        navigate(`/product-management/product/${recordId}?page=${currentPageRef.current}`);
      }
    }
  };
  return (
    <>
      <ListsContainer>
        <Table
          tableKey={key}
          key={key}
          title='Products'
          columns={columns}
          createSlug='Add to Products'
          actions={actions}
          createAction={handleCreate}
          tableRef={tableRef}
          data={data}
          pageSize={pageSize}
          filtering={false}
          sortableColumns
          totalPages={totalPageNumber}
          getOtherPageData={onChangeGetOtherPageData}
          actionCallback={(type, record) => {
            onEditProduct(type, record);
          }}
          extraActions={[
            {
              title: 'Add Product Attributes',
              url: actions[6],
              icon: SlidersOutlined,
              callback: (record) => onSetAttribute(record),
            },
            {
              title: 'Add Product Quantities',
              url: actions[8],
              icon: ProjectOutlined,
              callback: (record) => onSetVariant(record),
            },
            // {
            //   title: 'ایجاد کپی از محصول',
            //   url: actions[7],
            //   icon: CopyOutlined,
            //   callback: (record) => onDuplicateProduct(record),
            // },
            { title: 'Comments', url: actions[4], icon: MessageOutlined },
          ]}
          currentPage={parseInt(searchParams.get('page'), 10) || 1}
        />
      </ListsContainer>
    </>
  );
};

export default ProductList;
