export const Table = {
  fa: {
    search: 'جستجو',
    operation: 'عملیات',
    row: 'سطر',
    columnSearch: 'جستجوی ستون',
    emptyData: 'رکوردی برای نمایش وجود ندارد!',
    excelFile: 'فایل اکسل',
    downloadExcel: 'دانلود اکسل',
    firstPage: 'اولین صفحه',
    lastPage: 'آخرین صفحه',
    All: 'همه',
    nextPage: 'صفحه بعد',
    previousPage: 'صفحه قبل',
    from: 'از',
  },
  en: {
    search: 'search',
    operation: 'operation',
    row: 'rows',
    columnSearch: 'column search',
    emptyData: 'No records to display!',
    excelFile: 'excel file',
    downloadExcel: 'export excel',
    firstPage: 'first page',
    lastPage: 'last page',
    All: 'All',
    nextPage: 'next page',
    previousPage: 'previous page',
    from: 'from',
  },
};

export const Enabled = {
  fa: {
    true: 'فعال',
    false: 'غیرفعال',
  },
  en: {
    true: 'enabled',
    false: 'disabled',
  },
};

export const Default = {
  fa: {
    phone: 'تلفن',
    phoneNumber: 'شماره تماس',
    address: 'آدرس',
    description: 'توضیحات',
    postalCode: 'کدپستی',
    details: 'مشاهده جزئیات',
    addNewItem: 'ایجاد مورد جدید',
    create: 'ایجاد',
    delete: 'حذف',
    edit: 'ویرایش',
    back: 'بازگشت',
    submit: 'ثبت',
    cancel: 'لغو',
    enabled: 'وضعیت',
    registerDate: 'تاریخ ثبت',
    startDate: 'تاریخ شروع',
    endDate: 'تاریخ پایان',
    username: 'نام کاربری',
    firstName: 'نام',
    lastName: 'نام خانوادگی',
    fatherName: 'نام پدر',
    legalName: 'نام حقوقی',
    birthDate: 'تاریخ تولد',
    maritalStatus: 'وضعیت تاهل',
    gender: 'جنسیت',
    nationalCode: 'کد ملی',
    identificationCode: 'شماره شناسنامه',
    telegram: 'تلگرام',
    whatsapp: 'واتساپ',
    fax: 'فکس',
    mobile: 'موبایل',
    email: 'ایمیل',
    other: 'سایر',
    nextStep: 'مرحله بعد',
    previousStep: 'مرحله قبل',
    enterAmount: 'مقدار را وارد کنید',
    height: 'ارتفاع',
    width: 'عرض',
    length: 'طول',
    weight: 'وزن',
    volume: 'حجم',
    barcode: 'بارکد',
    language: 'زبان',
    translation: 'ترجمه',
    formalPrice: 'قیمت رسمی',
    informalPrice: 'قیمت غیر رسمی',
    coverPrice: 'قیمت روی جلد',
    count: 'تعداد',
    productCount: 'تعداد کالا',
    noOptionAvailable: 'موردی وجود ندارد',
    noOptionMatched: 'موردی یافت نشد',
    exit: 'خروج',
    basicInfo: 'اطلاعات پایه',
    personnel: 'پرسنل',
    accountInformation: 'اطلاعات حساب',
    createNewItem: 'ایجاد مورد جدید',
    veryBad: 'خیلی بد',
    bad: 'بد',
    mediocre: 'متوسط',
    good: 'خوب',
    perfect: 'عالی',
    seller: 'فروشنده',
    purchaser: 'مسئول سفارش',
    receiver: 'تحویل گیرنده',
    morning: 'صبح',
    noon: 'ظهر',
    afternoon: 'عصر',
    night: 'شب',
    all: 'همه',
    shift: 'شیفت',
    accountNumber: 'شماره حساب',
    alternativeAccountNumber: 'شماره حساب جایگزین ',
    bankName: 'نام بانک',
    accountType: 'نوع حساب',
  },
  en: {
    phone: 'phone',
    phoneNumber: 'phoneNumber',
    address: 'address',
    description: 'description',
    postalCode: 'postal code',
    addNewItem: 'add new item',
    create: 'create',
    delete: 'delete',
    edit: 'edit',
    back: 'back',
    submit: 'submit',
    cancel: 'cancel',
    enabled: 'status',
    registerDate: 'Register Date',
    startDate: 'start date',
    endDate: 'end date',
    username: 'username',
    firstName: 'first name',
    lastName: 'last name',
    fatherName: 'father name',
    legalName: 'legal name',
    birthDate: 'birth date',
    maritalStatus: 'marital status',
    gender: 'gender',
    nationalCode: 'national code',
    identificationCode: 'identification code',
    telegram: 'telegram',
    whatsapp: "what's app",
    fax: 'fax',
    mobile: 'mobile',
    email: 'email',
    other: 'other',
    nextStep: 'next step',
    previousStep: 'previous step',
    enterAmount: 'enter amount',
    height: 'height',
    width: 'width',
    length: 'length',
    weight: 'weight',
    volume: 'volume',
    barcode: 'barcode',
    language: 'language',
    translation: 'translation',
    formalPrice: 'formal price',
    informalPrice: 'informal price',
    coverPrice: 'cover price',
    count: 'count',
    productCount: 'product count',
    noOptionAvailable: 'no option is available',
    noOptionMatched: 'there is no option matched',
    exit: 'Logout',
    basicInfo: 'basic information',
    personnel: 'personnel',
    accountInformation: 'account information',
    createNewItem: 'create new item',
    veryBad: 'very bad',
    bad: 'bad',
    mediocre: 'mediocre',
    good: 'good',
    perfect: 'perfect',
    seller: 'seller',
    purchaser: 'purchaser',
    receiver: 'receiver',
    morning: 'morning',
    noon: 'noon',
    afternoon: 'afternoon',
    night: 'night',
    all: 'all',
    shift: 'shift',
    accountNumber: 'accountNumber',
    alternativeAccountNumber: 'alternative account number',
    bankName: 'bank name',
    accountType: 'account type',
  },
};

export const dashboardTitles = {
  fa: {
    customerCreditManagement: 'مدیریت اعتبار مشتری',
    staffCreditManagement: 'مدیریت اعتبار پرسنل',
    creditManagement: 'مدیریت اعتبار',
    countryDivisions: 'تقسیم‌بندی کشوری',
    salesDivisions: 'بخش‌بندی فروش',
    productHierarchy: 'سلسله مراتب کالا',
    productManagement: 'مدیریت محصولات',
    orderManagement: 'مدیریت سفارش‌ها',
    contentManagement: 'مدیریت محتوا',
    serviceManagement: 'مدیریت خدمات',
    customerManagement: 'مدیریت مشتریان',
    courseManagement: 'مدیریت دوره‌های آموزشی',
    reports: 'گزارش‌ها',
    accessManagement: 'مدیریت دسترسی',
    siteSettings: 'مدیریت تنظیمات سایت',
    productCategories: 'دسته بندی محصولات',
    mainProductCategories: 'دسته‌های والد',
    subProductCategories: 'دسته‌های فرعی و اصلی',
    attributeCategories: 'دسته بندی ویژگی‌های محصولات',
    brands: 'برندها',
    units: 'واحدها',
    attributes: 'ویژگی‌های محصولات',
    allProducts: 'همه محصولات',
    discounts: 'تخفیف‌ها',
    provincesManagement: 'مدیریت استان‌ها',
    citiesManagement: 'مدیریت شهر‌ها',
    deliveryMethods: 'مدیریت روش‌های ارسال',
    orderStatus: 'لیست وضعیت سفارش‌ها',
    ordersList: 'لیست سفارش‌ها',
    slideshowManagement: 'مدیریت اسلایدشو',
    slideshowList: 'لیست اسلایدشو',
    developerSliderList: 'لیست گالری اسلایدشو (برنامه‌نویس)',
    menuManagement: 'مدیریت منو',
    menuItemsList: 'لیست آیتم‌های منو',
    developerMenuList: 'لیست منوها (برنامه‌نویس)',
    pagesManagement: 'مدیریت صفحات',
    pagesList: 'لیست صفحات',
    settingsManagement: 'مدیریت تنظیمات',
    settingsList: 'لیست تنظیمات',
    developerSettingSections: 'لیست قسمت‌های تنظیمات (برنامه‌نویس)',
    blogManagement: 'مدیریت وبلاگ',
    blogsList: 'لیست وبلاگ‌ها',
    servicesManagement: 'مدیریت سرویس‌ها',
    serviceCategories: 'لیست کتگوری سرویس‌ها',
    servicesList: 'لیست سرویس‌ها',
    consultationRequests: 'مدیریت درخواست‌های مشاوره',
    contactFormsList: 'لیست درخواست‌های مشاوره',
    customersList: 'لیست مشتریان',
    videosList: 'لیست ویدیوها',
    coursesList: 'لیست دوره‌ها',
    reportsByProduct: 'گزارش بر اساس محصول',
    reportsByOrder: 'گزارش بر اساس سفارش',
    reportsByCustomer: 'گزارش بر اساس مشتری',
    reportsByDiscount: 'گزارش بر اساس کد تخفیف',
    rolesList: 'لیست نقش‌ها',
    usersList: 'لیست کاربران',
    defaultCurrency: 'واحد پولی پیش‌فرض',
  },
  en: {
    customerCreditManagement: 'Customer Credit Management',
    staffCreditManagement: 'Staff Credit Management',
    creditManagement: 'Credit Management',
    countryDivisions: 'Country Divisions',
    salesDivisions: 'Sale division',
    productHierarchy: 'Product hierarchy',
    productManagement: 'Product Management',
    orderManagement: 'Order Management',
    contentManagement: 'Content Management',
    serviceManagement: 'Service Management',
    customerManagement: 'Customer Management',
    courseManagement: 'Course Management',
    reports: 'Reports',
    accessManagement: 'Access Management',
    siteSettings: 'Site Settings',
    productCategories: 'Product Categories',
    mainProductCategories: 'Main Categories',
    subProductCategories: 'Sub Categories',
    attributeCategories: 'Attribute Categories',
    brands: 'Brands',
    units: 'Units',
    attributes: 'Product Attributes',
    allProducts: 'All Products',
    discounts: 'Discounts',
    provincesManagement: 'Provinces Management',
    citiesManagement: 'Cities Management',
    deliveryMethods: 'Delivery Methods',
    orderStatus: 'Order Status List',
    ordersList: 'Orders List',
    slideshowManagement: 'Slideshow Management',
    slideshowList: 'Slideshow List',
    developerSliderList: 'Developer Slider List',
    menuManagement: 'Menu Management',
    menuItemsList: 'Menu Items List',
    developerMenuList: 'Developer Menu List',
    pagesManagement: 'Pages Management',
    pagesList: 'Pages List',
    settingsManagement: 'Settings Management',
    settingsList: 'Settings List',
    developerSettingSections: 'Developer Setting Sections',
    blogManagement: 'Blog Management',
    blogsList: 'Blogs List',
    servicesManagement: 'Services Management',
    serviceCategories: 'Service Categories List',
    servicesList: 'Services List',
    consultationRequests: 'Consultation Requests',
    contactFormsList: 'Consultation Requests List',
    customersList: 'Customers List',
    videosList: 'Videos List',
    coursesList: 'Courses List',
    reportsByProduct: 'Reports by Product',
    reportsByOrder: 'Reports by Order',
    reportsByCustomer: 'Reports by Customer',
    reportsByDiscount: 'Reports by Discount Code',
    rolesList: 'Roles List',
    usersList: 'Users List',
    defaultCurrency: 'Default Currency',
  },
};

export const dashboard = {
  fa: {
    officeManagement: 'مدیریت دفاتر',
    inventoryManagement: 'مدیریت انبارها',
    settingParameters: 'تنظیمات پارامتر های سامانه',
    countryManagement: 'مدیریت کشورها',
    internationalTerritoryManagement: 'مدیریت مناطق بین‌المللی',
    provinceManagement: 'مدیریت استان‌ها',
    cityManagement: 'مدیریت شهرها',
    cityRegionManagement: 'مدیریت مناطق شهری',
    regionManagement: 'مدیریت مناطق',
    branchManagement: 'مدیریت شعب',
    territoryManagement: 'مدیریت حوزه‌ها',
    areaManagement: 'مدیریت حیطه‌ها',
    districtManagement: 'مدیریت نواحی',
    companyManagement: 'مدیریت شرکت',
    brandManagement: 'مدیریت برند',
    lineManagement: 'مدیریت لاین',
    classManagement: 'مدیریت رده',
    categoryManagement: 'مدیریت دسته',
    groupManagement: 'مدیریت گروه',
    guildManagement: 'مدیریت صنف‌ها',
    salePlanManagement: 'مدیریت طرح فروش',
    tariffManagement: 'مدیریت نرخنامه',
    productManagement: 'مدیریت کالا',
    userManagement: 'مدیریت کاربران',
    mapManagement: 'مدیریت نقشه',
    determineTheCoefficientOfPerformanceValidityParameter: 'ضریب پارامتر اعتبار عملکرد',
    staffCreditsList: 'لیست اعتبار عملکرد',
    customers: 'مشتریان',
    customersManagement: 'مدیریت مشتریان',
    customersManagementSetting: 'تنظیمات مدیریت مشتریان',
    notFoundTitle: 'خطای ۴۰۴',
    notFoundDescription: 'چنین صفحه‌ای پیدا نشد!',
    profile: 'حساب کاربری',
  },
  en: {
    officeManagement: 'office management',
    inventoryManagement: 'inventory management',
    settingParameters: 'setting parameters',
    countryManagement: 'country management',
    internationalTerritoryManagement: 'international territory management',
    provinceManagement: 'province management',
    cityManagement: 'city management',
    cityRegionManagement: 'city region management',
    regionManagement: 'region management',
    branchManagement: 'branch management',
    territoryManagement: 'territory management',
    areaManagement: 'area management',
    districtManagement: 'district management',
    companyManagement: 'company management',
    brandManagement: 'brand management',
    lineManagement: 'line management',
    classManagement: 'class management',
    categoryManagement: 'category management',
    groupManagement: 'group management',
    guildManagement: 'guild management',
    salePlanManagement: 'sale plan management',
    tariffManagement: 'tariff management',
    productManagement: 'product management',
    userManagement: 'user management',
    mapManagement: 'map management',
    determineTheCoefficientOfPerformanceValidityParameter:
      'Determine the coefficient of performance validity parameter',
    staffCreditsList: 'Staff Credits List',
    customers: 'customers',
    customersManagement: 'customers management',
    customersManagementSetting: 'customers management setting',
    notFoundTitle: '404 - Not Found',
    notFoundDescription: 'The Page You Are Looking For Does Not Exist!',
    profile: 'Profile',
  },
};

export const forms = {
  fa: {
    // general keys
    brandName: 'برند',
    brandList: 'لیست برندها',
    brandCreate: 'ایجاد برند',
    lineName: 'لاین',
    lineList: 'لیست لاین‌ها',
    lineCreate: 'ایجاد لاین',
    className: 'رده',
    classList: 'لیست رده‌ها',
    classCreate: 'ایجاد رده',
    categoryName: 'دسته',
    categoryList: 'لیست دسته‌ها ',
    categoryCreate: 'ایجاد دسته',
    groupName: 'گروه',
    groupList: 'لیست گروه‌ها',
    groupCreate: 'ایجاد گروه',
    inventoryCreate: 'ایجاد انبار',
    settingParameterCreate: 'ایجاد پارامترهای تنظیم سامانه',
    languageInformation: 'اطلاعات زبان خارجی',
    addLanguage: 'افزودن زبان',
    packingInformation: 'اطلاعات بسته‌بندی',
    addPackage: 'افزودن بسته‌بندی',
    planInformation: 'اطلاعات طرح',
    productInformation: 'اطلاعات کالا',
    finalSubmission: 'تایید نهایی',
    confirm: 'تایید',
    cancel: 'لغو',

    // login
    username: 'نام کاربری',
    password: 'رمز عبور',
    rememeberMe: 'مرا به خاطر بسپار!',
    forgotPass: 'رمز عبور خود را فراموش کردید؟',
    register: 'نام کاربری ندارید؟ ثبت نام کنید',
    rightsProtection: 'تمامی حقوق برای پرگاس طب محفوظ است.',
    login: 'ورود',
    loginIncorrect: 'نام کاربری یا کلمه عبور اشتباه است.',

    // country divisions
    countryName: 'کشور',
    countryList: 'لیست کشورها',
    countryCreate: 'ایجاد کشور',
    provinceName: 'استان',
    provinceList: 'لیست استان‌ها',
    provinceCreate: 'ایجاد استان',
    cityName: 'شهر',
    cityList: 'لیست شهرها',
    cityCreate: 'ایجاد شهر',
    cityRegionName: 'منطقه شهری',
    cityRegionList: 'لیست مناطق شهری',
    cityRegionCreate: 'ایجاد منطقه شهری',
    cityCode: 'کد شهر',
    provinceCode: 'کد استان',

    // sale divisions
    internationalTerritoryName: 'سطح بین المللی',
    regionName: 'منطقه',
    branchName: 'شعبه',
    territoryName: 'حوزه',
    areaName: 'حیطه',
    districtName: 'ناحیه',
    internationalTerritoryList: 'لیست سطوح بین المللی',
    internationalTerritoryCreate: 'ایجاد سطح بین المللی',
    regionList: 'لیست مناطق ',
    regionCreate: 'ایجاد منطقه',
    branchList: 'لیست شعب',
    branchCreate: 'ایجاد شعبه',
    territoryList: 'لیست حوزه‌ها',
    territoryCreate: 'ایجاد حوزه',
    areaList: 'لیست حیطه‌ها',
    areaCreate: 'ایجاد حیطه',
    districtList: 'لیست نواحی',
    districtCreate: 'ایجاد ناحیه',

    // offices
    officeName: 'نام دفتر',
    officeType: 'نوع دفتر',
    officeManager: 'مدیر دفتر',
    officeList: 'لیست دفاتر',
    officeCreate: 'ایجاد دفتر',

    // inventories
    inventoryName: 'نام انبار',
    inventoryType: 'نوع انبار',
    inventoryKeeper: 'مسئول انبار',
    inventoryList: 'لیست انبارها',
    inventoryBrands: 'برند کالاها',
    inventoryOffice: 'دفتر مرتبط',
    inventoryPostalCode: 'کد پستی',
    inventoryNamePlaceholder: 'نام انبار را وارد کنید',
    inventoryAddressPlaceholder: 'آدرس انبار را وارد نمایید ...',
    inventoryDescriptionPlaceholder: 'هرگونه توضیحات درباره انبار را اینجا بنویسید ...',

    // settingParameters
    parameterName: 'نام پارامتر',
    parameterGroup: 'گروه پارامتر',
    parameterValue: 'مقدار',
    parameterType: 'نوع',

    // product hierarchy
    companyName: 'شرکت',
    companyEnglishName: 'عنوان انگلیسی شرکت',
    companyList: 'لیست شرکت‌ها',
    companyCreate: 'ایجاد شرکت',
    lineEnglishName: 'عنوان انگلیسی لاین',
    classEnglishName: 'عنوان انگلیسی رده',
    categoryEnglishName: 'عنوان انگلیسی دسته',
    groupEnglishName: 'عنوان انگلیسی گروه',

    // guild
    guildName: 'نام صنف',
    guildList: 'لیست صنف‌ها',
    guildCreate: 'ایجاد صنف',
    guildEnglishName: 'نام انگلیسی صنف',

    // sale plan
    salePlan: 'طرح فروش',
    salePlanName: 'نام طرح فروش',
    salePlanList: 'لیست طرح‌های فروش',
    salePlanCreate: 'ایجاد طرح فروش',
    salePlanEnglishName: 'نام انگلیسی طرح',
    salePlanSpecifications: 'اطلاعات طرح فروش',
    salePlanDescriptionPlaceholder: 'هرگونه توضیحات درباره طرح فروش را اینجا بنویسید ...',
    productBrand: 'برند کالا',
    productLine: 'لاین کالا',
    productClass: 'رده کالا',
    productCategory: 'دسته کالا',
    productGroup: 'گروه کالا',
    purchaseCount: 'تعداد خرید',
    offerCount: 'تعداد آفر',
    cooperationDiscountPercent: 'تخفیف همکاری',
    marketingAndResearchDiscountPercent: 'پژوهش و بازاریابی',
    giftCardDiscountPercent: 'کارت هدیه',
    basket: 'سبد',
    addBasket: 'اضافه کردن سبد',
    createBasket: 'ساخت سبد',
    basketName: 'نام سبد',
    sellingBasket: 'سبد فروش',
    enterBasketName: 'نام سبد را وارد کنید',
    products: 'کالاها',
    addProduct: 'اضافه کردن کالا',
    addOfferStep: 'افزودن پله جدید',
    offer: 'آفر',
    offers: 'آفرها',

    // tariff
    tariffName: 'نام نرخنامه',
    tariffList: 'لیست نرخنامه‌ها',
    tariffCreate: 'ایجاد نرخنامه',
    tariffSpecification: 'مشخصات نرخنامه',
    tariffPlaceholder: 'هرگونه توضیحات درباره نرخنامه را اینجا بنویسید ...',

    // product
    productName: 'نام کالا',
    productCreate: 'ایجاد کالا',
    productList: 'لیست کالاها',
    productEnglishName: 'نام انگلیسی کالا',
    productBarcode: 'بارکد کالا',
    productDescriptionPlaceholder: 'هرگونه توضیحات درباره کالا را اینجا بنویسید ...',
    productSpecifications: 'مشخصات کالا',
    productImage: 'عکس کالا',
    smallPackage: 'کارتن کوچک',
    largePackage: 'کارتن بزرگ',
    pallet: 'پالت',
    language: 'زبان',
    translate: 'ترجمه',
    translation: 'ترجمه',
    deliveryDuration: 'زمان تحویل',
    width: 'عرض',
    height: 'ارتفاع',
    length: 'طول',
    weight: 'وزن',
    volume: 'حجم',
    barcode: 'بارکد',
    productCount: 'تعداد کالا در بسته',

    // user
    userName: 'نام کاربر',
    userCreate: 'ایجاد کاربر',
    userList: 'لیست کاربران',
    firstRelativeName: 'نام بستگان اول',
    firstRelativeNumber: 'شماره بستگان اول',
    secondRelativeName: 'نام بستگان دوم',
    secondRelativeNumber: 'شماره بستگان دوم',
    firstReferenceName: 'نام معرف اول',
    firstReferenceNumber: 'شماره معرف اول',
    secondReferenceName: 'نام معرف دوم',
    secondReferenceNumber: 'شماره معرف دوم',

    // customers
    customerName: 'نام مشتری',
    customerCreate: 'ایجاد مشتری',
    customerList: 'لیست مشتریان',
    customerReviewer: 'بررسی کننده اطلاعات',
    customerState: 'وضعیت بررسی',
    REVIEWING: 'در حال بررسی',
    APPROVED: 'تایید شده',
    REJECTED: 'رد شده',
    customerInfo: 'اطلاعات مشتری',
    economicCode: 'کد اقتصادی',
    businessLicenceCode: 'شماره پروانه',
    reputation: 'شهرت و اعتبار',
    urbanTexture: 'بافت اطراف مشتری',
    residential: 'مسکونی',
    official: 'اداری',
    recreational: 'تفریحی',
    mixed: 'مختلط',
    landOwner: 'مالک',
    renter: 'مستاجر',
    ownershipType: 'نوع مالکیت',
    stampImage: 'تصویر مهر مشتری',
    signatureImage: 'تصویر امضای مشتری',
    visitCardImage: 'تصویر کارت ویزیت',
    facadeImage: 'تصویر سر در',
    organizationalUnit: 'واحد سازمانی',
    organizationalLevel: 'سطح سازمانی',
    identificationCode: 'کد شناسایی',

    // customers settings
    customersReviewersList: 'لیست تاییدکنندگان مشتریان',
    addNewEmployee: 'اضافه کردن پرسنل جدید',
    contactInfo: 'اطلاعات تماس',
    ContactType: 'نوع تماس',
    addContactInfo: 'افزودن اطلاعات تماس',
    addBankAccount: 'افزودن اطلاعات حساب',

    // customer credit management
    determineTheCoefficientOfPerformanceValidityParameterList: 'لیست پارامترها',
    determineTheCoefficientOfPerformanceValidityParameterName: 'نام',
    determineTheCoefficientOfPerformanceValidityParameterDeterminingTheCoefficient: 'تعیین ضریب',

    // new
    primaryCreditParameterGroup: 'گروه اعتبار اولیه',
    PrimaryCreditParameter: 'پارامتر اعتبار اولیه',
    creditState: 'وضعیت بررسی',

    // role
    roleName: 'عنوان سمت',

    // Distribution management
    inventoryResponder: 'نام مسئول انبار',
    confirmationStatus: 'وضعیت تایید',
    date: 'تاریخ',
    distributorId: 'شناسه موزع',
    miscellaneousDistributorName: 'نام موزع متفرقه',
    deviceType: 'نوع وسیله',
    deviceNumber: 'شماره وسیله',
    deviceCapacity: 'ظرفیت وسیله',
    instituteName: 'نام موسسه',
    phoneNumberOfInstitute: 'شماره تماس موسسه',
    instituteAddress: 'آدرس موسسه',

    ordersList: 'لیست سفارشات',
  },
  en: {
    // general keys
    brandName: 'brand name',
    brandList: 'brand list',
    brandCreate: 'brand create',
    lineName: 'line name',
    lineList: 'line list',
    lineCreate: 'line create',
    className: 'class name',
    classList: 'class list',
    classCreate: 'class create',
    categoryName: 'category name',
    categoryList: 'category list',
    categoryCreate: 'category create',
    groupName: 'group name',
    groupList: 'group list',
    groupCreate: 'group create',
    inventoryCreate: 'inventory create',
    languageInformation: 'language information',
    addLanguage: 'add language',
    packingInformation: 'packing information',
    addPackage: 'add package',
    planInformation: 'plan information',
    productInformation: 'product information',
    finalSubmission: 'final submission',
    confirm: 'confirm',
    cancel: 'cancel',

    // login
    username: 'username',
    password: 'password',
    rememeberMe: 'Remember me!',
    forgotPass: 'forgot your password?',
    register: 'No username? Sign up!',
    rightsProtection: 'All rights of this website belong to Pergas Teb company.',
    login: 'login',
    loginIncorrect: 'The username or password is not correct.',

    // country divisions
    countryName: 'country name',
    countryList: 'country list',
    countryCreate: 'create country',
    provinceName: 'province name',
    provinceList: 'province list',
    provinceCreate: 'create province',
    cityName: 'city name',
    cityList: 'city list',
    cityCreate: 'create city',
    cityRegionName: 'city region name',
    cityRegionList: 'city region list',
    cityRegionCreate: 'create city region',
    cityCode: 'city code',
    provinceCode: 'province code',

    // sale divisions
    internationalTerritoryName: 'international territory name',
    regionName: 'region name',
    branchName: 'branch name',
    territoryName: 'territory name',
    areaName: 'area name',
    districtName: 'district name',
    internationalTerritoryList: 'international territory list',
    internationalTerritoryCreate: 'create international territory',
    regionList: 'region list',
    regionCreate: 'create region',
    branchList: 'branch list',
    branchCreate: 'create branch',
    territoryList: 'territory list',
    territoryCreate: 'create territory',
    areaList: 'area list',
    areaCreate: 'create area ',
    districtList: 'district list',
    districtCreate: 'create district ',

    // offices
    officeName: 'office name',
    officeType: 'office type',
    officeManager: 'office manager',
    officeList: 'office list',
    officeCreate: 'create office',

    // inventories
    inventoryName: 'inventory name',
    inventoryType: 'inventory type',
    inventoryKeeper: 'inventory keeper',
    inventoryList: 'inventory list',
    inventoryBrands: 'brands',
    inventoryOffice: 'related office',
    inventoryPostalCode: 'postal code',
    inventoryNamePlaceholder: 'enter name for inventory',
    inventoryAddressPlaceholder: 'enter address for inventory',
    inventoryDescriptionPlaceholder: 'write every needed description for inventory here ...',

    // product hierarchy
    companyName: 'company name',
    companyEnglishName: 'company english name',
    companyList: 'company list',
    companyCreate: 'company create',
    brandEnglishName: 'brand english name',
    lineEnglishName: 'line english name',
    classEnglishName: 'class english name',
    categoryEnglishName: 'category english name',
    groupEnglishName: 'group english name',

    // guild
    guildName: 'guild name',
    guildList: 'guild list',
    guildCreate: 'create guild',
    guildEnglishName: 'guild English name',

    // sale plan
    salePlan: 'sale plan',
    salePlanName: 'sale plan name',
    salePlanList: 'sale plan list',
    salePlanCreate: 'create sale plan',
    salePlanEnglishName: 'plan English name',
    salePlanSpecifications: 'sale plan specifications',
    productBrand: 'product brand',
    productLine: 'product line',
    productClass: 'product class',
    productCategory: 'product category',
    productGroup: 'product group',
    purchaseCount: 'purchase quantity',
    offerCount: 'offer quantity',
    cooperationDiscountPercent: 'colleague discount',
    marketingAndResearchDiscountPercent: 'research and marketing',
    giftCardDiscountPercent: 'gift card',
    basket: 'basket',
    addBasket: 'add basket',
    sellingBasket: 'selling basket',
    enterBasketName: 'enter basket name',
    createBasket: 'create basket',
    basketName: 'basket name',
    products: 'products',
    addProduct: 'add product',
    addOfferStep: 'add offer step',
    offer: 'offer',
    customerInfo: 'customer information',

    // tariff
    tariffSpecification: 'tariff specification',
    tariffList: 'tariff list',
    tariffCreate: 'tariff create',
    tariffPlaceholder: 'write every needed description for tariff here ...',

    // product
    productName: 'product name',
    productCreate: 'product create',
    productList: 'product list',
    productEnglishName: 'product English name',
    productBarcode: ' product barcode',
    smallPackage: 'product small package',
    largePackage: 'product large package',
    pallet: 'product pallet',
    language: 'language',
    translate: 'translation',

    // user
    userName: 'user name',
    userCreate: 'create user',
    userList: 'user list',
    firstRelativeName: 'first relative name',
    firstRelativeNumber: 'first relative number',
    secondRelativeName: 'second relative name',
    secondRelativeNumber: 'second relative number',
    firstReferenceName: 'first reference name',
    firstReferenceNumber: 'first reference number',
    secondReferenceName: 'second reference name',
    secondReferenceNumber: 'second reference number',

    // customers
    customerName: 'customer name',
    customerCreate: 'create customer',
    customerList: 'customer list',
    customerReviewer: 'information reviewer',
    customerState: 'customer state',
    REVIEWING: 'reviewing',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    economicCode: 'economic code',
    businessLicenceCode: 'business licence code',
    reputation: 'reputation',
    urbanTexture: 'urban textures',
    residential: 'residential',
    official: 'official',
    recreational: 'recreational',
    mixed: 'mixed',
    landOwner: 'landOwner',
    renter: 'renter',
    ownershipType: 'ownership type',
    stampImage: 'stamp image',
    signatureImage: 'signature image',
    visitCardImage: 'visit card image',
    facadeImage: 'facade image',
    organizationalUnit: 'organization unit',
    organizationalLevel: 'organization level',
    identificationCode: 'identification code',

    // customers settings
    customersReviewersList: 'customer reviewers list',
    contactInfo: 'contact info',
    ContactType: 'customer employee contact type',
    addContactInfo: 'add contact info',
    addBankAccount: 'add bank account',

    // customer credit management
    determineTheCoefficientOfPerformanceValidityParameterList: 'Parameter List',
    determineTheCoefficientOfPerformanceValidityParameterName: 'Name',
    determineTheCoefficientOfPerformanceValidityParameterDeterminingTheCoefficient: 'Determining the coefficient',

    // role
    roleName: 'role name',

    ordersList: 'Orders List',
  },
};

export const language = {
  fa: {
    arabic: 'عربی',
    dutch: 'آلمانی',
    english: 'انگلیسی',
    french: 'فرانسوی',
    turkish: 'ترکی',
  },
  en: {
    arabic: 'arabic',
    dutch: 'dutch',
    english: 'english',
    french: 'french',
    turkish: 'turkish',
  },
};

export const permissionsData = {
  fa: {
    'auth.login': 'ورود با پسوورد',
    'auth.login-with-otp': 'ورود با کد یکبار مصرف',
    'auth.verify-otp-code': 'تایید کد یکبار مصرف',
    'auth.refresh': 'تازه سازی توکن',
    'auth.register': 'ثبت نام کاربر جدید',
    'auth.otp': 'ورود با کد یکبار مصرف',
    'auth.verify-code': 'تایید کد یکبار مصرف',
    'sliders.index': 'لیست اسلایدشو',
    'sliders.store': '(فقط دسترسی برنامه نویس) ایجاد اسلایدشو',
    'sliders.show': 'دسترسی به یک اسلایدشو',
    'sliders.update': 'آپدیت اسلایدشو',
    'sliders.destroy': 'پاک کردن اسلایدشو',
    'galleries.index': 'لیست گالری اسلایدشو',
    'galleries.store': 'ایجاد گالری اسلایدشو (فقط دسترسی برنامه نویس)',
    'galleries.show': 'دسترسی به گالری اسلایدشو (فقط دسترسی برنامه نویس)',
    'galleries.update': 'آپدیت گالری اسلایدشو (فقط دسترسی برنامه نویس)',
    'galleries.destroy': 'پاک کردن گالری اسلایدشو (فقط دسترسی برنامه نویس)',
    'menu-items.index': 'لیست آیتم منو',
    'menu-items.store': 'ایجاد آیتم منو',
    'menu-items.show': 'دسترسی به یک آیتم منو',
    'menu-items.update': 'آپدیت آیتم منو',
    'menu-items.destroy': 'پاک کردن آیتم منو',
    'menus.index': 'لیست منوها',
    'menus.store': 'ایجاد منو (فقط دسترسی برنامه نویس)',
    'menus.show': 'دسترسی به یک منو (فقط دسترسی برنامه نویس)',
    'menus.update': 'آپدیت منو (فقط دسترسی برنامه نویس)',
    'menus.destroy': 'پاک کردن منو (فقط دسترسی برنامه نویس)',
    'pages.index': 'لیست صفحات ',
    'pages.store': 'ایجاد صفحه ',
    'pages.show': 'دسترسی به یک صفحه ',
    'pages.update': 'آپدیت صفحه ',
    'pages.destroy': 'پاک کردن صفحه ',
    'services.index': 'لیست سرویس ها ',
    'services.store': 'ایجاد سرویس ',
    'services.show': 'دسترسی به یک سرویس ',
    'services.update': 'آپدیت سرویس ',
    'services.destroy': 'پاک کردن سرویس ',
    'service-categories.index': 'لیست کتگوری سرویس ها ',
    'service-categories.store': 'ایجاد کتگوری سرویس ',
    'service-categories.show': 'دسترسی به یک کتگوری سرویس ',
    'service-categories.update': 'آپدیت کتگوری سرویس ',
    'service-categories.destroy': 'پاک کردن کتگوری سرویس ',
    'colleagues.index': 'لیست همکاران',
    'colleagues.store': 'ایجاد همکار ',
    'colleagues.show': 'دسترسی به یک همکار ',
    'colleagues.update': 'آپدیت همکار ',
    'colleagues.destroy': 'پاک کردن همکار ',
    'admin.delivery_method.index': 'لیست روش های ارسال',
    'admin.delivery_method.show': 'دسترسی به یک روش ارسال ',
    'admin.delivery_method.store': 'ایجاد روش ارسال',
    'admin.delivery_method.update': 'آپدیت روش ارسال',
    'admin.delivery_method.destroy': 'پاک کردن روش ارسال',
    'customers.index': 'لیست مشتریان',
    'customers.store': 'ایجاد مشتری ',
    'customers.show': 'دسترسی به یک مشتری ',
    'customers.update': 'آپدیت مشتری ',
    'customers.destroy': 'پاک کردن مشتری ',
    'salon-calendars.index': 'لیست تقویم ها',
    'salon-calendars.store': 'ایجاد تقویم ',
    'salon-calendars.show': 'دسترسی به یک تقویم ',
    'salon-calendars.update': 'آپدیت تقویم ',
    'salon-calendars.destroy': 'پاک کردن تقویم ',
    'salon-calendars.updateTimes': 'آپدیت زمان تقویم ',
    'setting-sections.index': 'لیست قسمت های تنظیمات',
    'setting-sections.store': 'ایجاد قسمت های تنظیمات (فقط دسترسی برنامه نویس)',
    'setting-sections.show': 'دسترسی به یک قسمت تنظیمات (فقط دسترسی برنامه نویس)',
    'setting-sections.update': 'آپدیت قسمت های تنظیمات (فقط دسترسی برنامه نویس)',
    'setting-sections.destroy': 'پاک کردن قسمت های تنظیمات (فقط دسترسی برنامه نویس)',
    'settings.index': 'لیست تنظیمات',
    'settings.store': '(فقط دسترسی برنامه نویس) ایجاد تنظیمات',
    'settings.show': 'دسترسی به یک تنظیمات ',
    'settings.update': 'آپدیت تنظیمات ',
    'settings.update-value': 'آپدیت مقدار تنظیمات ',
    'settings.destroy': 'پاک کردن تنظیمات ',
    'certificates.index': 'لیست قالب های مدرک',
    'certificates.store': 'ایجاد قالب مدرک',
    'certificates.show': 'دسترسی به یک قالب مدرک  ',
    'certificates.update': 'آپدیت قالب مدرک ',
    'certificates.destroy': 'پاک کردن قالب مدرک ',
    'certificates.customers': 'دانشجوهای قالب مدرک ',
    'certificates.attachCustomers': 'اضافه کردن دانشجو قالب مدرک',
    'certificates.detachCustomers': 'حذف دانشجواز قالب مدرک',
    'certificate-files.index': 'لیست مدرک ها',
    'certificate-files.store': 'ایجاد مدرک',
    'certificate-files.show': 'دسترسی به یک مدرک',
    'certificate-files.update': 'آپدیت مدرک ',
    'certificate-files.destroy': 'پاک کردن مدرک ',
    'courses.index': 'لیست دوره ها',
    'courses.store': 'ایجاد دوره',
    'courses.create_post': 'ایجاد دوره با ویدیو',
    'courses.show': 'دسترسی به یک دوره  ',
    'courses.update': 'آپدیت دوره ',
    'courses.destroy': 'پاک کردن دوره ',
    'courses.syncRelatedCourses': 'ساخت دوره های مرتبط',
    'courses.attachRelatedCourses': '(دسترسی برنامه نویس) ایجاد دوره های مرتبط',
    'courses.detachRelatedCourses': '(دسترسی برنامه نویس) حذف دوره های مرتبط',
    'videos.index': 'لیست ویدیو ها',
    'videos.store': 'ایجاد ویدیو',
    'videos.show': 'دسترسی به یک ویدیو  ',
    'videos.update': 'آپدیت ویدیو ',
    'videos.destroy': 'پاک کردن ویدیو ',
    'roles.index': 'لیست نقش ها',
    'roles.store': 'ایجاد نقش',
    'roles.show': 'دسترسی به یک نقش  ',
    'roles.update': 'آپدیت نقش ',
    'roles.destroy': 'پاک کردن نقش ',
    'permissions.index': 'لیست دسترسی ها',
    'permissions.store': 'ایجاد دسترسی',
    'permissions.show': 'دسترسی به یک دسترسی  ',
    'permissions.update': 'آپدیت دسترسی ',
    'permissions.destroy': 'پاک کردن دسترسی ',
    'permissions.assign-permissions-to-admin': 'تخصیص تمام دوره ها به ادمین',
    'booking.index': 'لیست وقت دهی ها',
    'booking.store': 'ایجاد وقت دهی',
    'booking.show': 'دسترسی به یک وقت دهی  ',
    'booking.update': 'آپدیت وقت دهی ',
    'booking.destroy': 'پاک کردن وقت دهی ',
    'contact-form.index': 'لیست درخواست های مشاوره',
    'contact-form.store': 'ایجاد درخواست مشاوره',
    'contact-form.show': 'دسترسی یک درخواست مشاوره  ',
    'contact-form.update': 'آپدیت درخواست مشاوره',
    'contact-form.destroy': 'پاک کردن درخواست مشاوره ',
    'users.index': 'لیست کاربران',
    'users.store': 'ایجاد کاربر',
    'users.show': 'دسترسی به یک کاربر  ',
    'users.update': 'آپدیت کاربر',
    'users.destroy': 'پاک کردن کاربر ',
    'users.info': 'اطلاعات کاربر',
    'courses.customerList': 'لیست دانشجوهای دوره',
    'courses.syncCustomersToCourse': 'سینک دانشجو و دوره (دسترسی برنامه نویس)',
    'courses.attachCustomersToCourse': 'اضافه کردن دانشجو به دوره',
    'courses.detachCustomersToCourse': 'حذف دانشجو از دوره',
    'videos.syncRelatedVideos': 'ساخت ویدیوهای مرتبط',
    'videos.attachRelatedVideos': '(دسترسی برنامه نویس) ایجاد ویدیوهای مرتبط',
    'videos.detachRelatedVideos': '(دسترسی برنامه نویس) حذف ویدیوهای مرتبط',
    'roles.get': 'دسترسی به یک نقش',
    'roles.permissions': 'دسترسی به دسترسی های یک نقش',
    'roles.assign.permission': '(دسترسی برنامه نویس) اضافه کردن دسترسی به نقش',
    'roles.revoke.permission': '(دسترسی برنامه نویس)  حذف کردن دسترسی از نقش',
    'roles.sync.permission': 'تخصیص دسترسی به نقش',
    'user.assign.role': 'تخصیص نقش به کاربر',
    'user.revoke.role': 'گرفتن نثش از کاربر (دسترسی برنامه نویس)',
    'permissions.generated': 'ایجاد تمام دسترسی ها (دسترسی برنامه نویس)',
    'blogs.index': 'لیست وبلاگ ها',
    'blogs.store': 'ایجاد وبلاگ',
    'blogs.show': 'دسترسی به یک وبلاگ  ',
    'blogs.update': 'آپدیت وبلاگ ',
    'blogs.destroy': 'پاک کردن وبلاگ ',
    'discounts.index': 'لیست تخفیف ها',
    'discounts.store': 'ایجاد تخفیف',
    'discounts.show': 'دسترسی به یک تخفیف  ',
    'discounts.update': 'آپدیت تخفیف ',
    'discounts.destroy': 'پاک کردن تخفیف ',
    'courses.comments.get': ' دسترسی به کامنت های دوره های آموزشی',
    'comments.delete': 'حذف یک کامنت',
    'update.comment.status': 'تایید / تغییر وضعیت کامنت',
    'courses.faqs.get': 'دسترسی به پرسش های متداول دوره های آموزشی',
    'courses.faqs.add': 'افزودن به سوالات متداول دوره های آموزشی',
    'courses.faqs.update': 'آپدیت سوالات متداول دوره های آموزشی',
    'courses.faqs.delete': 'پاک کردن سوالات متداول دوره های آموزشی',
    'faqs.get': 'دسترسی به هر پرسش متداول دوره های آموزشی',
    'attribute-category.index': 'لیست دسته بندی ویژگی ها',
    'attribute-category.store': 'ایجاد دسته بندی ویژگی ها',
    'attribute-category.show': 'دسترسی به یک دسته بندی ویژگی ها',
    'attribute-category.update': 'آپدیت دسته بندی ویژگی ها',
    'attribute-category.destroy': 'پاک کردن دسته بندی ویژگی ها',
    'product-category.index': 'لیست دسته بندی محصولات',
    'product-category.store': 'ایجاد دسته بندی محصولات',
    'product-category.show': 'دسترسی به یک دسته بندی محصولات',
    'product-category.update': 'آپدیت دسته بندی محصولات',
    'product-category.destroy': 'پاک کردن دسته بندی محصولات',
    'unit.index': 'لیست واحد ها',
    'unit.store': 'ایجاد واحد',
    'unit.show': 'دسترسی به یک واحد',
    'unit.update': 'آپدیت واحد',
    'unit.destroy': 'پاک کردن واحد',
    'brand.index': 'لیست برند ها',
    'brand.store': 'ایجاد برند',
    'brand.show': 'دسترسی به یک برند',
    'brand.update': 'آپدیت برند',
    'brand.destroy': 'پاک کردن برند',
    'attribute.index': 'لیست ویژگی ها',
    'attribute.store': 'ایجاد ویژگی',
    'attribute.show': 'دسترسی به یک ویژگی',
    'attribute.update': 'آپدیت ویژگی',
    'attribute.destroy': 'پاک کردن ویژگی',
    'product.index': 'لیست محصولات',
    'product.store': 'ایجاد محصولات',
    'product.show': 'دسترسی به یک محصول',
    'product.update': 'آپدیت محصولات',
    'product.destroy': 'پاک کردن محصولات',
    'add-product-attribute-options': 'اضافه کردن ویژگی به محصول',
    'show-product-attribute-options': 'دسترسی به ویژگی های یک محصول',
    FirstLevelCategories: 'دسته های والد',
    CategoriesAttributeOptions: 'آپشن های دسته بندی ویژگی ها',
    ChildrenOfAttributeCategory: 'دسترسی به زیرمجموعه های دسته بندی های ویژگی',
    ChildrenOfProductCategory: 'دسترسی به زیرمجموعه های دسته بندی محصولات',
    'product-inventory.index': 'موجودی محصول',
    'product-inventory.store': 'ذخیره موجودی محصول',
    'product-inventory.update': 'آپدیت موجودی محصول',
    'remove-product-attribute-options': 'حذف آپشن های تخصیص یافته به ویژگی محصول',
    'admin.get-all-provinces.getAllProvinces': 'لیست استان ها',
    'admin.activate-province.activateProvince': 'فعال کردن استان',
    'admin.deactivate-province.deactivateProvince': 'غیر فعال کردن استان',
    'admin.get-all-cities.getAllCities': 'لیست شهر ها',
    'admin.activate-city.activateCity': 'فعال کردن شهر',
    'admin.deactivate-city.deactivateCity': 'غیر فعال کردن شهر',
    'admin.variants.store': 'تخصیص موجودی به محصول',
    'admin.variants.index': 'لیست موجودی ویژگی محصولات',
    'admin.variants.show': 'مشاهده موجودی ویژگی محصولات',
    'admin.variants.destroy': 'پاک کردن موجودی تخصیص یافته به ویژگی',
    'admin.variants.updateQuantity': 'آپدیت موجودی ویژگی محصولات',
    'admin.product.search': 'جست و جوی محصول',
    'admin.product.delete-attachment': 'پاک کردن عکس محصولات',
    'admin.orders.search': 'جست و جوی سفارشات',
    'admin.product.import-xml': 'ایمپورت محصولات',
    'admin.product.import-attachment': 'ایمپورت عکس محصولات',
    'admin.set.default.currency': 'تنظیم کردن واحد پولی',
    'admin.get.default.currency': 'دسترسی به واحد پولی تنظیم شده',
    'admin.reports.index': 'دسترسی به گزارشات صفحه داشبورد ادمین',
    'admin.orders.index': 'دسترسی به لیست سفارش ها',
    'admin.orders.update': 'آپدیت سفارش',
    'admin.orders.destroy': 'پاک کردن سفارش',
    'admin.user.orders': 'دسترسی به سفارش های کاربر',
    'admin.order-status.index': 'دسترسی به لیست وضعیت های سفارش',
    'admin.orders.store': 'ذخیره کردن سفارش',
    'admin.orders.show': 'نمایش سفارش',
    'admin.brand.search': 'جستجو برند',
  },
  en: {
    'auth.login': 'login',
    'auth.login-with-otp': 'login with otp',
    'auth.verify-otp-code': 'verify otp code',
    'auth.refresh': 'refresh token',
    'auth.register': 'register new user',
    'sliders.index': 'slideshow list',
    'sliders.store': 'create slideshow',
    'sliders.show': 'get one slideshow',
    'sliders.update': 'update slidshow',
    'sliders.destroy': 'delete slideshow',
    'galleries.index': 'slideshow gallery list (programmers only)',
    'galleries.store': 'create slideshow gallery (programmers only)',
    'galleries.show': 'get one slideshow gallery (programmers only)',
    'galleries.update': 'update slideshow gallery (programmers only)',
    'galleries.destroy': 'delete slideshow gallery (programmers only)',
  },
};
