import lodash from 'lodash';
import persian from 'persian-rex';
import React, { Dispatch, SetStateAction } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';
import FileSaver from 'file-saver';
import _PropTypes from 'prop-types';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';
import { AnyObject } from '../types';
import { SET_VIEW_READ_ONLY } from '../components/context/reducer/actionTypes';
import ConvertErrorsToHuman from '../data/errors/backErrors';
import { BSwalConfirmationDelete, BSwalDeleted, BSwalShowError } from './BSwal';
import { REQUIRED_STRING } from '../constants/HookForm';
import BErrorFormHelperText from '../components/BErrorFormHelperText';
import InventoryIcon from '../components/icons/inventoryIcon';
import FactCheckIcon from '../components/icons/factCheckIcon';

type SetValue = UseFormReturn['setValue'];
type Register = UseFormReturn['register'];

const cookies = new Cookies();

export const ThousandsSeprator = (x = 0): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const FindAndCheckValue = (
  object: AnyObject,
  object_path: string,
  find_path: string,
  value: string,
): string | false => {
  if (lodash.get(object, object_path, []).length > 0) {
    const item = lodash.get(object, object_path).find((c: AnyObject) => lodash.get(c, find_path) === value);
    if (item) {
      return item.message;
    }
  }
  return false;
};

export const ReactFormHookServerError = (
  error: AnyObject | undefined,
  messages = 'messages',
  fieldName = 'fieldName',
  code: string,
): JSX.Element | false => {
  if (code && error && lodash.get(error, messages, []).find((c: AnyObject) => c[fieldName] === code))
    return <BErrorFormHelperText text={error[messages].find((c: AnyObject) => c[fieldName] === code).message} />;
  return false;
};

export const GetQueryStringValue = (key: string): string =>
  decodeURIComponent(
    window.location.search.replace(
      new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[.+*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'),
      '$1',
    ),
  );

export const getUniqueCode = (): string => {
  // eslint-disable-next-line no-undef
  const typedArray = new Uint8Array(5);
  const randomValues = window.crypto.getRandomValues(typedArray);
  return randomValues.join('');
};

export const convertToBase64 = (response: AnyObject): Promise<string | null | ArrayBuffer> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });

export const ConvertConflictErrorsOnSwal = (error: AnyObject): string => ConvertErrorsToHuman(error);

// Regex
export const OnlyAcceptEnglishInteger = (character: string): boolean => /^\d+$/.test(character);

export const OnlyAcceptPersianInteger = (character: string): boolean => persian.number.test(character);

export const OnlyAcceptEnglishCharacters = (character: string): boolean => /^[a-zA-Z]+$/.test(character);

export const OnlyAcceptPersianCharacters = (character: string): boolean =>
  persian.letter.test(character) || OnlyAcceptEnglishInteger(character);

export const ValidKeysInAll = (character: AnyObject): boolean => {
  const validKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', ' ', 'Tab', 'Enter'];
  const validCombinedCharacters = ['a', 'c', 'v'];
  if (validCombinedCharacters.includes(character.key.toLowerCase()) && (character.metaKey || character.ctrlKey))
    return true;
  return validKey.includes(character.key);
};

export const EmailSpecialKeys = (character: string): boolean => {
  const validKey = ['@', '.', '_'];
  return validKey.includes(character);
};

export const Dot = (event: AnyObject): boolean => event.key === '.' && !event?.target?.value.toString().match(/\./g);

// Handler
export const onKeyDownHandlerOnlyAcceptIntegerEnglish = (event: AnyObject): boolean | undefined => {
  if (event.altKey) {
    event.preventDefault();
    return;
  }
  if (OnlyAcceptEnglishInteger(event.key) || ValidKeysInAll(event) || Dot(event)) return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyDontAcceptSpace = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (event.key === ' ') {
    event.preventDefault();
  } else return true;
};

export const onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpace = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if ((OnlyAcceptEnglishInteger(event.key) || ValidKeysInAll(event) || Dot(event)) && event.key !== ' ') return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpaceDontAcceptDot = (
  event: AnyObject,
): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if ((OnlyAcceptEnglishInteger(event.key) || ValidKeysInAll(event)) && event.key !== ' ') return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpaceAcceptDash = (
  event: AnyObject,
): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (/^[-/]/.test(event.key)) {
    return true;
  }
  if ((OnlyAcceptEnglishInteger(event.key) || ValidKeysInAll(event) || Dot(event)) && event.key !== ' ') return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyDontAcceptDashAndPlus = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (/^[-+]/.test(event.key)) {
    event.preventDefault();
  } else return true;
};

export const onKeyDownHandlerOnlyAcceptIntegerPersian = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (OnlyAcceptPersianInteger(event.key) || ValidKeysInAll(event)) return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptEnglishCharacter = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (OnlyAcceptEnglishCharacters(event.key) || ValidKeysInAll(event)) return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (OnlyAcceptEnglishCharacters(event.key) || ValidKeysInAll(event) || OnlyAcceptEnglishInteger(event.key))
    return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptValidCharactersInAnyLanguage = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (event.key.toString().match(/([^\\{}[\];:/.,'"@!#$%^&(*)_+=-])/) || ValidKeysInAll(event)) return true;
  event.preventDefault();
};
export const onKeyDownHandlerOnlyAcceptEmailCharacters = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (
    OnlyAcceptEnglishCharacters(event.key) ||
    ValidKeysInAll(event) ||
    EmailSpecialKeys(event.key) ||
    OnlyAcceptEnglishInteger(event.key)
  )
    return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptWebSiteCharacters = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (
    OnlyAcceptEnglishCharacters(event.key) ||
    ValidKeysInAll(event) ||
    EmailSpecialKeys(event.key) ||
    OnlyAcceptEnglishInteger(event.key) ||
    ['/'].includes(event.key)
  )
    return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptPersianCharacter = (event: AnyObject): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (OnlyAcceptPersianCharacters(event.key) || ValidKeysInAll(event)) return true;
  event.preventDefault();
};

export const onKeyDownHandlerOnlyAcceptEnglishCharacterAndPersianCharachter = (
  event: AnyObject,
): boolean | undefined => {
  if (event.altKey) return event.preventDefault();
  if (OnlyAcceptEnglishCharacters(event.key) || OnlyAcceptPersianCharacters(event.key) || ValidKeysInAll(event))
    return true;
  event.preventDefault();
};

export const isValidNationalCode = (value: string): boolean => {
  if (!value) return false;
  if (value.length !== 10 || /(\d)(\1){9}/.test(value)) return false;

  let sum = 0;
  const chars = value.split('');

  for (let i = 0; i < 9; i += 1) sum += +chars[i] * (10 - i);

  let lastDigit = null;
  const remainder = sum % 11;

  lastDigit = remainder < 2 ? remainder : 11 - remainder;

  return +chars[9] === lastDigit;
};

export const isValidLegalNationalCode = (code: string): boolean => {
  if (!code) return true;
  const L = code.length;

  if (L < 11 || parseInt(code, 10) === 0) return false;

  if (parseInt(code.substr(3, 6), 10) === 0) return false;
  const c = parseInt(code.substr(10, 1), 10);
  const d = parseInt(code.substr(9, 1), 10) + 2;
  const z = [29, 27, 23, 19, 17];
  let s = 0;
  for (let i = 0; i < 10; i++) s += (d + parseInt(code.substr(i, 1), 10)) * z[i % 5];
  s %= 11;
  if (s === 10) s = 0;
  return c === s;
};

export const isValidPlaque = (value: string): boolean => {
  if (!value) return true;
  if (
    value.startsWith('/') ||
    value.endsWith('/') ||
    value.startsWith('-') ||
    value.includes('.') ||
    value.endsWith('-') ||
    value.startsWith('0')
  )
    return false;
  return true;
};

export const fetchDataQueryCreator = (
  query: AnyObject,
  fields: AnyObject,
  filterDto: string,
  search: (modelName: string, params: any) => Promise<any>,
  searchPath: string,
  historyFilters?: AnyObject,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const filters: AnyObject = {};
    if (query && Array.isArray(query.filters)) {
      query.filters.forEach((c) => {
        if (c.column.field === 'enabled') {
          lodash.set(filters, c.column.field, c.value === 'checked' || c.value === 'true');
        }
        if (c.column.field === 'updatedOn') {
          if (moment(c.value.from, 'YYYY-MM-DD', true).isValid() || moment(c.value.to, 'YYYY-MM-DD', true).isValid()) {
            lodash.set(
              filters,
              'updatedOnFrom',
              moment(`${c.value.from || '1000-01-01'} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
            );
            lodash.set(
              filters,
              'updatedOnTo',
              moment(`${c.value.to || '9999-09-09'} 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
            );
          }
        } else if (c.column.field === 'createdOn') {
          if (moment(c.value.from, 'YYYY-MM-DD', true).isValid() || moment(c.value.to, 'YYYY-MM-DD', true).isValid()) {
            lodash.set(
              filters,
              'createdOnFrom',
              moment(`${c.value.from || '1000-01-01'} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
            );
            lodash.set(
              filters,
              'createdOnTo',
              moment(`${c.value.to || '9999-09-09'} 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
            );
          }
        } else lodash.set(filters, c.column.field, Array.isArray(c.value) && c.value.length === 0 ? null : c.value);
      });
    }
    Object.keys(filters).forEach((prop) => {
      if (filters[prop] === '-1') {
        delete filters[prop];
      }
    });
    if (filters.enabled === 'checked') {
      filters.enabled = 'true';
    }
    if (filters.enabled === 'unchecked') {
      filters.enabled = 'false';
    }
    let sortFieldQuery = query?.orderBy?.field || 'createdOn';
    if (query?.orderBy?.field.includes('personnelFullName')) {
      sortFieldQuery = query?.orderBy?.field.replace('personnelFullName', 'person.firstName,person.lastName');
    }
    if (query?.orderBy?.field.includes('personFullName')) {
      const firstQuery = query?.orderBy?.field.replace('personFullName', '');
      sortFieldQuery = `${firstQuery}person.firstName,${firstQuery}person.lastName`;
    }
    if (query?.orderBy?.field.includes('fullName')) {
      const firstQuery = query?.orderBy?.field.replace('fullName', '');
      sortFieldQuery = `${firstQuery}person.firstName,${firstQuery}person.lastName`;
    }
    if (query?.orderBy?.field === 'code') {
      sortFieldQuery = 'numericCode';
    }
    const data = {
      currentPage: query?.page ? query?.page + 1 : 1,
      rowPerPage: query?.pageSize ? query?.pageSize : 200,
      sortField: sortFieldQuery,
      sortAscending: query?.orderDirection === 'asc' || false,
      enablePaging: true,
      fetchTotalRows: true,
      fields,
      filterMap: {
        suggestionQuery: query.search,
      },
      filterDto: {
        '@class': `com.lafarrerr.${filterDto}`,
        ...historyFilters,
        ...filters,
      },
    };
    search(searchPath, data)
      .then((response) => {
        const { results } = response.data;
        const { totalRows } = response.data;
        if (results.length === 0 && lodash.get(query, 'page') > 0)
          resolve(
            fetchDataQueryCreator(
              { ...query, page: parseInt(lodash.get(query, 'page'), 10) - 1 },
              fields,
              filterDto,
              search,
              searchPath,
            ),
          );
        resolve({
          data: results,
          // eslint-disable-next-line no-param-reassign
          page: query ? query.page++ : 1,
          totalCount: totalRows,
        });
      })
      .catch((err) => reject(err));
  });

export const fetchAutoCompleteQueryCreator = (
  query_string: string,
  filterDto: string,
  search: (modelName: string, params: any) => Promise<any>,
  searchPath: string,
  levelKey?: string,
  levelValue?: string,
  additionalFilter: AnyObject = {},
): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!searchPath) return reject();
    const data: AnyObject = {
      currentPage: 1,
      rowPerPage: 10,
      sortField: 'id',
      sortAscending: false,
      enablePaging: true,
      fetchTotalRows: true,
      fields: [],
      filterMap: {
        suggestionQuery: query_string || '',
      },
      filterDto: {
        '@class': `com.lafarrerr.${filterDto}`,
        ...additionalFilter,
      },
    };
    if (levelKey) data.filterDto[levelKey] = levelValue;
    axios
      .post(`${searchPath}/options`, data)
      .then((response) => {
        const results = response.data;
        resolve(results);
      })
      .catch((err) => reject(err));
  });
export const convertKeyedObjectToArray = (obj: AnyObject, key: string, value: string): AnyObject[] => {
  const result: AnyObject[] = [];
  Object.keys(obj).forEach((k) => {
    result.push({ [key]: k, [value]: obj[k] });
  });
  return result;
};
export const convertArrayToKeyedObject = (array: any[] = [], key = 'key', value = 'value'): AnyObject => {
  let result = {};
  if (array && Array.isArray(array))
    array.forEach((item) => {
      result = { ...result, [lodash.get(item, key)]: lodash.get(item, value) };
    });
  return result;
};

export const onChangeForBComponents = <T extends Record<string, unknown>>(
  e: AnyObject,
  setValue: SetValue,
  get: T,
  set: Dispatch<SetStateAction<T>>,
): void => {
  const { currentTarget: input } = e;
  const myForm = { ...get };
  if (input.value === '') input.value = null;
  lodash.set(myForm, input.name, input.value);
  setValue(input.name, input.value);
  set(myForm);
};

export const registerUseFormRequired = (register: Register, name: string, pattern: any = null): void => {
  register(name, { required: REQUIRED_STRING, pattern });
};

export const setValues = (setValue: SetValue, item: AnyObject): void => {
  if (item)
    Object.keys(item).forEach((element) => {
      setValue(element, item[element]);
    });
};

export const onChangeTable = (name: string, value: unknown): { currentTarget: { name: string; value: unknown } } => ({
  currentTarget: {
    name,
    value,
  },
});

export const removeTableData = (value: AnyObject | AnyObject[]): any[] => {
  const returnData: any[] = [];
  if (Array.isArray(value))
    value.forEach((element) => {
      const elCopy = { ...element };
      delete elCopy.tableData;
      returnData.push(elCopy);
    });
  return returnData;
};

export const removeTableDataAndConvertIdToInteger = (value: AnyObject[]): any[] | undefined => {
  if (value) {
    const returnData: any[] = [];
    value.forEach((element) => {
      const elCopy = { ...element };
      if (lodash.get(elCopy, 'consultant.id')) {
        lodash.set(elCopy, 'consultant.id', parseInt(lodash.get(elCopy, 'consultant.id'), 10));
      }
      if (lodash.get(elCopy, 'brand.id')) {
        lodash.set(elCopy, 'brand.id', parseInt(lodash.get(elCopy, 'brand.id'), 10));
      }
      if (lodash.get(elCopy, 'dayCount')) {
        lodash.set(elCopy, 'dayCount', parseInt(lodash.get(elCopy, 'dayCount'), 10));
      }
      if (lodash.get(elCopy, 'post')) lodash.set(elCopy, 'post.id', parseInt(lodash.get(elCopy, 'post.id'), 10));
      if (lodash.get(elCopy, 'internationalTerritory'))
        lodash.set(elCopy, 'internationalTerritory.id', parseInt(lodash.get(elCopy, 'internationalTerritory.id'), 10));
      delete elCopy.tableData;
      returnData.push(elCopy);
    });
    return returnData;
  }
};

export const onCountryChanged = (
  setValue: SetValue,
  setItem: Dispatch<SetStateAction<any>>,
  country: AnyObject,
): void => {
  setItem((old: AnyObject) => ({ ...old, country: { ...old.country, ...country } }));
  setValue('country', country);
};

export const onInternationalTerritoryChanged = (
  setItem: Dispatch<SetStateAction<any>>,
  internationalTerritory: AnyObject,
): void => {
  setItem((old: AnyObject) => ({
    ...old,
    country: { ...old.country, internationalTerritory },
  }));
};

export const editRemoveActions = (
  editURL: string,
  removePATH: string,
  history: AnyObject,
  removeItem: (modelName: string, id: string | number) => Promise<any>,
  tableRef: React.MutableRefObject<null>,
  globalDispatch: React.Dispatch<any>,
  historyPermission: (id: string | number) => Promise<any>,
  hasConfirmation: (data: AnyObject) => Promise<boolean> | undefined,
  confirmationHistory: string,
  confirmationHistoryNextButtonPermission: string,
): any[] => [
  (data: AnyObject) => ({
    icon: 'edit',
    tooltip: 'ویرایش',
    iconProps: {
      id: `edit-${data.tableData.id}`,
    },
    onClick: async (_: any, rowData: AnyObject) => {
      if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
      history.push(`${editURL}${rowData.id}`);
    },
  }),

  historyPermission
    ? (data: AnyObject) => ({
        icon: 'history',
        tooltip: 'سوابق',
        iconProps: {
          id: `history-${data.tableData.id}`,
        },
        onClick: async (_: any, rowData: AnyObject) => {
          historyPermission(rowData.id);
        },
      })
    : null,

  (data: AnyObject) => {
    if (hasConfirmation) {
      if (hasConfirmation(data)) {
        return {
          icon: () => <InventoryIcon />,
          tooltip: 'رد/تایید',
          iconProps: {
            id: `check-${data.tableData.id}`,
          },
          onClick: (_event: any, rowData: AnyObject) => {
            if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
            history.push(`${editURL}${rowData.id}?confirmation=true`);
          },
        };
      }
      return {
        icon: () => <InventoryIcon disabled />,
        tooltip: 'رد/تایید',
        iconProps: {
          id: `check-${data.tableData.id}`,
        },
        onClick: () => {
          // do nothing
        },
      };
    }
  },

  confirmationHistory
    ? (confirmData: AnyObject) => ({
        icon: () => <FactCheckIcon />,
        tooltip: 'سوابق تایید',
        iconProps: {
          id: `check-${confirmData.tableData.id}`,
        },
        onClick: (_event: any, rowData: AnyObject) => {
          if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
          history.push(
            `/workflow/process-task/${rowData.id}/${confirmationHistory}/${confirmationHistoryNextButtonPermission}`,
          );
        },
      })
    : null,

  removePATH
    ? (data: AnyObject) => ({
        icon: 'remove_circle',
        iconProps: {
          id: `remove-${data.tableData.id}`,
        },
        tooltip: 'حذف',
        onClick: async (_: any, rowData: AnyObject) => {
          BSwalConfirmationDelete().then((result) => {
            if (result.value) {
              removeItem(removePATH, rowData.id).then((response) => {
                if (response) {
                  if (response.status === 204 && tableRef.current) {
                    (tableRef.current as any).onQueryChange();
                    BSwalDeleted();
                  }
                  if (response.status === 409) {
                    BSwalShowError(lodash.get(response, 'data.messages', []));
                  }
                }
              });
            }
          });
        },
      })
    : null,
];

export const listReportQueryCreator = (
  columns: any[],
  filterDto: string,
  searchPath: string,
  additionalFilter: any = {},
): Promise<void> =>
  new Promise((resolve, reject) => {
    const fields: string[] = [];
    const filters: AnyObject = {};
    if (columns) {
      columns.forEach((c) => {
        if (c.field) fields.push(c.field);
        if (c.tableData.filterValue) {
          if (c.field === 'enabled') {
            if (c.tableData.filterValue === 'checked') filters[c.field] = true;
            else if (c.tableData.filterValue === 'unchecked') filters[c.field] = false;
          } else if (c.field === 'updatedOn') {
            if (c.tableData.filterValue.to) {
              if (
                moment(c.tableData.filterValue.from, 'YYYY-MM-DD', true).isValid() &&
                moment(c.tableData.filterValue.to, 'YYYY-MM-DD', true).isValid()
              ) {
                filters.updatedOnFrom = moment(c.tableData.filterValue.from).format('YYYY-MM-DD HH:mm:ss');
                filters.updatedOnTo = moment(c.tableData.filterValue.to).format('YYYY-MM-DD HH:mm:ss');
              }
            }
          } else if (c.field === 'createdOn') {
            if (c.tableData.filterValue.to) {
              if (
                moment(c.tableData.filterValue.from, 'YYYY-MM-DD', true).isValid() &&
                moment(c.tableData.filterValue.to, 'YYYY-MM-DD', true).isValid()
              ) {
                filters.createdOnFrom = moment(c.tableData.filterValue.from).format('YYYY-MM-DD HH:mm:ss');
                filters.createdOnTo = moment(c.tableData.filterValue.to).format('YYYY-MM-DD HH:mm:ss');
              }
            }
          } else filters[c.field] = c.tableData.filterValue;
        }
      });
    }
    const data = {
      currentPage: 1,
      rowPerPage: 200,
      sortField: 'id',
      sortAscending: false,
      enablePaging: true,
      fetchTotalRows: true,
      fields,
      filterMap: {
        suggestionQuery: '',
      },
      filterDto: {
        '@class': `com.lafarrerr.${filterDto}`,
        ...filters,
        ...additionalFilter,
      },
    };
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.Authorization = `Bearer ${cookies.get('userToken')}`;
    axios
      .post(`${searchPath}/listReport`, data, {
        responseType: 'blob',
      })
      .then((response) => {
        let filename = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('inline') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        FileSaver.saveAs(response.data, filename);
        resolve();
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .catch(() => reject());
  });

export const swalFire = (error: AnyObject): void => {
  if (error.response && error.response.status === 409) {
    Swal.fire({
      title: 'خطا در ذخیره',
      text: ConvertConflictErrorsOnSwal(error.response.data.messages[0].message),
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close',
    });
  }
};

export const onAddressChanged = (
  address: AnyObject,
  setValue: SetValue,
  get: AnyObject,
  set: Dispatch<SetStateAction<any>>,
): void => {
  const form = { ...get };
  form.address = {
    latitude: 0,
    longitude: 0,
    enabled: true,
    ...address,
  };
  setValue('address.latitude', 0);
  setValue('address.longitude', 0);
  setValue('address.enabled', true);
  set(form);
};

export const processDataForTreeView = (val: AnyObject): void => {
  lodash.set(val, 'title', val.name);
  lodash.set(val, 'label', val.name);
  lodash.set(val, 'value', val.id);
  lodash.set(val, 'key', val.id);
  for (let i = 0, len = val.children?.length; i < len; i++) {
    processDataForTreeView(val.children[i]);
  }
};

export const PropTypes = _PropTypes;
