import React, { createContext, useEffect, useReducer, useContext } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import reducer from './reducer/reducer';
import {
  Delete,
  Get,
  GetItem,
  GetAll,
  Post,
  Put,
  Search,
  AutoComplete,
  ListReport,
  FetchHistory,
  FetchImage,
  PostRefresh,
} from '../../helpers/APIHandlers';
import { SET_ITEM_LOADING, SET_ITEMS_LOADING, SET_ERROR, SET_SAVE_SUCCESS } from './reducer/actionTypes';
import { PropTypes } from '../../common/utilities';
import { UserCredentialsContext } from './LoginContext';
import { initContext } from './initContext';
import {
  DashboardPanelContextInterface,
  FetchItem,
  GlobalState,
  FetchList,
  SearchResponseInterface,
} from './contextInterfaces';
import { AnyObject } from '../../types';

function DeepTrim(obj: any) {
  Object.keys(obj).forEach((prop) => {
    const value = obj[prop];
    const type = typeof value;
    // eslint-disable-next-line no-prototype-builtins
    if (value != null && (type === 'string' || type === 'object') && obj.hasOwnProperty(prop)) {
      if (type === 'object') {
        DeepTrim(obj[prop]);
      } else {
        // eslint-disable-next-line no-param-reassign
        obj[prop] = obj[prop].trim();
      }
    }
  });
}

export const DashboardPanelContext = createContext<DashboardPanelContextInterface>(initContext);

/**
 *
 * @param props
 * @returns {createContext} This will return the dashboard layout context provider
 * @constructor
 */

const initialStateZero: GlobalState = {
  language: 'en',
  currentDate: '',
  error: undefined,
  enums: {},
  saveSuccess: false,
  isPanelOpen: true,
  itemLoading: false,
  itemsLoading: false,
  viewReadOnly: false,
  historyIdList: [],
  selectedMenuId: -1,
  pageLoading: false,
  currentCustomerPrimaryParameter: undefined,
  currentPersonnelPrimaryParameter: undefined,
  currentCustomerPrimaryParameterName: undefined,
  currentPersonnelPrimaryParameterName: undefined,
  isLoadingHistory: false,
  currentCurrency: { id: 'irt', name: 'تومان' },
};

function formatError(response: any) {
  const status = response ? response.status : -1;
  const messages = response && response.data && response.data.messages ? response.data.messages : [];
  const finalMessages = [];
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].fieldName) {
      finalMessages.push({
        fieldName: messages[i].fieldName ? messages[i].fieldName : '',
        message: messages[i].message ? messages[i].message : '',
      });
    } else {
      finalMessages.push({
        fieldName: '',
        message: messages[i],
      });
    }
  }
  const { message } = response?.data || '';
  if (message) finalMessages.push(message);
  return {
    status,
    messages: finalMessages,
  };
}

const initialState: GlobalState = {
  language: 'en',
  currentDate: '',
  enums: {},
  saveSuccess: false,
  isPanelOpen: true,
  itemLoading: false,
  itemsLoading: false,
  viewReadOnly: false,
  historyIdList: [],
  selectedMenuId: -1,
  currentCustomerPrimaryParameter: undefined,
  currentPersonnelPrimaryParameter: undefined,
  currentCustomerPrimaryParameterName: 'CITY',
  currentPersonnelPrimaryParameterName: 'CITY',
  isLoadingHistory: false,
  pageLoading: false,
  ...(localStorage.getItem('globalState') ? JSON.parse(localStorage.getItem('globalState') || '') : {}),
  error: undefined,
  currentCurrency: { id: 'irt', name: 'تومان' },
};

const DashboardPanelContextProvider: React.FC = ({ children }: any) => {
  const cookies = new Cookies();
  const [globalState, globalDispatch] = useReducer(reducer, initialState);
  const { onChangeCredentials } = useContext(UserCredentialsContext);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('globalState', JSON.stringify(globalState));
  }, [globalState]);

  const refreshToken = async (data: any, modelName: string, method: string, item?: any, id?: string | number) => {
    cookies.set('userToken', data?.authorisation.token, {
      maxAge: new Date().getTime() + 1000 * 36000,
      path: '/',
    });
    await onChangeCredentials(data);
    // localStorage.setItem('userCredentials', JSON.stringify(data));
    axios.defaults.headers.Authorization = `Bearer ${data?.authorisation.token}`;
    if (method === 'save') {
      if (id) {
        saveItem(modelName, item, id);
      } else {
        saveItem(modelName, item);
      }
    }
    if (method === 'get') {
      if (id) {
        fetchItem({ modelName, id });
      } else {
        fetchItem({ modelName, showLoader: true });
      }
    }
  };

  const fetchList = <T extends AnyObject = any>({
    modelName,
    page,
    size,
    showLoader = true,
    customRequestConfig = null,
    provinceCode = null,
  }: FetchList): Promise<T[]> | any => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    if (showLoader) {
      globalDispatch({ type: SET_ITEMS_LOADING, value: true });
    }

    return GetAll<T>(modelName, page, size, provinceCode, customRequestConfig)
      .then((response) => Promise.resolve(response && response.data ? response.data : []))
      .catch((error: AxiosError) => {
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => globalDispatch({ type: SET_ITEMS_LOADING, value: false }));
  };

  const fetchImage = (modelName: string, id: string | number, path: string, showLoader = true) => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    if (showLoader) globalDispatch({ type: SET_ITEM_LOADING, value: true });
    return FetchImage(modelName, id, path)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
      .finally(() => globalDispatch({ type: SET_ITEM_LOADING, value: false }));
  };

  function fetchItem<T>({ modelName, id, showLoader = true }: FetchItem): Promise<T> {
    globalDispatch({ type: SET_ERROR, value: undefined });
    if (showLoader) globalDispatch({ type: SET_ITEM_LOADING, value: true });
    if (id) {
      return Get<T>(modelName, id)
        .then(({ data }) => Promise.resolve(data))
        .catch((error) => {
          if (error?.response?.status === 401) {
            PostRefresh()
              .then((res) => {
                refreshToken(res.data, modelName, 'get', null, id);
              })
              .catch(() => {
                navigate('/logout');
              });
          }
          const response = error.response ? error.response : undefined;

          const finalError = formatError(response);
          globalDispatch({ type: SET_ERROR, value: finalError });
          return Promise.reject(response);
        })
        .finally(() => globalDispatch({ type: SET_ITEM_LOADING, value: false }));
    }
    return GetItem<T>(modelName)
      .then(({ data }) => Promise.resolve(data))
      .catch((error) => {
        if (error?.response?.status === 401) {
          PostRefresh()
            .then((res) => {
              refreshToken(res.data, modelName, 'get');
            })
            .catch(() => {
              navigate('/logout');
            });
        }
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => globalDispatch({ type: SET_ITEM_LOADING, value: false }));
  }

  // const fetchItemNoError = <T extends AnyObject = any>({
  //   modelName,
  //   id,
  //   showLoader = true,
  // }: FetchItem): Promise<T | undefined> => {
  //   globalDispatch({ type: SET_ERROR, value: undefined });
  //   if (showLoader) globalDispatch({ type: SET_ITEM_LOADING, value: true });
  //   return Get(modelName, id)
  //     .then((response) => Promise.resolve(response?.data || undefined))
  //     .catch((error) => Promise.reject(error))
  //     .finally(() => globalDispatch({ type: SET_ITEM_LOADING, value: false }));
  // };

  const search = <T extends AnyObject = any>(
    modelName: string,
    item: AnyObject,
  ): Promise<AxiosResponse<SearchResponseInterface<T>> | undefined> =>
    new Promise((resolve, reject) => {
      globalDispatch({ type: SET_ERROR, value: undefined });
      Search<SearchResponseInterface<T>>(modelName, item)
        .then(resolve)
        .catch((error) => {
          const response = error.response ? error.response : undefined;
          const finalError = formatError(response);
          globalDispatch({ type: SET_ERROR, value: finalError });
          reject(response);
        })
        .finally(() => {
          globalDispatch({ type: SET_ITEM_LOADING, value: false });
        });
    });

  const listReport = (modelName: string, item: AnyObject): Promise<AxiosResponse> => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    return ListReport(modelName, item)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => {
        globalDispatch({ type: SET_ITEM_LOADING, value: false });
      });
  };

  const fetchHistory = <T extends AnyObject = any>(
    modelName: string,
    item: string | number,
  ): Promise<AxiosResponse<SearchResponseInterface<T>>> => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    return FetchHistory<T>(modelName, item)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => {
        globalDispatch({ type: SET_ITEM_LOADING, value: false });
      });
  };

  const autoComplete = (modelName: string, item: AnyObject) => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    return AutoComplete(modelName, item)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => {
        globalDispatch({ type: SET_ITEM_LOADING, value: false });
      });
  };

  const saveItem = <T extends AnyObject = any>(
    modelName: string,
    item: Partial<T>,
    id?: string | number,
    showSuccess = true,
  ) => {
    DeepTrim(item);
    globalDispatch({ type: SET_ERROR, value: undefined });
    const _str = JSON.stringify(item);
    if (/[☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼σ]/.test(_str)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ data: { messages: ['کاراکتر های وارد شده مجاز نمی باشد.'] } });
    }
    if (id) {
      return Put(modelName, id, item)
        .then((response) => {
          if (showSuccess) globalDispatch({ type: SET_SAVE_SUCCESS, value: true });
          return Promise.resolve(response);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            PostRefresh()
              .then((res) => {
                refreshToken(res.data, modelName, 'save', item, id);
              })
              .catch(() => {
                navigate('/logout');
              });
          }
          const response = error.response ? error.response : undefined;
          const finalError = formatError(response);
          globalDispatch({ type: SET_ERROR, value: finalError });
          return Promise.reject(response);
        })
        .finally(() => {
          globalDispatch({ type: SET_ITEM_LOADING, value: false });
        });
    }
    return Post(modelName, item)
      .then((response) => {
        if (showSuccess) globalDispatch({ type: SET_SAVE_SUCCESS, value: true });
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          PostRefresh()
            .then((res) => {
              refreshToken(res.data, modelName, 'save', item);
            })
            .catch(() => {
              navigate('/logout');
            });
        }
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => {
        globalDispatch({ type: SET_ITEM_LOADING, value: false });
      });
  };

  const removeItem = (modelName: string, id: string | number) => {
    globalDispatch({ type: SET_ERROR, value: undefined });
    return Delete(modelName, id)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        const response = error.response ? error.response : undefined;
        const finalError = formatError(response);
        globalDispatch({ type: SET_ERROR, value: finalError });
        return Promise.reject(response);
      })
      .finally(() => globalDispatch({ type: SET_ITEM_LOADING, value: false }));
  };

  return (
    <DashboardPanelContext.Provider
      value={{
        autoComplete,
        search,
        listReport,
        fetchHistory,
        saveItem,
        removeItem,
        fetchList,
        fetchItem,
        // fetchItemNoError,
        fetchImage,
        globalState,
        globalDispatch,
        initialStateZero,
      }}
    >
      {children}
    </DashboardPanelContext.Provider>
  );
};

DashboardPanelContextProvider.propTypes = {
  children: PropTypes.any,
};

export default DashboardPanelContextProvider;
