import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AppRoutes from './Routes';
import DashboardPanelProvider from './components/context/DashboardContext';
import UserCredentialsProvider from './components/context/LoginContext';
// import 'antd/dist/reset.css';
import './assets/scss/index.scss';

export default () => (
  <BrowserRouter>
    <UserCredentialsProvider>
      <DashboardPanelProvider>
        <ConfigProvider direction='ltr' style={{ background: '#fff' }}>
          <AppRoutes />
        </ConfigProvider>
      </DashboardPanelProvider>
    </UserCredentialsProvider>
  </BrowserRouter>
);
