import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ salesData }) => {
  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        // text: 'Chart.js Line Chart - Multi Axis',
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      // y1: {
      //   type: 'linear',
      //   display: true,
      //   position: 'right',
      //   grid: {
      //     drawOnChartArea: false,
      //   },
      // },
    },
  };

  const labels = Array.from({ length: 7 })
    .map((_, index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date.toISOString().split('T')[0];
    })
    .reverse();

  // Format labels to "YY/MM/DD"
  const formattedLabels = labels.map((labelDate) => {
    const [year, month, day] = labelDate.split('-');
    return `${year.slice(2)}/${month}/${day}`; // "YY/MM/DD"
  });

  const totalSellData = labels.map((labelDate) => {
    const sale = salesData?.find((item) => item.date === labelDate);
    return sale ? parseFloat(sale.total_sell) : 0;
  });

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        label: 'USD',
        data: totalSellData,
        borderColor: '#5A6FF0',
        backgroundColor: '#00FFDD',
        yAxisID: 'y',
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
