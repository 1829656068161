import React, { useEffect, useState, useMemo } from 'react';
import { filterDeep } from 'deepdash-es/standalone';
import _ from 'lodash';
import {
  DatabaseOutlined,
  FileImageOutlined,
  MenuOutlined,
  SettingOutlined,
  DiffOutlined,
  CustomerServiceOutlined,
  UsergroupDeleteOutlined,
  BookOutlined,
  AppstoreOutlined,
  UserSwitchOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  ShoppingOutlined,
  SettingFilled,
  FileTextOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Profile } from './components';
import Locale from '../../../../helpers/Locale';
import { dashboardTitles } from '../../../../data/Dictionary';

const { Sider } = Layout;

const Sidebar = ({ credentials }) => {
  const [credential, setCredential] = useState(credentials);
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);
  const Language = Locale();

  useEffect(() => {
    setCredential(credentials);
  }, [credentials]);

  const [menus] = useState(() => [
    {
      key: '6',
      icon: <AppstoreAddOutlined />,
      label: dashboardTitles[Language].productManagement,
      children: [
        {
          key: '60',
          permission: 'product-category.index',
          label: dashboardTitles[Language].productCategories,
          children: [
            {
              key: '600',
              path: 'product-management/main-product-categories',
              permission: 'product-category.index',
              label: (
                <Link to='/product-management/main-product-categories'>
                  {dashboardTitles[Language].mainProductCategories}
                </Link>
              ),
            },
            {
              key: '601',
              path: 'product-management/product-categories',
              permission: 'product-category.index',
              label: (
                <Link to='/product-management/product-categories'>
                  {dashboardTitles[Language].subProductCategories}
                </Link>
              ),
            },
          ],
        },
        {
          key: '61',
          path: 'product-management/attribute-categories',
          permission: 'attribute-category.index',
          label: (
            <Link to='/product-management/attribute-categories'>{dashboardTitles[Language].attributeCategories}</Link>
          ),
        },
        {
          key: '62',
          path: 'product-management/brands',
          permission: 'brand.index',
          label: <Link to='/product-management/brands'>{dashboardTitles[Language].brands}</Link>,
        },
        {
          key: '63',
          path: 'product-management/units',
          permission: 'unit.index',
          label: <Link to='/product-management/units'>{dashboardTitles[Language].units}</Link>,
        },
        {
          key: '64',
          path: 'product-management/attributes',
          permission: 'attribute.index',
          label: <Link to='/product-management/attributes'>{dashboardTitles[Language].attributes}</Link>,
        },
        {
          key: '65',
          path: 'product-management/products',
          permission: 'product.index',
          label: <Link to='/product-management/products'>{dashboardTitles[Language].allProducts}</Link>,
        },
        {
          key: '42',
          path: 'customer-management/discounts',
          permission: 'discounts.index',
          label: <Link to='/customer-management/discounts'>{dashboardTitles[Language].discounts}</Link>,
        },
      ],
    },
    {
      key: '8',
      icon: <ShoppingOutlined />,
      label: dashboardTitles[Language].orderManagement,
      children: [
        {
          key: '80',
          permission: 'admin.get-all-provinces.getAllProvinces',
          path: 'order-management/provinces',
          label: <Link to='/order-management/provinces'>{dashboardTitles[Language].provincesManagement}</Link>,
        },
        {
          key: '81',
          permission: 'admin.get-all-cities.getAllCities',
          path: 'order-management/cities',
          label: <Link to='/order-management/cities'>{dashboardTitles[Language].citiesManagement}</Link>,
        },
        {
          key: '82',
          path: 'delivery-management/delivery-methods',
          permission: 'admin.delivery_method.index',
          label: <Link to='/delivery-management/delivery-methods'>{dashboardTitles[Language].deliveryMethods}</Link>,
        },
        {
          key: '83',
          path: 'order-management/order-status',
          permission: 'roles.assign.permission',
          label: <Link to='/order-management/orders-status'>{dashboardTitles[Language].orderStatus}</Link>,
        },
        {
          key: '84',
          path: 'order-management/orders',
          permission: 'admin.orders.index',
          label: <Link to='/order-management/orders'>{dashboardTitles[Language].ordersList}</Link>,
        },
      ],
    },
    {
      key: '1',
      icon: <DatabaseOutlined />,
      label: dashboardTitles[Language].contentManagement,
      children: [
        {
          key: '10',
          icon: <FileImageOutlined />,
          label: dashboardTitles[Language].slideshowManagement,
          children: [
            {
              key: '100',
              path: 'cms/slideshow-list',
              permission: 'sliders.index',
              label: <Link to='/cms/slideshow-list'>{dashboardTitles[Language].slideshowList}</Link>,
            },
            {
              key: '101',
              path: 'cms/slider-list',
              permission: 'galleries.show',
              label: <Link to='/cms/slider-list'>{dashboardTitles[Language].developerSliderList}</Link>,
            },
          ],
        },
        {
          key: '11',
          icon: <MenuOutlined />,
          label: dashboardTitles[Language].menuManagement,
          children: [
            {
              key: '111',
              path: 'cms/menu-items',
              permission: 'menu-items.index',
              label: <Link to='/cms/menu-items'>{dashboardTitles[Language].menuItemsList}</Link>,
            },
            {
              key: '112',
              path: 'cms/menu-list',
              permission: 'menus.show',
              label: <Link to='/cms/menu-list'>{dashboardTitles[Language].developerMenuList}</Link>,
            },
          ],
        },
        {
          key: '13',
          icon: <DiffOutlined />,
          label: dashboardTitles[Language].pagesManagement,
          children: [
            {
              key: '131',
              path: 'cms/pages',
              permission: 'pages.index',
              icon: <DiffOutlined />,
              label: <Link to='/cms/pages'>{dashboardTitles[Language].pagesList}</Link>,
            },
            // {
            //   key: '132',
            //   path: 'cms/faqs',
            //   permission: 'pages.index',
            //   icon: <QuestionCircleOutlined />,
            //   label: <Link to='/cms/faqs'> لیست پرسش های متداول</Link>,
            // },
            // {
            //   key: '133',
            //   path: 'cms/comments',
            //   permission: 'pages.index',
            //   icon: <CommentOutlined />,
            //   label: <Link to='/cms/comments'> لیست نظرات</Link>,
            // },
          ],
        },
        {
          key: '12',
          icon: <SettingOutlined />,
          label: dashboardTitles[Language].settingsManagement,
          children: [
            {
              key: '121',
              path: 'cms/settings',
              permission: 'settings.index',
              label: <Link to='/cms/settings'>{dashboardTitles[Language].settingsList}</Link>,
            },
            {
              key: '122',
              path: 'cms/setting-sections',
              permission: 'setting-sections.show',
              label: <Link to='/cms/setting-sections'>{dashboardTitles[Language].developerSettingSections}</Link>,
            },
          ],
        },
        {
          key: '14',
          icon: <FileTextOutlined />,
          label: dashboardTitles[Language].blogManagement,
          children: [
            {
              key: '141',
              path: 'cms/blogs',
              permission: 'blogs.index',
              label: <Link to='/cms/blogs'>{dashboardTitles[Language].blogsList}</Link>,
            },
          ],
        },
      ],
    },
    {
      key: '2',
      icon: <AppstoreOutlined />,
      label: dashboardTitles[Language].serviceManagement,
      children: [
        {
          key: '20',
          icon: <CustomerServiceOutlined />,
          label: 'مدیریت سرویس ها',
          children: [
            {
              key: '200',
              path: 'salon-management/service-categories',
              permission: 'service-categories.index',
              label: (
                <Link to='/salon-management/service-categories'>{dashboardTitles[Language].serviceCategories}</Link>
              ),
            },
            {
              key: '201',
              path: 'salon-management/services',
              permission: 'services.index',
              label: <Link to='/salon-management/services'>{dashboardTitles[Language].servicesList}</Link>,
            },
          ],
        },
        {
          key: '21',
          icon: <CustomerServiceOutlined />,
          label: dashboardTitles[Language].consultationRequests,
          children: [
            {
              key: '210',
              path: 'salon-management/contact-form',
              permission: 'contact-form.index',
              label: <Link to='/salon-management/contact-forms'>{dashboardTitles[Language].contactFormsList}</Link>,
            },
          ],
        },
      ],
    },
    {
      key: '3',
      icon: <UsergroupDeleteOutlined />,
      label: dashboardTitles[Language].customerManagement,
      children: [
        {
          key: '30',
          path: 'customer-management/customers',
          permission: 'customers.index',
          label: <Link to='/customer-management/customers'>{dashboardTitles[Language].customersList}</Link>,
        },
      ],
    },
    {
      key: '4',
      icon: <BookOutlined />,
      label: dashboardTitles[Language].courseManagement,
      children: [
        {
          key: '40',
          path: 'course-management/videos',
          permission: 'videos.index',
          label: <Link to='/course-management/videos'>{dashboardTitles[Language].videosList}</Link>,
        },
        {
          key: '41',
          path: 'course-management/courses',
          permission: 'courses.index',
          label: <Link to='/course-management/courses'>{dashboardTitles[Language].coursesList}</Link>,
        },
      ],
    },
    {
      key: '7',
      icon: <BarChartOutlined />,
      label: dashboardTitles[Language].reports,
      children: [
        {
          key: '70',
          path: 'reports/products',
          permission: 'roles.index',
          label: <Link to='/reports/products'>{dashboardTitles[Language].reportsByProduct}</Link>,
        },
        {
          key: '71',
          path: 'reports/orders',
          permission: 'roles.index',
          label: <Link to='/reports/orders'>{dashboardTitles[Language].reportsByOrder}</Link>,
        },
        {
          key: '72',
          path: 'reports/customers',
          permission: 'roles.index',
          label: <Link to='/reports/customers'>{dashboardTitles[Language].reportsByCustomer}</Link>,
        },
        {
          key: '73',
          path: 'reports/discounts',
          permission: 'roles.index',
          label: <Link to='/reports/discounts'>{dashboardTitles[Language].reportsByDiscount}</Link>,
        },
      ],
    },
    {
      key: '5',
      icon: <UserSwitchOutlined />,
      label: dashboardTitles[Language].accessManagement,
      children: [
        {
          key: '50',
          path: 'access-management/roles',
          permission: 'roles.index',
          label: <Link to='/access-management/roles'>{dashboardTitles[Language].rolesList}</Link>,
        },
        {
          key: '51',
          path: 'access-management/users',
          permission: 'users.index',
          label: <Link to='/access-management/users'>{dashboardTitles[Language].usersList}</Link>,
        },
      ],
    },
    {
      key: '9',
      icon: <SettingFilled />,
      label: dashboardTitles[Language].siteSettings,
      children: [
        {
          key: '90',
          path: 'settings-management/get-default-currency',
          permission: 'admin.set.default.currency',
          label: (
            <Link to='/settings-management/get-default-currency'>{dashboardTitles[Language].defaultCurrency}</Link>
          ),
        },
      ],
    },
  ]);

  const [pages, setPages] = useState([]);
  const [current, setCurrent] = useState('');

  useEffect(() => {
    const foundMenu = menus.find((menu) => menu.children?.find((submenu) => location.pathname.includes(submenu.path)));

    if (foundMenu) {
      const foundChild = foundMenu.children.find((submenu) => location.pathname.includes(submenu.path));

      if (foundChild) {
        setCurrent(foundChild.key);
      } else {
        setCurrent(foundMenu.key);
      }
    }
  }, [location.pathname, menus]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    setPermissions(_.get(credential.user, 'permissions', []));
  }, [credential]);

  useEffect(() => {
    if (permissions.length > 0 && menus.length > 0)
      setPages(
        filterDeep(
          menus,
          (value) => {
            if (value) {
              if (permissions.find((permission) => permission.name === value.permission)?.id) {
                return true;
              }
              return false;
            }
            return false;
          },
          { childrenPath: 'children', checkCircular: false },
        ),
      );
  }, [permissions, menus]);

  return (
    <Sider theme='light' breakpoint='lg' collapsedWidth='0' style={{ minHeight: '100%', color: '#38291E' }} width={300}>
      <div className='logo'>
        <Profile credentials={credential} />
      </div>
      <Menu
        theme='dark'
        mode='inline'
        items={pages}
        onClick={onClick}
        selectedKeys={[current]}
        style={{ minHeight: '100vh' }}
      />
    </Sider>
  );
};

export default Sidebar;
