import React from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const barChart = ({ salesData }) => {
  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        // text: 'Chart.js Line Chart - Multi Axis',
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      // y1: {
      //   type: 'linear',
      //   display: true,
      //   position: 'right',
      //   grid: {
      //     drawOnChartArea: false,
      //   },
      // },
    },
  };
  const labels = Array.from({ length: 6 })
    .map((_, index) => {
      const date = new Date();
      date.setMonth(date.getMonth() - index);
      return date.toISOString().slice(0, 7); // "YYYY-MM"
    })
    .reverse();

  // Map each label to the corresponding quantity from salesData or 0 if not present
  const totalSellData = labels.map((labelDate) => {
    const [year, month] = labelDate.split('-').map(Number); // Extract year and month
    const sale = salesData?.find((item) => item.year === year && item.month === month);

    return sale ? parseFloat(sale.total_quantity_sold) : 0;
  });

  const data = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Number of Products',
        backgroundColor: 'rgb(53, 162, 235)',
        data: totalSellData,
      },
    ],
  };

  return <Chart type='bar' data={data} options={options} />;
};

export default barChart;
