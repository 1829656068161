import React, { createContext, useCallback, useState, useContext } from 'react';
import { GetItem } from '../../helpers/APIHandlers';

const initCredentials = localStorage.getItem('userCredentials')
  ? JSON.parse(localStorage.getItem('userCredentials'))
  : {
      user: { permissions: [] },
      authorisation: null,
    };

export const UserCredentialsContext = createContext({
  credentials: initCredentials,
  onChangeCredentials: async (data) =>
    // Placeholder function
    Credential,
});

export const useUserCredentialsContext = () => useContext(UserCredentialsContext).credentials;

/**
 *
 * @param props
 * @returns {createContext} This will return the user context provider
 * @constructor
 */
export default function UserCredentialsProvider({ children }) {
  const [credentials, setCredentials] = useState(initCredentials);

  const onChangeCredentials = useCallback(
    async (data) => {
      if (!data?.user?.id) {
        console.warn('Invalid user data provided to onChangeCredentials');
        return credentials;
      }

      const updatedCredentials = { ...credentials };
      updatedCredentials.user.name = data.user.name;

      try {
        const response = await GetItem('user');

        updatedCredentials.authorisation = data.authorisation;

        if (response?.data?.data?.roles?.[0]?.permissions) {
          updatedCredentials.user.permissions = [...response.data.data.roles[0].permissions];
        }

        // Save updated credentials to localStorage
        localStorage.setItem('userCredentials', JSON.stringify(updatedCredentials));
        setCredentials(updatedCredentials);
      } catch (error) {
        console.error('Error in onChangeCredentials:', error);
        throw error;
      }

      return updatedCredentials;
    },
    [credentials],
  );
  return (
    <UserCredentialsContext.Provider value={{ credentials, onChangeCredentials }}>
      {children}
    </UserCredentialsContext.Provider>
  );
}
