import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';

const initialItem = {
  name: '',
  manufacturer: '',
  description: '',
  image: '',
  active: true,
  display: true,
};
const cookies = new Cookies();

const BrandPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState } = useContext(DashboardPanelContext);
  const { viewReadOnly } = globalState;
  const [sliderImage, setSliderImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const fileInputRef = useRef(null);
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/brand',
    localStorageKey: 'brand',
    initialItem,
  });

  const fileSelectedHandlerLoad = async (e) => {
    setSliderImage(null);
    const url = process.env.REACT_APP_SITE_URL;
    const name = `${url}`.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setSliderImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
    if (currentItem?.id && currentItem?.attachments && currentItem.attachments[0]) {
      fileSelectedHandlerLoad(currentItem.attachments[0].path);
    }
  }, [currentItem]);

  const fileSelectedHandler = async (e) => {
    const _currentItem = currentItem;
    _currentItem.image = null;
    setSliderImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentItem.image = file;
    setCurrentItem(_currentItem);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setSliderImage({ img: reader.result });
    };
  };

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (_currentItem.active && _currentItem.active !== 0) {
      _currentItem.active = 1;
    } else {
      _currentItem.active = 0;
    }
    if (_currentItem.display && _currentItem.display !== '0') {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    delete _currentItem.attachments;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(_currentItem).length; i++) {
      formData.append(
        Object.keys(_currentItem)[i],
        Object.values(_currentItem)[i] || Object.values(_currentItem)[i] === 0 ? Object.values(_currentItem)[i] : '',
      );
    }
    if (id) {
      formData.append('_method', 'put');
    }
    axios({
      method: 'post',
      url: id ? 'brand/'.concat(id) : 'brand',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          navigate('/product-management/brands');
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };
  const onRemoveImage = () => {
    setSliderImage(null);
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setCurrentItem(_currentSlider);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/product-management/brands',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Brand Info' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='Name'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='Manufacturer'
                  name='manufacturer'
                  value={currentItem?.manufacturer}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.manufacturer}
                  required
                />
                <BTextareaField
                  onChange={onChange}
                  name='description'
                  sm={12}
                  xs={12}
                  value={currentItem?.description}
                  label='Description'
                  readOnly={viewReadOnly}
                  required
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>Select Image</div>
                  <span>
                    The uploaded file size must be a maximum of 4MB. The image format should be .webp, .jpeg/.jpg, or
                    .png.
                  </span>
                  <br />
                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandler}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {sliderImage?.img && (
                    <>
                      <button type='button' onClick={() => onRemoveImage()} aria-label='Remove Image'>
                        <DeleteOutlined />
                      </button>
                      <img alt='slider' src={sliderImage.img} style={{ width: '200px', height: '200px' }} />
                    </>
                  )}
                </Grid>
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='Active'
                  value={currentItem?.display === '0' ? false : currentItem?.display || false}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='active'
                  label='Publish'
                  value={currentItem?.active === 0 ? false : currentItem?.active || false}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/product-management/brands',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to List
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default BrandPage;
