import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BTextareaField from '../../../../components/inputs/forms/BTextareaField';
import { setValues, onChangeForBComponents } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import CkeditorField from '../../../../components/inputs/CkeditorField';

const initialItem = {
  name: '',
  name_fa: '',
  keywords: '',
  description: '',
  content: '',
  display: true,
  home_display: true,
  test: 'test',
  image: null,
  cover: null,
};
const url = process.env.REACT_APP_SITE_URL;
const cookies = new Cookies();

const BlogPage = (props) => {
  const navigate = useNavigate();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);
  const [textEditor, setTextEditor] = useState('');
  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const [mainImage, setMainImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/blogs',
    localStorageKey: 'blogs',
    initialItem,
  });

  const fileSelectedHandlerLoadImage = async (e) => {
    setMainImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setMainImage({ img: imgSrc });
  };

  const fileSelectedHandlerLoadCoverImage = async (e) => {
    setCoverImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setCoverImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
    if (currentItem.id) {
      currentItem.test = 'test';
    }
    if (currentItem?.id && currentItem.attachments && currentItem.attachments[0]) {
      const main = currentItem.attachments.find((attachment) => attachment.slug === 'image');
      const cover = currentItem.attachments.find((attachment) => attachment.slug === 'cover');
      if (main) {
        fileSelectedHandlerLoadImage(main.path);
      }
      if (cover) {
        fileSelectedHandlerLoadCoverImage(cover.path);
      }
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (currentItem.display) {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    if (currentItem.home_display) {
      _currentItem.home_display = '1';
    } else {
      _currentItem.home_display = '0';
    }
    if (currentItem?.keywords?.length > 0) {
      const _array = currentItem?.keywords.split('،');
      if (_array?.length === 0) {
        BSwalShowError(['The format of the keywords is not correct.']);
        setLoading(false);
        return;
      }
    } else {
      BSwalShowError(['The format of the keywords is not correct.']);
      setLoading(false);
      return;
    }
    delete _currentItem.attachments;
    delete _currentItem?.test;
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(_currentItem).length; i++) {
      formData.append(
        Object.keys(_currentItem)[i],
        Object.values(_currentItem)[i] ? Object.values(_currentItem)[i] : '',
      );
    }
    if (id) {
      formData.append('_method', 'put');
    }
    axios({
      method: 'post',
      url: id ? 'blogs/'.concat(id) : 'blogs',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          navigate('/cms/blogs');
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const fileSelectedHandlerImage = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setMainImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setMainImage({ img: reader.result });
    };
  };

  const fileSelectedHandlerCoverImage = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.cover = null;
    setCoverImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.cover = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setCoverImage({ img: reader.result });
    };
  };

  useEffect(() => {
    setCurrentItem({ ...currentItem, content: textEditor });
  }, [textEditor]);

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/cms/blogs',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Blog Info' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  sm={6}
                  xs={12}
                  label='Blog Title'
                  name='name_fa'
                  value={currentItem?.name_fa}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name_fa}
                  required
                />
                <BTextField
                  sm={6}
                  xs={12}
                  label='Blog Link'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                  // {...register('name', { required: true })}
                />
                <BTextareaField
                  onChange={onChange}
                  name='description'
                  sm={12}
                  xs={12}
                  value={currentItem?.description}
                  label='Short Description'
                  required
                />
                <Grid item xs={12}>
                  {currentItem.test && (
                    <CkeditorField value={currentItem.content} onChange={(data) => setTextEditor(data)} />
                  )}
                </Grid>
                <BTextareaField
                  onChange={onChange}
                  name='keywords'
                  sm={6}
                  xs={12}
                  value={currentItem?.keywords}
                  label='Keywords (Separated with Comma (,))'
                  required
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>Select Blog Image</div>
                  <span>
                    The uploaded file size must be a maximum of 4MB. The image format should be .webp, .jpeg/.jpg, or
                    .png.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandlerImage}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {mainImage?.img && <img src={mainImage.img} alt='main' style={{ width: '200px', height: '200px' }} />}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>Select Cover Image</div>
                  <span>
                    The uploaded file size must be a maximum of 4MB. The image format should be .webp, .jpeg/.jpg, or
                    .png.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandlerCoverImage}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {coverImage?.img && (
                    <img src={coverImage.img} alt='cover' style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid>
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='Active'
                  value={Number(currentItem?.display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='home_display'
                  label='Homepage Display'
                  value={Number(currentItem?.home_display)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/cms/blogs',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to List
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default BlogPage;
