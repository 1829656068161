import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import BAsAutoComplete from '../../../../components/inputs/forms/BAsyncAutoCompleteNew';
import CkeditorField from '../../../../components/inputs/CkeditorField';
import { SET_SAVE_SUCCESS } from '../../../../components/context/reducer/actionTypes';

const initialItem = {
  name: '',
  name_en: '',
  product_category_id: null,
  brand_id: null,
  description: '',
  // instruction: '',
  base_price: '',
  off_price: '',
  slug: '',
  new: true,
  special: true,
  popular: true,
  image: null,
  image_main: null,
  video: null,
  cover_image: null,
  active: true,
  display: true,
};
const cookies = new Cookies();
const url = process.env.REACT_APP_SITE_URL;

const ProductPage = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const firstRender = useRef(false);
  const { staticContext, ...rest } = props;
  const { globalState, fetchItem, saveItem, globalDispatch } = useContext(DashboardPanelContext);
  const [categories, setCategories] = useState([]);
  const [categoriesLastPage, setCategoriesLastPage] = useState(1);
  // const [allBrands, setAllBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsLastPage, setBrandsLastPage] = useState(1);
  const { viewReadOnly, currentCurrency } = globalState;
  const [sliderImage, setSliderImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();
  const fileInputRef = useRef(null);
  const mainFileInputRef = useRef(null);
  const [textEditor, setTextEditor] = useState('');
  const [textEditorInstruction, setTextEditorInstruction] = useState('');
  const [removedImages, setRemovedImages] = useState([]);
  const [introVideo, setIntroVideo] = useState(null);
  const [videoCover, setVideoCover] = useState(null);
  const page = searchParams.get('page');
  const title = searchParams.get('title');
  // const [productQuantity, setProductQuantity] = useState(0);
  const {
    id,
    item: currentItem,
    setItem: setCurrentItem,
  } = useBasicInformationMountedEffect({
    match: { params: props.param },
    fetchItemURL: '/product',
    localStorageKey: 'product',
    initialItem,
  });

  // useEffect(() => {
  //   if (currentItem?.id && currentItem.inventory) {
  //     setProductQuantity(currentItem.inventory.quantity);
  //   }
  // }, [currentItem?.id]);

  // const handleSearchTitle = (query) => {
  //   if (query) {
  //     saveItem('/brand/search', { name: query }).then(async (response) => {
  //       globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
  //       if (response) {
  //         const allData = [];
  //         // const number_of_pages = response.data?.data?.last_page;
  //         // setTotalPageNumber(number_of_pages * 10);
  //         for (let i = 0; i < response.data?.data?.data?.length; i++) {
  //           allData.push({ ...response.data?.data?.data[i], key: response.data?.data?.data[i].id });
  //         }
  //         setBrands((prev) => {
  //           const existingKeys = new Set(prev.map((brand) => brand.key)); // Store existing keys
  //           const newBrands = allData
  //             .filter((brand) => !existingKeys.has(brand.id)) // Only add if key does not exist
  //             .map((brand) => ({ ...brand, key: brand.id }));

  //           return [...prev, ...newBrands]; // Merge unique items
  //         });
  //         // setAllBrands((prev) => {
  //         //   const existingKeys = new Set(prev.map((brand) => brand.key)); // Store existing keys
  //         //   const newBrands = allData
  //         //     .filter((brand) => !existingKeys.has(brand.id)) // Only add if key does not exist
  //         //     .map((brand) => ({ ...brand, key: brand.id }));

  //         //   return [...prev, ...newBrands]; // Merge unique items
  //         // });
  //       }
  //     });
  //   } else {
  //     setBrands([]);
  //   }
  // };

  const fileSelectedHandlerLoad = async (paths) => {
    setSliderImage(null);
    const images = paths.map((path) => {
      const name = `${url}`.concat(path.path);
      const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
      return { imgSrc, id: path.id };
    });

    if (images.length > 0) {
      setSliderImage({ img: images });
    }
  };

  const mainFileSelectedHandlerLoad = async (e, attachId) => {
    setMainImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setMainImage({ img: imgSrc, id: attachId });
  };

  const fileSelectedHandlerLoadVideo = async (e, attachId) => {
    setIntroVideo(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setIntroVideo({ img: imgSrc, id: attachId });
  };

  const fileSelectedHandlerLoadVideoCover = async (e, attachId) => {
    setVideoCover(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setVideoCover({ img: imgSrc, id: attachId });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem?.id && currentItem?.attachments) {
      const paths = [];
      for (let i = 0; i < currentItem.attachments.length; i++) {
        if (currentItem.attachments[i].slug === 'image_main') {
          mainFileSelectedHandlerLoad(currentItem.attachments[i].path, currentItem.attachments[i].id);
        } else if (currentItem.attachments[i].slug === 'video') {
          fileSelectedHandlerLoadVideo(currentItem.attachments[i].path, currentItem.attachments[i].id);
        } else if (currentItem.attachments[i].slug === 'cover_image') {
          fileSelectedHandlerLoadVideoCover(currentItem.attachments[i].path, currentItem.attachments[i].id);
        } else {
          paths.push({ path: currentItem.attachments[i].path, id: currentItem.attachments[i].id });
        }
      }
      if (paths.length > 0) fileSelectedHandlerLoad(paths);
    }
  }, [currentItem?.id]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    if (_currentItem.active && _currentItem.active !== 0) {
      _currentItem.active = 1;
    } else {
      _currentItem.active = 0;
    }
    if (_currentItem.new && _currentItem.new !== '0') {
      _currentItem.new = '1';
    } else {
      _currentItem.new = '0';
    }
    if (_currentItem.special && _currentItem.special !== '0') {
      _currentItem.special = '1';
    } else {
      _currentItem.special = '0';
    }
    if (_currentItem.popular && _currentItem.popular !== '0') {
      _currentItem.popular = '1';
    } else {
      _currentItem.popular = '0';
    }
    if (_currentItem.display && _currentItem.display !== '0') {
      _currentItem.display = '1';
    } else {
      _currentItem.display = '0';
    }
    _currentItem.name_en = currentItem.name;
    delete _currentItem.attachments;
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    Object.keys(_currentItem).forEach((key) => {
      const value = _currentItem[key];

      if (Array.isArray(value)) {
        // Handle an array of images
        value.forEach((item, index) => {
          formData.append(`${key}${index + 1}`, item);
        });
      } else if (value instanceof FileList) {
        // Handle multiple files (e.g., input type="file" with multiple attribute)
        Array.from(value).forEach((item) => {
          formData.append(key, item);
        });
      } else if (value instanceof File) {
        // Handle a single file
        formData.append(key, value);
      } else {
        // Handle other form fields
        if (key === 'instruction' && !value) {
          return;
        }
        formData.append(key, value || value === 0 ? value : '');
      }
    });
    if (id) {
      formData.append('_method', 'put');
    }
    axios({
      method: 'post',
      url: id ? 'product/'.concat(id) : 'product',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        // if (id) {
        //   saveItem(
        //     `product-inventory`,
        //     {
        //       product_id: id,
        //       quantity: productQuantity,
        //     },
        //     _currentItem.inventory?.id || null,
        //   ).then(() => {
        //     if (res.data.data) {
        //       navigate('/product-management/products');
        //     }
        //   });
        // } else if (res.data.data) {
        //   navigate('/product-management/products');
        // }
        if (res.data.data) {
          if (removedImages.length > 0) {
            const imageFormData = new FormData();
            const removedImagesJSON = JSON.stringify(removedImages);
            imageFormData.append('attachment_ids', removedImagesJSON);
            axios({
              method: 'post',
              url: 'product/delete-attachment',
              data: imageFormData,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('userToken')}`,
              },
            }).then(() => {
              if (page) {
                if (title) {
                  navigate(`/product-management/products?page=${page}&title=${title}`);
                } else {
                  navigate(`/product-management/products?page=${page}`);
                }
              } else if (title) {
                navigate(`/product-management/products?title=${title}`);
              } else {
                navigate('/product-management/products');
              }
            });
          } else if (page) {
            if (title) {
              navigate(`/product-management/products?page=${page}&title=${title}`);
            } else {
              navigate(`/product-management/products?page=${page}`);
            }
          } else if (title) {
            navigate(`/product-management/products?title=${title}`);
          } else {
            navigate('/product-management/products');
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error === 'Slug already exists') {
          BSwalShowError(['This product slug exists.']);
        } else {
          BSwalShowError(err?.data?.messages);
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };

  const fileSelectedHandler = async (e) => {
    const { files } = e.target;
    if (!files.length) return;

    const newImages = Array.from(files);

    const currentItemCopy = { ...currentItem, image: null };
    currentItemCopy.image = newImages;
    setCurrentItem(currentItemCopy);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSliderImage({ img: newImages });
    };
    reader.readAsDataURL(newImages[0]);
  };

  const mainFileSelectedHandler = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image_main = null;
    setMainImage(null);
    const file = e.target.files[0];
    _currentSlider.image_main = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setMainImage({ img: reader.result, id: null });
    };
  };

  const onRemoveImage = (image) => {
    const _currentItem = currentItem;
    _currentItem.image = null;
    setSliderImage((prevSliderImage) => {
      let newImages;
      if (image.imgSrc) {
        newImages = prevSliderImage?.img.filter((file) => {
          if (image.id && file.id) {
            return file.id !== image.id;
          }
          return file.imgSrc !== image.imgSrc;
        });
      } else {
        newImages = prevSliderImage?.img.filter((file) => file.name !== image.name);
      }
      if (image.id) {
        setRemovedImages((prev) => [...prev, image.id]);
      }
      _currentItem.image = newImages;
      return { img: newImages };
    });
  };

  const onRemoveMainImage = () => {
    if (mainImage.id) {
      setRemovedImages((prev) => [...prev, mainImage.id]);
    }
    setCurrentItem((prev) => ({
      ...prev,
      image_main: null,
    }));
    setMainImage(null);
  };

  const onRemoveCoverImage = () => {
    const _currentItem = currentItem;
    if (videoCover.id) setRemovedImages((prev) => [...prev, videoCover.id]);
    _currentItem.cover_image = null;
    setVideoCover(null);
    setCurrentItem(_currentItem);
  };

  const onRemoveVideo = () => {
    const _currentItem = currentItem;
    if (introVideo.id) setRemovedImages((prev) => [...prev, introVideo.id]);
    _currentItem.video = null;
    setIntroVideo(null);
    setCurrentItem(_currentItem);
  };

  useEffect(() => {
    setCurrentItem({ ...currentItem, description: textEditor });
  }, [textEditor]);

  // useEffect(() => {
  //   setCurrentItem({ ...currentItem, instruction: textEditorInstruction });
  // }, [textEditorInstruction]);

  const handleFetchCategoryData = useCallback(
    (catPage, itemId) => {
      firstRender.current = true;
      fetchItem({
        modelName: `/product-category?page=${catPage}`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1') {
              if (itemId && currentItem?.product_category_id && currentItem?.category?.id) {
                if (response.data?.data[i].id !== currentItem?.category.id) {
                  res.push(response.data?.data[i]);
                }
              } else {
                res.push(response.data?.data[i]);
              }
            }
          }
          if (itemId && catPage === 1 && currentItem?.product_category_id) {
            if (currentItem?.category?.id && currentItem?.category?.name) {
              res.push({
                id: currentItem?.category.id,
                name: currentItem?.category.name,
              });
            }
          }
          setCategoriesLastPage(response.data?.last_page);
          setCategories((prev) => [...prev, ...res]);
        }
      });
    },
    [currentItem?.product_category_id, currentItem?.category?.id],
  );

  const handleFetchBrandData = useCallback(
    (brandPage, itemId) => {
      fetchItem({
        modelName: `/brand?page=${brandPage}`,
        showLoader: true,
      }).then(async (response) => {
        if (response) {
          const res = [];
          for (let i = 0; i < response.data?.data?.length; i++) {
            if (response.data?.data[i].display === '1') {
              if (itemId && currentItem?.brand_id && currentItem?.brand.id) {
                if (response.data?.data[i].id !== currentItem?.brand.id) {
                  res.push(response.data?.data[i]);
                }
              } else {
                res.push(response.data?.data[i]);
              }
            }
          }
          if (currentItem?.id && currentItem?.brand_id) {
            if (currentItem?.brand?.id && currentItem?.brand?.name) {
              res.push({
                id: currentItem.brand.id,
                name: currentItem.brand.name,
              });
            }
          }
          setBrandsLastPage(response.data?.last_page);
          setBrands((prev) => [...prev, ...res]);
          // setAllBrands((prev) => {
          //   const existingKeys = new Set(prev.map((brand) => brand.key)); // Store existing keys
          //   const newBrands = res
          //     .filter((brand) => !existingKeys.has(brand.id)) // Only add if key does not exist
          //     .map((brand) => ({ ...brand, key: brand.id }));

          //   return [...prev, ...newBrands]; // Merge unique items
          // });
        }
      });
    },
    [currentItem?.brand?.id, currentItem?.brand_id],
  );

  useEffect(() => {
    if (!firstRender.current && (currentItem?.id || currentItem?.id === 0 || id === 0)) {
      handleFetchCategoryData(1, id);
      handleFetchBrandData(1, id);
    }
  }, [currentItem?.id, id]);

  const fileSelectedHandlerVideo = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.video = null;
    setIntroVideo(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.video = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setIntroVideo({ img: reader.result });
    };
  };

  const fileSelectedHandlerVideoCover = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.cover_image = null;
    setVideoCover(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.cover_image = file;
    setCurrentItem(_currentSlider);
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => {
      // eslint-disable-next-line no-shadow
      setVideoCover({ img: reader.result });
    };
  };

  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <Button
            id='backToListIcon'
            component={Link}
            to={
              // eslint-disable-next-line no-nested-ternary
              page
                ? title
                  ? `/product-management/products?page=${encodeURIComponent(page)}&title=${title}`
                  : `/product-management/products?page=${encodeURIComponent(page)}`
                : title
                ? `/product-management/products?title=${title}`
                : '/product-management/products'
            }
            color='secondary'
            variant='outlined'
            style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}
          >
            <ArrowLeftOutlined />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Product Info' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  xs={12}
                  sm={6}
                  label='Name'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                {/* <BTextField
                  xs={12}
                  sm={6}
                  label='عنوان محصول به انگلیسی'
                  name='name_en'
                  value={currentItem?.name_en}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name_en}
                /> */}
                <BSelectField
                  sm={6}
                  xs={12}
                  label='Select Category'
                  name='product_category_id'
                  value={currentItem?.product_category_id ?? ''}
                  onChange={onChange}
                  error={errors?.product_category_id}
                  items={convertArrayToKeyedObject(categories, 'id', 'name')}
                  readOnly={viewReadOnly || categories.length === 0}
                  fetchNextPageData={(nextPage) => handleFetchCategoryData(nextPage, id)}
                  lastPage={categoriesLastPage}
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='Select Brand'
                  name='brand_id'
                  value={currentItem?.brand_id ?? ''}
                  onChange={onChange}
                  error={errors?.brand_id}
                  items={convertArrayToKeyedObject(brands, 'id', 'name')}
                  readOnly={viewReadOnly || brands.length === 0}
                  fetchNextPageData={(nextPage) => handleFetchBrandData(nextPage, id)}
                  lastPage={brandsLastPage}
                />
                {/* <BAsAutoComplete
                  sm={6}
                  xs={12}
                  label='Select Brand'
                  name='brand_id'
                  value={allBrands.find((br) => String(br.id) === String(currentItem?.brand_id))}
                  onChange={onChange}
                  error={errors?.brand_id}
                  items={brands.length > 0 ? brands : allBrands}
                  readOnly={viewReadOnly || allBrands.length === 0}
                  fetchQueryData={handleSearchTitle}
                /> */}
                {/* {currentItem.id && (
                  <BTextField
                    xs={12}
                    sm={6}
                    label='موجودی محصول (به عدد)'
                    name='quantity'
                    value={productQuantity}
                    onChange={(e) => setProductQuantity(e.currentTarget.value)}
                    readOnly={viewReadOnly}
                    error={errors?.quantity}
                  />
                )} */}
                <Grid item xs={12}>
                  <div>
                    <h4 style={{ display: 'inline-block' }}>Product Description</h4>
                  </div>
                  <Grid item xs={12} style={{ direction: 'rtl' }}>
                    {(currentItem.display === '0' ||
                      currentItem.display === '1' ||
                      currentItem.display === true ||
                      currentItem.display === false) && (
                      <CkeditorField value={currentItem.description} onChange={(data) => setTextEditor(data)} />
                    )}
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <div>
                    <h4 style={{ display: 'inline-block' }}>نحوه استفاده از محصول</h4>
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                  <Grid item xs={12} style={{ direction: 'rtl' }}>
                    {(currentItem.display === '0' ||
                      currentItem.display === '1' ||
                      currentItem.display === true ||
                      currentItem.display === false) && (
                      <CkeditorField
                        value={currentItem.instruction}
                        onChange={(data) => setTextEditorInstruction(data)}
                      />
                    )}
                  </Grid>
                </Grid> */}
                <BTextField
                  xs={12}
                  sm={6}
                  label='Price'
                  name='base_price'
                  value={String(currentItem?.base_price)}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.base_price}
                  required
                />
                <BTextField
                  xs={12}
                  sm={6}
                  label='Price After Discount'
                  name='off_price'
                  value={currentItem?.off_price !== null ? String(currentItem?.off_price) : ''}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.off_price}
                />
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>Select Image</div>
                  <span>
                    The uploaded file size must be a maximum of 300KB. The image format should be .webp, .jpeg/.jpg, or
                    .png.
                  </span>
                  <br />
                  <input
                    ref={mainFileInputRef}
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={mainFileSelectedHandler}
                    style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
                  />
                  {mainImage?.img && (
                    <div style={{ display: 'inline' }}>
                      <button type='button' onClick={() => onRemoveMainImage()} aria-label='Remove Image'>
                        <DeleteOutlined />
                      </button>
                      <img alt='slider-main' src={mainImage?.img} style={{ width: '200px', height: '200px' }} />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>Select Images</div>
                  <span>
                    The uploaded file size must be a maximum of 300KB. The image format should be .webp, .jpeg/.jpg, or
                    .png.
                  </span>
                  <br />
                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandler}
                    multiple
                    style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
                  />
                  {sliderImage?.img &&
                    sliderImage?.img.map((image, index) => (
                      <div style={{ display: 'inline' }} key={index}>
                        <button type='button' onClick={() => onRemoveImage(image)} aria-label='Remove Image'>
                          <DeleteOutlined />
                        </button>
                        <img
                          alt={`slider-${index}`}
                          src={image.imgSrc ? image.imgSrc : URL.createObjectURL(image)}
                          style={{ width: '200px', height: '200px' }}
                        />
                      </div>
                    ))}
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب ویدیو معرفی</div>
                  <span>اندازه فایل آپلود شده حداکثر 2 مگابایت میباشد. فرمت فیلم به صورت m4v یا mp4 میباشد.</span>
                  <br />
                  <input
                    type='file'
                    accept='video/mp4,video/x-m4v,video/*'
                    onChange={fileSelectedHandlerVideo}
                    style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
                  />
                  {introVideo?.img && (
                    <div style={{ display: 'inline' }}>
                      <button type='button' onClick={() => onRemoveVideo()} aria-label='Remove Image'>
                        <DeleteOutlined />
                      </button>
                      <video width='320' height='240' controls>
                        <source src={introVideo?.img} type='video/mp4' />
                        <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
                        Your browser does not support the video tag.
                      </video>{' '}
                    </div>
                  )}
                </Grid> */}
                {/* <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب کاور ویدیو</div>
                  <span>
                    اندازه فایل آپلود شده حداکثر 300 کیلوبایت میباشد. فرمت عکس به صورت .webp یا jpeg/jpg یا png یا
                    میباشد.
                  </span>
                  <br />
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg, .webp'
                    onChange={fileSelectedHandlerVideoCover}
                    style={{ marginBottom: '15px', marginTop: '15px', width: '100px' }}
                  />
                  {videoCover?.img && (
                    <div style={{ display: 'inline' }}>
                      <button type='button' onClick={() => onRemoveCoverImage()} aria-label='Remove Image'>
                        <DeleteOutlined />
                      </button>
                      <img src={videoCover.img} alt='cover' style={{ width: '200px', height: '200px' }} />
                    </div>
                  )}
                </Grid> */}
                {currentItem?.id && (
                  <BTextField
                    xs={12}
                    sm={6}
                    label='Slug'
                    name='slug'
                    value={currentItem?.slug}
                    onChange={onChange}
                    readOnly={viewReadOnly}
                    error={errors?.slug}
                  />
                )}
                <BCheckBoxField
                  sm={12}
                  name='display'
                  label='Active'
                  value={
                    currentItem?.display === '0' || currentItem?.display === false
                      ? false
                      : !!(currentItem?.display === '1' || currentItem?.display === 1 || currentItem?.display === true)
                  }
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='active'
                  label='Publish'
                  value={
                    currentItem?.active === '0' || currentItem?.active === false
                      ? false
                      : !!(currentItem?.active === '1' || currentItem?.active === 1 || currentItem?.active === true)
                  }
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='new'
                  label='New'
                  value={
                    currentItem?.new === '0' || currentItem?.new === false
                      ? false
                      : !!(currentItem?.new === '1' || currentItem?.new === 1 || currentItem?.new === true)
                  }
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='special'
                  label='Special'
                  value={
                    currentItem?.special === '0' || currentItem?.special === false
                      ? false
                      : !!(currentItem?.special === '1' || currentItem?.special === 1 || currentItem?.special === true)
                  }
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='popular'
                  label='Popular'
                  value={
                    currentItem?.popular === '0' || currentItem?.popular === false
                      ? false
                      : !!(currentItem?.popular === '1' || currentItem?.popular === 1 || currentItem?.popular === true)
                  }
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={
                    // eslint-disable-next-line no-nested-ternary
                    page
                      ? title
                        ? `/product-management/products?page=${encodeURIComponent(page)}&title=${title}`
                        : `/product-management/products?page=${encodeURIComponent(page)}`
                      : title
                      ? `/product-management/products?title=${title}`
                      : '/product-management/products'
                  }
                  color='secondary'
                  variant='outlined'
                >
                  Back to List
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default ProductPage;
