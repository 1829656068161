import { Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../components/context/DashboardContext';
import BSubmitButton from '../../../components/buttons/SubmitButton';
import BTextField from '../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptEmailCharacters,
} from '../../../common/utilities';
import { BSwalShowError } from '../../../common/BSwal';
import BDateFieldTable from '../../../components/inputs/forms/BDateFieldTable';

const initialItem = {
  name: '',
  email: '',
  mobile: '',
  birthday: '',
  engaging_date: '',
  address: '',
  password: '',
  display: true,
};

const cookies = new Cookies();
const url = process.env.REACT_APP_SITE_URL;

const ProfilePage = (props) => {
  const { staticContext, ...rest } = props;
  const { globalState, fetchItem } = useContext(DashboardPanelContext);
  const [currentItem, setCurrentItem] = useState(initialItem);
  const { viewReadOnly } = globalState;
  const [coverImage, setCoverImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue } = useForm();

  useEffect(() => {
    fetchItem({
      modelName: `/user`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setCurrentItem(response.data);
      }
    });
  }, []);

  const fileSelectedHandlerLoadImage = async (e) => {
    setCoverImage(null);
    const name = url.concat(e);
    const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
    // eslint-disable-next-line no-shadow
    setCoverImage({ img: imgSrc });
  };

  useEffect(() => {
    if (currentItem.data) {
      setCurrentItem(currentItem.data);
    }
    if (currentItem) {
      setValues(setValue, currentItem);
    }
    if (currentItem?.id && currentItem.attachments && currentItem.attachments[0]) {
      fileSelectedHandlerLoadImage(currentItem.attachments[0].path);
    }
  }, [currentItem]);

  const onSubmit = () => {
    setLoading(true);
    const _currentItem = { ...currentItem };
    _currentItem.display = '1';
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    const formData = new FormData();
    for (let i = 0; i < Object.keys(_currentItem).length; i++) {
      formData.append(
        Object.keys(_currentItem)[i],
        Object.values(_currentItem)[i] ? Object.values(_currentItem)[i] : '',
      );
    }
    formData.append('_method', 'put');
    axios({
      method: 'post',
      url: `users/${currentItem.id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${cookies.get('userToken')}`,
      },
    })
      .then((res) => {
        if (res?.data?.error?.email) {
          BSwalShowError(res?.data?.error?.email);
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const fileSelectedHandlerImage = async (e) => {
    const _currentSlider = currentItem;
    _currentSlider.image = null;
    setCoverImage(null);
    const file = e.target.files[0];
    // eslint-disable-next-line no-shadow
    _currentSlider.image = file;
    setCurrentItem(_currentSlider);
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentItem, setCurrentItem);
  };
  return (
    <div style={{ margin: 10 }}>
      {currentItem && (
        <Card {...rest}>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Profile Info' />
            <Divider />
            <CardContent>
              <br />
              <Grid container spacing={3}>
                <BTextField
                  label='Name'
                  name='name'
                  value={currentItem?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.name}
                  required
                />
                <BTextField
                  label='Email'
                  name='email'
                  value={currentItem?.email}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.email}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEmailCharacters}
                  required
                />
                <BTextField
                  label='Mobile'
                  name='mobile'
                  value={currentItem?.mobile}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors?.mobile}
                  required
                />
                <BTextField
                  type='password'
                  name='password'
                  label='Password'
                  value={currentItem?.password}
                  onChange={onChange}
                  required={!currentItem.id}
                  error={errors?.password}
                />
                {/* <BDateFieldTable
                  name='birthday'
                  label='تاریخ تولد'
                  error={errors?.birthday}
                  value={currentItem?.birthday}
                  onChange={onChange}
                  required
                /> */}
                {/* <BDateFieldTable
                  name='engaging_date'
                  label='تاریخ ازدواج'
                  error={errors?.engaging_date}
                  value={currentItem?.engaging_date}
                  onChange={onChange}
                /> */}
                {/* <Grid item xs={12} sm={12}>
                  <div style={{ fontSize: '16px' }}>انتخاب عکس کاربری</div>
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    onChange={fileSelectedHandlerImage}
                    style={{ marginBottom: '15px', marginTop: '15px' }}
                  />
                  {coverImage?.img && (
                    <img src={coverImage.img} alt='cover' style={{ width: '200px', height: '200px' }} />
                  )}
                </Grid> */}
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save' loading={loading} />}
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default ProfilePage;
