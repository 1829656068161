import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Avatar, List, Typography, Statistic } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { GetItem } from '../../helpers/APIHandlers';
import LineChart from '../../components/charts/lineChart';
import BarChart from '../../components/charts/barChart';

const circleStyle = (color) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: color,
  position: 'absolute',
  top: 16,
  right: 16,
});

const Dashboard = (props) => {
  const [reports, setReports] = useState(null);
  const [mostSoldProducts, setMostSoldProducts] = useState([]);
  const [mostSoldCustomers, setMostSoldCustomers] = useState([]);

  const getReports = useCallback(async () => {
    await GetItem('reports').then((res) => setReports(res?.data));
  }, [GetItem]);
  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    const sortedProducts = reports?.mostSoldProductsPerMonth
      // Step 1: Aggregate quantities for products with the same ID
      .reduce((acc, item) => {
        const existingProduct = acc.find((product) => product.id === item.product.id);
        if (existingProduct) {
          // If the product is already in the accumulator, sum up the quantity
          existingProduct.quantity += Number(item.quantity_sold);
        } else {
          // If not, add it to the accumulator
          acc.push({
            id: item.product.id,
            title: item.product.name,
            quantity: Number(item.quantity_sold),
          });
        }
        return acc;
      }, [])
      // Step 2: Sort by quantity and take the top 4
      .sort((a, b) => b.quantity - a.quantity)
      .slice(0, 5);

    // Step 2: Format the sorted data for display
    const dataSource = sortedProducts?.map((item) => ({
      title: item.title,
      quantity: ` ${item.quantity} Items`,
    }));
    setMostSoldProducts(dataSource);
  }, [reports?.mostSoldProductsPerMonth]);

  useEffect(() => {
    const sortedCustomers = reports?.mostProfitableCustomers
      .map((item) => ({
        title: item.customer.name,
        price: Number(item.total_spent).toLocaleString(),
      }))
      .sort((a, b) => b.price - a.price)
      .slice(0, 5);

    // Step 2: Format the sorted data for display
    const dataSource = sortedCustomers?.map((item) => ({
      title: item.title,
      price: `$${item.price}`,
    }));
    setMostSoldCustomers(dataSource);
  }, [reports?.mostProfitableCustomers]);

  return (
    <div data-testid='dashboard'>
      {/* <Grid container spacing={4}> */}
      <h4>Dashboard</h4>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <div style={circleStyle('#ff4d4f')} />
            <Statistic
              title='Total Sell'
              value={`$${reports?.totalSell ? Number(reports?.totalSell).toLocaleString() : 0}`}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div style={circleStyle('#40a9ff')} />
            <Statistic title='Total Number of Orders' value={reports?.totalOrderCount ?? 0} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div style={circleStyle('#73d13d')} />
            <Statistic title='Total Number of Customers' value={reports?.totalCustomerCount ?? 0} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div style={circleStyle('#faad14')} />
            <Statistic title='Total Number of Products' value={reports?.totalProductCount ?? 0} />
          </Card>
        </Col>

        {/* Weekly Revenue chart placeholder */}
        <Col span={12}>
          <Card title='Weekly Revenue'>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%' }}>
                <LineChart salesData={reports?.totalSellPerDay} />
              </div>
              <br />
            </div>
          </Card>
        </Col>

        <Col span={12}>
          <Card title='Number of Sales Per Month'>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%' }}>
                <BarChart salesData={reports?.totalProductsSoldPerMonth} />
              </div>
              <br />
            </div>
          </Card>
        </Col>

        {/* Sales chart placeholder */}
        {/* <Col span={8}>
          <Card title='پرفروش ترین محصول'>
            <div style={{ height: '200px', textAlign: 'center' }}>[Sales Chart]</div>
          </Card>
        </Col> */}

        {/* Top-selling products list */}
        <Col span={8}>
          <Card title='Best-Selling Products'>
            <List
              dataSource={mostSoldProducts}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>{item.title}</Typography.Text> - {item.quantity}
                </List.Item>
              )}
            />
          </Card>
        </Col>

        {/* Best store customers */}
        <Col span={8}>
          <Card title='Top Customers of the Store'>
            <List
              dataSource={mostSoldCustomers}
              renderItem={(item) => (
                <List.Item>
                  <Avatar icon={<UserOutlined />} />
                  <span style={{ marginLeft: '8px' }}>
                    {item.title} - {item.price}
                  </span>
                </List.Item>
              )}
            />
          </Card>
        </Col>

        {/* New customers */}
        <Col span={8}>
          <Card title='Newest Users'>
            <div style={{ display: 'flex', gap: '10px', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar icon={<UserOutlined />} />
              Users Joined This Month:
              <h2 style={{ display: 'flex' }}>{reports?.totalNewCustomersThisMonth}</h2>
            </div>
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
    </div>
  );
};
export default Dashboard;
