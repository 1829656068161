import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import moment from 'moment';
import Locale from '../../../../helpers/Locale';
import { Default } from '../../../../data/Dictionary';
// import logo from '../../../../assets/img/logo/logo.png';

const { Header } = Layout;

const TopbarName = styled.div`
  margin: 0 20px;
  display: inline;
  font-size: 20px;
  border-right: 1px solid #fff;
  padding-right: 20px;
`;

// const LogoContainer = styled.div`
//   display: inline;
//   margin-top: 15px;
//   position: relative;
// `;

const Logout = styled.div`
  display: inline;
  float: right;
  margin-right: 15px;
`;

const CurrentDate = styled.div`
  display: inline;
  & > span {
    margin-right: 10px;
  }
`;

const Topbar = () => {
  const navigate = useNavigate();
  const Language = Locale();

  return (
    <Header className='header' style={{ background: '#DEDFE0', padding: '0 7px', color: '#6F7275' }}>
      <div className='logo'>
        {/* <LogoContainer>
          <RouterLink to='/'>
            <img alt='Logo' style={{ height: 30, borderRadius: '50%', position: 'fixed', top: 17 }} src={logo} />
          </RouterLink>
        </LogoContainer> */}
        <TopbarName>
          <Link to='/'>Sanaz H Expressions Admin Panel</Link>
        </TopbarName>
        <CurrentDate>
          <span> {moment(new Date().toISOString().slice(0, 10), 'YYYY-MM-DD').locale('en').format('dddd')}</span>
          <span> {moment(new Date().toISOString().slice(0, 10), 'YYYY-MM-DD').locale('en').format('YYYY/MM/DD')}</span>
        </CurrentDate>
        <Logout>
          <button
            type='button'
            onClick={() => {
              navigate('/logout');
            }}
            style={{
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: 'rgba(255, 255, 255, 0.23)',
              border: '1px solid rgba(255, 255, 255, 0.23)',
              color: '#6F7275',
              cursor: 'pointer',
              fontFamily: 'Arial',
            }}
          >
            <PoweroffOutlined style={{ marginRight: '10px' }} />

            {Default[Language].exit}
          </button>
        </Logout>
      </div>
    </Header>
  );
};

export default Topbar;
