import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';

const OrderStatusList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchItem({
      modelName: `/order-status`,
      showLoader: true,
    }).then(async (response) => {
      if (response) {
        const allData = [];
        for (let i = 0; i < response.data?.length; i++) {
          allData.push({ ...response.data[i], key: response.data[i].id });
        }
        setData(allData);
      }
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: 'Name',
        dataIndex: 'status',
        key: 'status',
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'roles.assign.permission',
    deletePermission: 'roles.assign.permission',
    editURL: '/order-management/order-status/',
    removePATH: 'order-status',
    createPermission: 'roles.assign.permission',
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    navigate('/order-management/order-status');
  };

  return (
    <>
      <ListsContainer>
        <Table
          tableKey={key}
          key={key}
          title='Order Status'
          columns={columns}
          createSlug='Add an Order Status'
          actions={actions}
          createAction={handleCreate}
          tableRef={tableRef}
          data={data}
          pageSize={pageSize}
          filtering={false}
          sortableColumns
          totalPages={totalPageNumber}
        />
      </ListsContainer>
    </>
  );
};

export default OrderStatusList;
